<template>
    <div class="option-box">
       <ul>
          <li class="flex" v-for="(item,index) in list" :key="index"  @click="active(item,index)">
              <div>   
                  <img :src="item.img" alt="" v-if="item.img" class="img">
                  <span v-text="item.label"></span>
              </div>
              
              <p class="radio">
                  <i :class="[item.active?'active':'']"></i>
              </p>
          </li>
       </ul>
    </div>
  </template>
  
  <script>
  export default {
      props:{
          list:[]
      },
      data(){
          return {
              
          }
      },
      methods:{
          active(val,i){
              this.list.forEach((item,index)=>{
                  if(i===index){
                      this.list[i].active = true;
                  }else{
                      item.active=false;
                  }
              })
              this.$emit('active',val)
          }
      }
  
  }
  </script>
  
  <style lang="scss" scoped>
  .flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
    //   margin-bottom: 20px;
      cursor: pointer;
      &:nth-child(2) {
          margin-bottom: 0;
      }
      span {
        font-size: 20px;
        color: #C198FF;
      }
  }
  .img {
      width: 40px;
      vertical-align: middle;
      margin-right: 8px;
      
  }
      .option-box {
          width: 100%;
          // position: absolute;
          z-index: 100000;
          // top: 35px;
          // left: -100px;
          // width: 270px;
          // height: 264px;
          padding: 22px;
          border-radius: 3px;
          background-color: #12042E;
          border: 1px solid #C198FF;
          padding-left: 26px;
          padding-right: 26px;
          box-sizing: border-box;
      }
      .radio {
          cursor: pointer;
          position: relative;
          border: 1px solid #C198FF;
          width: 22px;
          height: 22px;
          border-radius: 11px;
          background-color: transparent;
          padding: 5px;
          box-sizing: border-box;
          // text-align: center;
          // line-height: 22px;
          .active {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              display: inline-block;
              width: 13px;
              height: 13px;
              border-radius: 50%;
              background: #FF3A8A;
          }
      }
  </style>