<template>
  <div class="pui-input-box">
    <div class="pui-input__label" v-if="label">
        {{label}}
        <slot name="label-prefix">
            <svg class="svg-icon pui-input__label-icon" aria-hidden="true">
                <use v-bind:xlink:href="labelIcon"></use>
            </svg>
        </slot>
    </div>
    <Input
    ref="input"
    class="pui-input"
    :class="[center ? 'pui-input--center': '']"
    :type="type"
    :label="label"
    :maxlength="maxlength"
    :minlength="minlength"
    :placeholder="placeholder"
    :disabled="disabled"
    v-model="inputValue"
    @blur="onBlur"
    @focus="onFocus"
    @change="onChange"
    @input="onInput"
    @clear="onClear">
    </Input>
  </div>
</template>

<script>
import { Input } from 'element-ui'
export default {
    name: 'pui-input',
    components: { Input },
    props: {
        value: [Number, String],
        type: String,
        maxlength: Number,
        minlength: Number,
        placeholder: String,
        disabled: Boolean,
        center: Boolean,
        label: String,
        labelIcon: String
    },
    data() {
        return {
            inputValue: this.value
        }
    },
    watch: {
        value(val) {
            this.inputValue = val
        },
        inputValue(val) {
            this.$emit('update:value', val)
        }
    },
    methods: {
        onBlur() {
            this.$emit('blur')
        },
        onFocus() {
            this.$emit('focus')
        },
        onChange() {
            this.$emit('change')
        },
        onInput(val) {
            this.$emit('input', val)
        },
        onClear() {
            this.$emit('clear')
        },
        focus() {
            this.$refs.input.focus()
        },
        blur() {
            this.$refs.input.blur()
        },
        select() {
            this.$refs.input.select()
        }
    }
}
</script>

<style lang="scss" scoped>
    .pui-input-box {
        position: relative;
        display: flex;
        flex-direction: column;
        .pui-input__label {
            padding: 5px 0;
            color: #8B7B73;
            font-size: 14px;
        }
        .pui-input__label-icon {
            width: 13px;
            height: 13px;
            display: inline-block;
        }
    }
    .pui-input {
        &.pui-input--center {
            input::-ms-input-placeholder{text-align: center;}
            input::-webkit-input-placeholder{text-align: center;}
            input {
                text-align: center;
                background-color: #F4F9F7 !important;
                border-radius: 15px;
                border: 1px solid #D4D4D4;
            }
        }
        input {
            color: $--color !important;
            font-family: TrebuchetMS-Bold, TrebuchetMS !important;
            font-weight: bold !important;
            border: 0;
            border-bottom: 1px dashed #D8D8D8;
            background-color: white !important;
            padding-left: 0;
            &::-ms-input-placeholder, &::-webkit-input-placeholder {
                color: #C4C4C4;
            }
            &:focus {
                border-color: $--color;
            }
        }
    }
</style>