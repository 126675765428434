<template>
    <div style="width: 100%; height: 100%" ref="chart"></div>
</template>
<script>
import * as Echarts from 'echarts';
export default {
    props: {
        echartsArr: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() { return { chart: null } }, //图表实例
    mounted() { this.init() },
    methods: {
        init() {
            //2.初始化
            this.chart = Echarts.init(this.$refs.chart);
            //3.配置数据
            let option = {
                title: {
                    show: true,
                    text: this.$t('Favoredfields') ,
                    top: 'center',
                    left: '30%',
                    textStyle: {
                        color: '#fff',
                        fontSize: 10
                    }
                },
                tooltip: {
                    trigger: 'item',
                    show:false
                },
                legend: {
                    top: '30%',
                    right: '20',
                    orient: 'vertical',
                    itemWidth: 15,
                    itemHeight: 10,
                    itemGap: 5,
                    textStyle: {
                        color: '#fff',
                        fontSize: 10
                    },
                    formatter: function (name) {
                        let seriesData = option.series[0].data
                        let zb;
                        for (let i = 0; i < seriesData.length; i++) {
                            if (seriesData[i].name == name) {
                                zb = seriesData[i].zb;
                            }
                        }
                        return name + '   ' + zb;
                    }
                },
                series: [
                    {
                        center:['40%','50%'],
                        name: 'Access From',
                        type: 'pie',
                        radius: ['60%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 1,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            selectorLabel: {
                                show: false
                            },
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: true
                        },
                        data: this.echartsArr
                    }
                ]
            };
            // 4.传入数据
            this.chart.setOption(option);
        }
    }
};
</script>
<style  scoped lang="scss">
@media screen and (max-width: 500px) {
}
</style>