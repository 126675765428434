/*
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-22 11:44:41
 * @Description:
 */
import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import '@/components/UIKIT/style.scss'
import '@/components/common.scss'
// import {VantLoading} from 'vant';
import 'vant/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css'
import vuescroll from 'vuescroll'
import 'animate.css'
import '@/plugins/vconsole'
import './rem.js'
// import {
//   Select,
//   Row,
//   Col,
//   Tooltip,
//   Breadcrumb,
//   BreadcrumbItem,
//   Button,
//   InfiniteScroll,
//   Option,
//   Input,
//   RadioGroup,
//   RadioButton,
//   tabPane,
//   Dialog,
//   Pagination,
//   Popover,
//   Progress,
//   Slider
// } from 'element-ui'
import ElementUI from 'element-ui'
import '@/plugins/metamask.js'
import { i18n } from '@/plugins/i18n'
// import locale from 'element-ui/lib/locale/lang/zh-CN' 
import zh from 'element-ui/lib/locale/lang/zh-CN' 
import locale from 'element-ui/lib/locale/lang/en' 
import toast from "@/components/tips"  //我的toast文件目录
// import form from "@/components/form"  //我的toast文件目录
//1.安装该插件form
Vue.use(toast);
// Vue.use(form);
Vue.config.productionTip = false

// Vue.use(VueRouter);

if(localStorage.lang=='zh'){
  Vue.use(ElementUI, {zh})
}else{
  Vue.use(ElementUI, {locale})
}
Vue.use(vuescroll)
// Vue.use(Row)
// Vue.use(Input)
// Vue.use(Option)
// Vue.use(Select)
// Vue.use(Col)
// Vue.use(Tooltip)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Button)
// Vue.use(InfiniteScroll)
// Vue.use(tabPane)
// Vue.use(RadioGroup)
// Vue.use(RadioButton)
// Vue.use(Dialog)
// Vue.use(Popover)
// Vue.use(Progress)
// Vue.use(Slider)
// Vue.use(Pagination)





Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}
 
//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}
export default new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')