<template>
    <div>
        <el-dialog :visible.sync="isShow" custom-class="tip-dialog" :show-close="false">
            {{ message }}
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "Toast",
  props:{
    message:{
      type:String,
      default:''

    },
    isShow:{
      type:Boolean,
      default: false

    }
  },
  methods:{
    show(message,time = 5000){
      this.isShow = true;
      this.message = message;
      setTimeout(()=>{
         this.isShow = false;
         this.message = '';
      },time)
    }

  }

}
</script>
<style lang="scss" scoped>
.tip-dialog {
    word-break: keep-all;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 53% !important;
    max-width: 570px;
    height: 470px;
    // line-height: 470px;
    text-align: center;
    margin: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px !important;
    background: url(../../assets/uni-meta/tip-bg.png) no-repeat !important;
    background-size: 100% 100% !important;
    padding: 0px 100px !important;

    .el-dialog__title {
        color: #fff !important;
    }

    .el-dialog__header {
        padding-top: 0;
    }

    .el-dialog__body {
        word-break: keep-all;
        word-wrap: break-word;
        white-space: pre-wrap;
        width: 80%;
        font-size: 30px;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        color: #00FFFF !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>