<template>
<div>
  <div class="pui-dialog pui-dialog--inner" v-if="inner" :style="style" :class="[extraClass]">
      <div class="pui-dialog__header">
          <div class="pui-dialog__title" :style="titleStyle">{{title}}</div>
          <div class="pui-dialog__desc">
              <slot name="desc">{{desc}}</slot>
          </div>
          <div class="pui-dialog__right">
              <slot name="title-right"></slot>
          </div>   
      </div>
      <div class="pui-dialog__main">
          <slot></slot>
      </div>
      <div class="pui-dialog__btns" v-if="btns && btns.length >= 1">
          <div class="pui-dialog__btns-item" :class="[index === 1 ? 'is-right' : '', btns.length === 1 ? 'is-only' : '']" v-for="(item, index) in btns" :key="`pui-dialog__btns-item-${index}`">
              <Button :width="134" @onClick="clickCallBack(item, index)" v-html="item">{{item}}</Button>
          </div>
      </div>
  </div>

<Dialog
  :title="title"
  :custom-class="customClass"
  :visible.sync="dialogVisible"
  :modal-append-to-body="false"
  :modal = "modal" 
  v-if="!inner"
  :class="isTips ? 'z-model-local' : ''"
  >
  <div class="pui-dialog-el__main">
      <div class="pui-dialog-el__detail">
          <slot></slot>
      </div>
      <div class="btn-box" v-if="isTips&&isBtns">
          <div class="z-default-btn" @click="cancel">
            {{ $t("cancel") }}
          </div>
          <Button @click.native="confirm">{{ confirmText ? confirmText : $t('confirm')}}</Button>
      </div>
      <div class="pui-dialog__btns" v-if="btns && btns.length >= 1 && !isTips" >
          <div class="pui-dialog__btns-item" :class="[index === 1 ? 'is-right' : '', btns.length === 1 ? 'is-only' : '']" v-for="(item, index) in btns" :key="`pui-dialog__btns-item-${index}`">
              <Button @onClick="clickCallBack(item, index)">{{item}}</Button>
          </div>
      </div>

  </div>
  </Dialog> 



  <!-- <Dialog
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      :append-to-body="true"
      class="z-model-local"
      v-if="isTips"
    >
      <div>
        <slot></slot>
        <div class="btn-box">
          <div class="z-default-btn" @click="dialogVisible = false">
            {{ $t("cancel") }}
          </div>
          <Button @click.native="confirm">{{ confirmText ? confirmText : $t('confirm')}}</Button>
        </div>
      </div>
  </Dialog> -->
</div>   
</template>

<script>
import { Dialog } from 'element-ui';
import Button from '../Button/button.vue';
export default {
    components: { Dialog, Button },
    props: {
        inner: {
            type: Boolean,
            default: true
        },
        btns: {
            type: Array,
            default() {
                return []
            }
        },
        title: String,
        desc: String,
        titleFontSize: {
            type: [Number, String],
            default: 30
        },
        width: [Number, String],
        height: [Number, String],
        visible: Boolean,
        extraClass:String,
        modal:{
            type:Boolean,
            default:true
        },
        isTips:{
            type:Boolean,
            default:false
        },
        confirmText:{
            type:String,
            default:""
        },
        isBtns:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            dialogVisible: this.visible
        }
    },
    watch: {
        visible(val) {
            this.dialogVisible = val;
        },
        dialogVisible(val) {
            this.$emit('update:visible', val);
        }
    },
    computed: {
        style() {
            return {
                width: this.width + 'px',
                height: this.height + 'px'
            }
        },
        titleStyle() {
            return {
                fontSize: this.titleFontSize + 'px'
            }
        },
        customClass() {
            return 'pui-dialog-el'
        }
    },
    methods: {
        clickCallBack(item, index) {
            this.$emit('callback', item, index);
        },
        confirm(){
            this.$emit('confirm');
        },
        cancel(){
           this.$emit('cancel');
        }
    }
}
</script>
<style lang="scss" scoped>
.pui-dialog-el {
        border-radius: 26px;
        .el-dialog__header {
            padding: 41px 33px 0 33px;
            .el-dialog__title {
                color: #FFAD40;
                font-weight: bold;
            }
        }
        .el-dialog__body {
            padding-left: 33px;
            padding-right: 33px;
            padding-top: 15px;
        }
        .el-dialog__headerbtn {
            display: none;
        }
        .pui-dialog-el__detail {
            margin-bottom: 31px;
        }
    }
    @media screen and (max-width: 750px) {
        .pui-dialog-el {
            width: 90% !important;
            position: absolute !important;
            top: calc(50% - 100px) !important;
            left: 50%;
            transform: translate(-50%, -50%);
            margin:0;
        }
    }
</style>
<style scoped lang="scss">
    .pui-dialog {
        padding: 32px 37px;
        background-color: white;
        box-shadow: 0px 5px 12px 0px #D7BAA5;
        border-radius: 26px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        position: relative;

        .pui-dialog__header {
            margin-bottom: 34px;     //加这个盲盒详情有问题（间距过大）【其他有问题是考虑下这块】
        }

        .pui-dialog__title {
            font-size: 30px;
            font-weight: bold;
            position: relative;
        }

        .pui-dialog__main {
            margin-bottom: 10px;
        }
        
        .pui-dialog__right {
            position: absolute;
            top: 32px;
            right: 37px;
        }
    }
    .pui-dialog__btns {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 37px;

        .pui-dialog__btns-item {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            margin-right: 8px;
            &.is-right {
                justify-content: flex-start;
                margin-left: 8px;
            }
            &.is-only {
                justify-content: center;
                margin: 0;
            }
        }
    }
    .btn-box {
    // padding-top: 37px;
    display: flex;
    align-items: stretch;

    > * {
      width: 50%;
      flex: 1;
      padding-left: 0;
      padding-right: 0;
    }
    .z-default-btn {
      margin-right: 15px;
      border-radius: 20px;
      line-height: 36px;
      border: 1px solid #d9d9d9;
      color: #d9d9d9;
      text-align: center;
    }
    }

    //release-header-pad传来的类名
/deep/div.release-header-pad{
    .pui-dialog__title{
        padding-bottom: 0;
    }
}
</style>