import { render, staticRenderFns } from "./PublishNow.vue?vue&type=template&id=97ffcca4&scoped=true&"
import script from "./PublishNow.vue?vue&type=script&lang=js&"
export * from "./PublishNow.vue?vue&type=script&lang=js&"
import style0 from "./PublishNow.vue?vue&type=style&index=0&id=97ffcca4&lang=scss&scoped=true&"
import style1 from "./PublishNow.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ffcca4",
  null
  
)

export default component.exports