const baseSize = 16
function setRem() {
  if(document.documentElement.clientWidth>768){
    const scale = document.documentElement.clientWidth / 1920
    document.documentElement.style.fontSize = baseSize * scale + 'px'
  }else{
    document.documentElement.style.fontSize =  '7px'
  }
}
setRem()
window.onresize = function () {
  setRem()
}