/*
 * @Author: zw
 * @Date: 2021-11-08 16:34:10
 * @LastEditors: zw
 * @LastEditTime: 2021-11-12 10:21:53
 * @Description: 
 */
import { Notification } from 'element-ui';
import {i18nText} from '@/plugins/i18n'

export function toast({msg,type,duration}){
    //type:success || error 【关键字处理】
    if(!type ||( type!== 'success' && type!=='error')){
        type='success'
    }
    Notification({
        title: i18nText('msg'),
        message: msg,
        duration:duration||'1500',
        // duration:duration==3?duration:'150000',
        iconClass:type=='success' ?'toast-success' :'toast-error',
        customClass:'el-custom-notif'
        // position: 'bottom-right'
    });
}