<template>
  <div class="pui-button" :class="[`pui-button--${size}`, `pui-button--${type}`, width ? 'pui-button--width' : '']" :style="visible ? style : disStyle " @click.stop="onClick">
      <slot></slot>
  </div>
</template>

<script>
export default {
    props: {
        width: [String, Number],
        height: [String, Number],
        size: {
            type: String,
            default: 'normal'
        },
        type: {
            type: String,
            default: 'primary'
        },
        visible: {
             type: Boolean,
             default: true
        }
    },
    computed: {
        style() {
            return {
                width: this.width + 'px',
                height: this.height + 'px',
            }
        },
        disStyle() {
            return {
                width: this.width + 'px',
                height: this.height + 'px',
                background: "#ccc",
                "box-shadow": "none"
            }
        }
    },
    methods: {
        onClick() {
            this.$emit('onClick')
        }
    }
}
</script>

<style scoped lang="scss">
    .pui-button {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background-color: #FFCD40;
        border-radius: 19px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-shadow: 0px 3px #EDAA3D;
        user-select: none;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
        &--normal {
            padding-top: $--button-padding-top;
            padding-bottom: $--button-padding-bottom;
            padding-right: $--button-padding-right;
            padding-left: $--button-padding-left;
        }
        &--small {
            padding-top: $--button-padding-top-sm;
            padding-bottom: $--button-padding-bottom-sm;
            padding-right: $--button-padding-right-sm;
            padding-left: $--button-padding-left-sm;
        }
        &--larger {
            padding-top: $--button-padding-top-lg;
            padding-bottom: $--button-padding-bottom-lg;
            padding-right: $--button-padding-right-lg;
            padding-left: $--button-padding-left-lg;
        }
        &--primary {
            background-color: #FFCD40;
            box-shadow: 0px 3px #EDAA3D;
        }
        &--error {
            background-color: #FA6F6F;
            box-shadow: 0 3px #BA3E3E;
        }
        &--info {
            background-color: #3CC48B;
            box-shadow: 0 3px #2BA270;
        }
        &.pui-button--width {
            padding-left: 8px;
            padding-right: 7px;
            text-align: center;
        }
    }
    .pui-button:active {
        box-shadow: 0px 1px #EDAA3D;;
        top: 2px;
    }
</style>