<template>
    <div class="x-page">
        <div class="options">
            <div class="option-box">
                <OptionBox :optionArr="optionArr" @choose="choose"></OptionBox>
            </div>
         
            <img :src="img" alt=""  @click="publish(1)" class="lb jelly">
               
        </div>
        <div class="search">
            <el-input :placeholder="'Try searching for the needs you want to cooperate'" prefix-icon="el-icon-search"
                v-model="params.detailedDescription" autofocus @input="getList" class="jelly">
            </el-input>
        </div>
        <div class="flex" v-if="cardList.length">
            <div class="cards scal" v-for="(item, index) in cardList" :key="index">
                <div class="top" @click="$router.push('/demandDetail?id=' + item.id)">{{ item.promoteImg }}
                    <img :src="item.promoteImg ? item.promoteImg : people" alt="" class="peeple">
                    <img :src="item.bgFlag ? img2 : img1" alt="" class="box">
                    <div class="info">
                        <ul class="list">
                            <li class="mb-20">
                                <span>{{ item.projectName }}</span>
                            </li>
                            <li class="mb-20">
                                <ul class="center">
                                    <li class="mb-16">
                                        <p v-text="$t('ProjectFields') + ':'"></p>
                                        <p v-html="item.projectFields"></p>
                                    </li>
                                    <li class="mb-16">
                                        <p v-text="$t('place') + ':'"></p>
                                        <p v-html="item.onlineOrOffline"></p>
                                    </li>
                                    <li class="mb-16">
                                        <p v-text="$t('proIntro') + ':'"></p>
                                        <p v-html="item.projectIntroduction"></p>
                                    </li>
                                    <li class="mb-16">
                                        <p v-text="$t('ProNeedDes') + ':'"></p>
                                        <p v-html="item.detailedDescription"></p>
                                    </li>
                                    <li class="mb-16">
                                        <p v-text="$t('xuType') + ':'"></p>
                                        <p v-html="item.requirementType"></p>
                                    </li>
                                </ul>
                            </li>

                            <li class="mb-16">
                                <p v-text="$t('RequireFans') + ':'"></p>
                                <span v-html="item.requirementsFansNumber"></span>
                            </li>
                            <li class="mb-20">
                                <p v-text="$t('PartnersSeek') + ':'"></p>
                                <span v-html="item.seekPartners"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="bottom">
                    <p>{{ $t('Remuneration') }} : {{ item.remuneration }}</p>
                    <button @click="publish(2, item.id,index)" v-text="$t('nowUp')" :class="[item.disable ? 'disable' : 'jelly']"
                        :disabled="item.disable"></button>
                </div>
            </div>
        </div>
        <div v-else class="empty">
            {{ this.$t('no-con') }}
        </div>
        <div class="w-full text-center mb-20">
            <pages-vue @handleCurrentChange="handleCurrentChange" :total="total" :size="params.size" v-if="total">
            </pages-vue>
        </div>
        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
        <FormDialog v-if="pushDialogVisible" :isWhitelistUsers="isWhitelistUsers" @close = closeDia>
        </FormDialog>

        <PublishNow v-if="PublishNowVisible" :currenId="currenId"  :isWhitelistUsers="isWhitelistUsers" @subDis="subDis">
        </PublishNow>
    </div>
</template>

<script>
import img1 from "../../assets/uni-meta/x-card-box.png"
import img2 from "../../assets/uni-meta/x-card-box2.png"

import upEn from "../../assets/uni-meta/up-en.png"
import upZh from "../../assets/uni-meta/up-zh.png"
import OptionBox from '@/components/optionbox'
import { dictionary, getList, toSignUp, getRequireList } from '@/api/user'
import PublishNow from './components/PublishNow'
import pagesVue from "@/components/pages.vue"
import TipDialog from '@/components/TipDialog.vue'
import people from '../../assets/uni-meta/x-card-people.png'
import {updateUser} from '@/utils'
// import BaseDialog from '@/components/BaseDialog.vue'
import FormDialog from "./components/FormDialog.vue"
export default {
    components: {
        OptionBox, TipDialog, FormDialog, PublishNow, pagesVue
    },
    data() {
        return {
            currenId:'',
            currenIndex:'',
            img1: img1,
            img2: img2,
            people,
            img:'',
            cardList: [],
            requireList: [],
            optionArr: [
                { title: 'Requirement Type', option: [], choose: 0 },
                { title: 'Main fields', option: [], choose: 0 },
                { title: 'Cooperation partner type', option: [], choose: 0 },
            ],
            needSel: false,
            placeSel: false,
            form: {
                needTypes: '',
                place: '',
                fansNums: ''
            },
            search: '',
            tipShow: false,
            TipText: '',
            pushDialogVisible: false,
            PublishNowVisible: false,
            params: {
                current: 1,
                size: 8,
                detailedDescription: null,
                requirementType: null,
                projectFields: null,
                seekPartners: null,
                status:1
            },
            total: 0,
            isWhitelistUsers: false
        }
    },
    async mounted() {
        updateUser();
        this.isWhitelistUsers = this.$store.state.whiteUser;
        this.img = localStorage.lang == 'zh'?upZh:upEn
        const { data } = await dictionary({ code: "demand_type" })
        this.optionArr[0].option = [...data];
        console.log(this.params.requirementType);
        {
            const { data } = await dictionary({ code: "major_areas" })
            this.optionArr[1].option = [...data]
        }
        {
            const { data } = await dictionary({ code: "demand_cooperator" })
            this.optionArr[2].option = [...data]
        }
        await this.getRequireList();
        await this.getList();

    },
    methods: {
        choose(obj) {
            const { index, ind, val } = obj
            this.optionArr[index].choose = ind;
            let temVal = val != 0 && val != '' && ind ? val : null;
            index === 0 ? this.params.requirementType = temVal : index === 1 ? this.params.projectFields = temVal : this.params.seekPartners = temVal;
            this.getList()
        },
        async getRequireList() {
            const { data } = await getRequireList({ memberId:this.$store.state.userInfo.user_id,current:1,size:1000000})
            const { records } = data;
            this.requireList = records;
        },
        closeDia() {
            this.pushDialogVisible = false;
            this.PublishNowVisible = false;
        },
        publish(index, id,currindex) {
            this.currenIndex = currindex;
            if (!this.$store.state.account || !this.$store.state.sigStr) {
                this.tip(this.$t('beforeChina'))
            } else {
                this.currenId = id;
                if (index == 1) {
                    this.pushDialogVisible = true
                } else if (this.$store.state.memberType) {
                    this.whiteUp(id)
                    this.subDis();
                } else {
                    this.PublishNowVisible = true;
                }
                console.log(this.pushDialogVisible);
            }
        },
        async whiteUp(id) {
            await toSignUp({
                demandId: id,
                memberId: this.$store.state.userInfo.user_id,	//用户的id
                ...this.$store.state.chainInfo
            })
            this.tip(this.$t('okSub'))
        },
        tip(text) {
            this.$toast.show(text);
            // this.tipShow = true;
            // this.TipText = text;
            // setTimeout(() => {
            //     this.tipShow = false
            // }, 3000)
        },
        handleCurrentChange(val) {
            this.params.current = Number(val);
            this.getList();
        },
        async getList() {
            if (this.params.detailedDescription == '') {
                this.params.detailedDescription = null;
            }
            const { data } = await getList(this.params);
            const { total, records } = data;
            this.cardList = records;
            if (this.cardList.length) {
                this.cardList.forEach(item => {
                    console.log(item.id,'item.id');
                    this.requireList.forEach(el => {
                        if(el.demandId == item.id) {
                            item.disable = true;
                        }
                    })
                });
            }
            this.total = total;
        },
        subDis(){
            this.PublishNowVisible = false;
            this.cardList[this.currenIndex].disable = true;
            this.$forceUpdate();
        }
    }

}
</script>
<style scoped lang='scss'>
.x-page {
    background: url('../../assets/uni-meta/kol-u.png') no-repeat top;
    background-color: #371381;
    background-size: 1920px 1428px;
    width: 100%;
    min-height: 1800px;
    padding: 0 236px 20px 240px;
    box-sizing: border-box;
}

.empty {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;
}

.flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/deep/ .el-icon-search:before {
    color: #AA75F9;
}

.option-box {}

.options {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;

    .option-box {
        padding-top: 30px;
    }

    .lb {
        width: 246px;
        height: 297px;
        position: relative;
        cursor: pointer;
        img {
            width: 100%;
        }
        .text {
            width: 100%;
            text-align: center;
            position: absolute;
            font-size: 12px;
            color: #fff;
            left: 50%;
            transform: translateX(-50%);
            top: 45%;
            
        }
    }
}

.search {
    padding-top: 42px;
    display: flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    padding-right: 35px;
    margin-bottom: 75px;

    .el-input {
        width: 40%;
        border-radius: 39px;
        color: #AA75F9;
    }

    /deep/ .el-input__inner {
        border: 1px solid #AA75F9;
        border-radius: 10px;
        background: none;
        color: #fff;

        &::placeholder {
            color: #AA75F9;
        }
    }
}

.disable {
    background-color: #ccc !important;
}
.cards {
    margin-bottom: 20px;

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
        color: #fff;
        padding: 0 51px 0 49px;
        box-sizing: border-box;

        button {
            width: 256px;
            height: 77px;
            border: none;
            outline: none;
            cursor: pointer;
            background-color: #FF3A8A;
            border-radius: 30px 0 30px 0;
        }
    }

    .mb-20 {
        margin-bottom: 18px;
    }

    .mb-16,.mb-20 {
        margin-bottom: 16px;
        p {
            &:nth-child(1) {
                color: #fff;
                font-size: 22px;
            }
        }
        span {
            color: #FF3A8A;
        }
    }

    .top {
        position: relative;
        width: 696px;
        height: 85%;
        box-sizing: border-box;
        padding: 40px 31px 10px;
        // background: url('../../assets/uni-meta/x-card-box.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;

        .box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .peeple {
            width: 100%;
        }

        .info {
            width: 100%;
            min-height: 412px;
            height: calc(100% - 230px);
            background: #5826C0;
            padding: 30px 43px 160px 43px;
            box-sizing: border-box;
            color: #fff;

            @media screen and(max-width:660px) {
                padding: 30px 0 180px 43px;
            }


            li {
                line-height: 1.4;
                span {
                    font-size: 22px;
                }

                span:nth-child(1) {
                    margin-right: 5px;
                }
            }

            .center {
                li {
                    span {
                        font-size: 14px;
                    }

                }

                color: #72F3EC;
            }
        }

    }

    .bottom {
        width: 708px;
        height: 203px;
        background: url('../../assets/uni-meta/x-card-bottom.png') no-repeat;
        background-size: 100% 100%;
    }
}

.customer-dialog {
    padding: 0 0 !important;

    .title {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 30px;

        // padding: 40px 0;
    }

    .form-box {
        padding-top: 40px;
    }

    .form-item {
        display: flex;
        flex-direction: column;
        justify-content: start;
        font-size: 24px;
        color: #fff;
        margin-bottom: 20px;

        .sel {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 10px;

            .option-box {
                width: 100%;
                position: absolute;
                top: 48px;
                left: 0;
                z-index: 100000;
                // top: 35px;
                // left: -100px;
                // width: 270px;
                // height: 264px;
                border-radius: 3px;
                background-color: #12042E;
                border: 1px solid #C198FF;
                padding-left: 26px;
                padding-right: 26px;
                box-sizing: border-box;

                .items {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            .radio {
                cursor: pointer;
                position: relative;
                border: 1px solid #C198FF;
                width: 22px;
                height: 22px;
                border-radius: 11px;
                background-color: transparent;
                padding: 5px;
                box-sizing: border-box;

                .active {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background: #FF3A8A;
                }
            }
        }

        span {
            margin: 0 0 16px 10px;
        }

        .input {
            height: 48px;
            line-height: 48px;
            border-radius: 5px;
            border: 1px solid #C198FF;
            background: transparent;
            padding-left: 26px;
            box-sizing: border-box;
            color: #C198FF;

            &::placeholder {
                color: #C198FF;
            }

            img {
                width: 9px;
                height: 7px;
                line-height: 48px;
                cursor: pointer;
            }

            .inputs {
                &::placeholder {
                    color: #C198FF;
                }

                width: 100%;
                border: none;
                color: #C198FF;
                background: transparent;
            }
        }
    }

    .submit-box {
        text-align: center;
        width: 477px;
        justify-content: center;
        margin-top: 80px;
        width: 100%;

        .submit {
            text-align: center;
            width: 100%;
            height: 87px;
            border-radius: 50px 0 50px 0;
            background-color: #FF3A8A;
            color: #fff;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }

}


@media screen and(max-width:660px) {
    .x-page {
        padding: 0 40px 75px 40px;
    }

    .cards {
        margin: auto;
    }

}
</style>

<style lang="scss">

</style>