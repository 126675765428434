<template>
  <div class="custome-container">
    <Header @menu="menu" v-if="isRouterAlive && $store.state.isReload"></Header>
    <!-- 菜单部分 -->
    <Menu class="custome-menu" :visible.sync="showMenu" :links="menus"></Menu>
    <!-- 页面主体 -->
    <div class="custome-main" ref="Cust">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { Menu } from '@/components/UIKIT'
// import walletInfo from '@/components/wallet-info/index.vue'

import { mapGetters } from 'vuex'
// import langs from '@/langs';
// import { connect } from '@/plugins/metamask'
import { setI18nLanguage } from '@/plugins/i18n'
import store from '@/store'
import Header from '@/components/header'
import Footer from '@/components/footer'

export default {
  name: 'pancakeswapIndex',
  components: { Header, Menu, Footer },
  provide() {
    return {
      $app: this,
      reload: this.reload,
    }
  },
  data() {
    return {
      agentVisible: false,
      showMenu: false,
      showwalletInfo: true,
      isRouterAlive: true, //控制视图是否显示的变量
    }
  },
  filters: {
    langFilter(lang) {
      const LangJson = {
        zh: '中文',
        en: 'En',
      }
      return LangJson[lang]
    },
  },
  computed: {
    ...mapGetters(['lang', 'isMobile', 'keepAliveComponents']),
    accountStr() {
      let account = this.$store.state.account
      console.log('account------------', account)
      if (!account) {
        return ''
      }
      return account.slice(0, 5) + '...' + account.slice(account.length - 4)
    },

    menus: function () {
      //tip :这里的icon 外包用的是阿里的iconfont，星辰上传的。
      //修改时 需要重新上传到图标库 并修改icon的class
      //还要修改index.html里的引入链接！！！
      return [
        {
          id: 'home',
          icon: '',
          active: true,
          name: "KOL List",
          to: '/',
        },
        {
          id: 'mall',
          icon: '',
          active: false,
          name: "需求广场",
          to: '/public-square',
        },
        {
          id: 'my-nft',
          icon: '',
          active: false,
          name: "推广渠道",
          to: '/promotion-channels',
        },
        {
          id: 'work',
          icon: '',
          active: false,
          name: "关于我们",
          to: '/more',
        }
      ]
    },
  },
  methods: {
    menu() {
      this.showMenu = !this.showMenu;
    },
    reload() {
      this.isRouterAlive = false //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true //再打开
      })
    },
    handleClose() {
      this.showMenu = false
    },
    switchLang() {
      console.log(this.lang)
      let lang = this.lang == 'zh' ? 'en' : 'zh'
      this.$store.commit('SET_LANG', lang)
      console.log(this.$store.state.lang)
      store.dispatch('changeload')
      setI18nLanguage(lang)
    }
  },
  mounted() {

    // console.log(i18nText('physical-strength'));
  },
  watch: {
    // 方法1
    $route: {
      handler: function () {
        this.$refs.Cust.scrollTop = 0
      },
      deep: true
    }
  },
}
</script>
<style scoped lang="scss">
.custome-main {
  flex: 1;
  // overflow-y: scroll;    
  -webkit-overflow-scrolling: touch;
}

.right-ctrl,
.left-ctrl {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 3px;

  .wallet-btn,
  .lang-btn {
    // display: flex;
    margin-right: 13px;
  }
}

.left-ctrl {
  right: auto;
  left: 0;
  display: flex;
  flex-direction: row;
  padding-bottom: 0;

  .left-ctrl__menu {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: row;

    &>div {
      display: flex;
    }

    .left-ctrl__btn {
      cursor: pointer;
      width: 64px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .left-ctrl__logo-icon {
      width: 70px;
    }

    .left-ctrl__logo-text {
      width: 120px;
      // margin-left: 10px;
    }

    .left-ctrl__menu-collapse {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
