/*
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-22 16:17:44
 * @Description:
 */
import { setI18nLanguage } from '@/plugins/i18n'
export default {
  // SET_CHAIN: (state, chain) => {
  //     state.chain = chain
  // },
  SET_CHAIN_ID: (state, chainId) => {
    state.chainId = chainId
  },
  SET_ACCOUNT: (state, account) => {
    state.account = account
  },
  SET_LANG: (state, lang) => {
    state.lang = lang
    setI18nLanguage(lang)
  },
  SET_INDEX: (state, selectIndex) => {
    state.selectIndex = selectIndex
  },
  
  SET_IS_BSC_CHAIN: (state, isBscChain) => {
    state.isBscChain = isBscChain
  },
  SET_SIG_STR: (state, sigStr) => {
    state.sigStr = sigStr
  },
  SET_White_User:(state, whiteUser)=>{
    state.whiteUser = whiteUser
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_USER_LEVEL: (state, userLevel) => {
    state.userLevel = userLevel
  },
  SET_MEMBER_TYPE: (state, memberType) => {
    state.memberType = memberType
  },
  SET_chainInfo: (state, chainInfo) => {
    state.chainInfo = chainInfo
  },
  
  
  SET_IS_PASS_INPUT_INVITE_CODE: (state, passedAccount) => {
    if (passedAccount === false)
      return localStorage.setItem('isPassInputInviteCode', false)
    let oldVal = localStorage.getItem('isPassInputInviteCode')
    let res = null
    if (!oldVal) {
      res = passedAccount
      localStorage.setItem('isPassInputInviteCode', res)
    } else {
      res = oldVal.split(',')
      let hasExist = res.find((account) => account === passedAccount)
      if (!hasExist) {
        res.push(passedAccount)
        localStorage.setItem('isPassInputInviteCode', res)
      }
    }
    console.log('passed account', res)
  },
  SET_USER_IS_ALREADY_REGISTED: (state, userISAlreadyRegisted) => {
    state.userISAlreadyRegisted = userISAlreadyRegisted
  },
  SET_PFTContract_Address: (state, PFTContractAddress) => {
    state.PFTContractAddress = PFTContractAddress
  },
  // 冲加载
  toReload(state, res) {
    state.isReload = res //先关闭，
  },
  keepAlive (state, component) {
    // 注：防止重复添加（当然也可以使用Set）
    !state.keepAliveComponents.includes(component) && 
      state.keepAliveComponents.push(component)
  },
  noKeepAlive (state, component) {
    const index = state.keepAliveComponents.indexOf(component)
    index !== -1 &&
      state.keepAliveComponents.splice(index, 1)
  }
}
