
import request from '@/plugins/axios'

//表单提交
export function submit (data){
  return request({
    url: '/blade-memberHelp/memberHelp/submit',
    method: 'post',
    data,
  })
}

export function dictionary (params){
    return request({
      url: '/blade-system/dict-biz/dictionary',
      method: 'get',
      params,
    })
  }

  export function kolList (params){
    return request({
      url: '/blade-daoMember/daoMember/list',
      method: 'get',
      params,
    })
  }
  export function kolDetail (params){
    return request({
      url: '/blade-daoMember/daoMember/detail/platformAndService',
      method: 'get',
      params,
    })
  }
  export function cooperationDemandDetail (params){
    return request({
      url: '/blade-cooperationDemand/cooperationDemand/detail',
      method: 'get',
      params,
    })
  }
// 饼图的数据
  export function getEcharts (params){
    return request({
      url: '/blade-system/dict-biz/dictionary',
      method: 'get',
      params,
    })
  }

  // 申请成为会员
  export function applyKol (data){
    return request({
      url: '/blade-daoMember/daoMember/apply',
      method: 'post',
      data,
    })
  }

  //kol 预约的列表
  export function getkols (params){
    return request({
      url: '/appointmentCooperation/select',
      method: 'get',
      params,
    })
  }

  // 预约kol
  export function setkols (data){
    return request({
      url: '/appointmentCooperation/submit',
      method: 'post',
      data,
    })
  }

