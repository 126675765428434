<template>
    <div>
        <Dia :title="$t('nowUp')">
            <div class="dao-inner">
                <div class="limit">
                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('Identity')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="Unlimited.identity" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in IDList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('connetName')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="Unlimited.contactsName" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('sex')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="Unlimited.gender" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in sexList" :key="item.id" :label="item.dictValue"
                                :value="item.dictValue">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('Country/Region')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="Unlimited.country" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in addressArr" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles" v-if="Unlimited.country == '0'">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('otherCountry')"></div>
                    </div>
                    <div class="input" v-if="Unlimited.country == '0'">
                        <el-input v-model="Unlimited.otherCountry" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit">Telegram ID</div>
                    </div>
                    <div class="input">
                        <el-input v-model="Unlimited.telegramId" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('OtherInformation')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="Unlimited.otherContact" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                </div>


                <div v-if="Unlimited.identity == 1">
                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('ProjectName')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.projectName" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                </div>

                <div v-if="Unlimited.identity == 0">

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('platform')"></div>
                    </div>
                    <div class="platform">
                        <div v-for="(item, index) in platFormArr" :key="index" class="platbox"
                            :style="item.isChecked ? 'border-color:#FF3A8A;color:#FF3A8A' : ''"
                            @click="checkEvent(item, index)">
                            {{ item.name }}
                        </div>
                    </div>



                    <div class="twitterbox pbox" v-show="platFormArr[0].isChecked">
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('TwitterNi')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="twitterObj.nickName" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('TwitterFans')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="twitterObj.followers" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('TwitterAccLink')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="twitterObj.accountUrl" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                    </div>

                    <div class="telegrambox pbox" v-show="platFormArr[1].isChecked">
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('TelegramNi')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="telegramObj.nickName" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('TelegramFans')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="telegramObj.followers" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('TelegramAccontLink')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="telegramObj.accountUrl" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                    </div>

                    <div class="youtubebox pbox" v-show="platFormArr[2].isChecked">

                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('YoutubeNi')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="youtubeObj.nickName" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('YoutubeFans')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="youtubeObj.followers" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('YoutubeAccountLink')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="youtubeObj.accountUrl" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                    </div>

                    <div class="otherplatbox pbox" v-show="platFormArr[3].isChecked">
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('otherPlatName')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="otherPlatObj.platName" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('Ni')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="otherPlatObj.nickName" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('fans')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="otherPlatObj.followers" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                        <div class="titles">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('selfAccountLink')"></div>
                        </div>
                        <div class="input">
                            <el-input v-model="otherPlatObj.accountUrl" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                    </div>
                </div>

                <div v-if="Unlimited.identity == 2">
                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('chinnName')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.distributor" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('ApproximateUsers')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.approximateUsersNumber" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                </div>

                <div class="btn" v-text="$t('Submit')" @click="sub">

                </div>
            </div>
        </Dia>
        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
    </div>

</template>

<script>
import TipDialog from '@/components/TipDialog'
import { dictionary, signUp, additionalUser } from '@/api/user'
import Dia from '@/components/Dialog'
import { updateUser } from '@/utils'
export default {
    components: {
        TipDialog, Dia
    },
    props: {
        isWhitelistUsers: {
            type: Boolean,
            default: () => { return false }
        },
        currenId: {
            type: String,
            default: () => {
                return ''
            }

        }
    },
    data() {
        return {
            needSel: false,
            placeSel: false,
            partnersSel: false,
            remunerationSel: false,
            idSel: false,
            needList: [
                { label: this.$t('Giveaway'), value: 'China', id: 1 },
                { label: this.$t('twitterSpace'), value: 'China', id: 2 },
                { label: this.$t('ProjectPromotion'), value: 'China', id: 3 },
                { label: this.$t('activePuish'), value: 'China', id: 4 },
                { label: this.$t('actionUderline'), value: 'China', id: 5 },
                { label: this.$t('otherIpnut'), value: 'China', id: 6 },
            ],
            placeList: [
                { label: this.$t('online'), value: 'China', id: 1 },
                { label: this.$t('unline'), value: 'China', id: 2 },
            ],
            seekPartnersList: [
                { label: this.$t('KOL'), value: 'China', id: 1 },
                { label: this.$t('otherParties'), value: 'China', id: 2 },
                { label: this.$t('otherPromotion'), value: 'China', id: 3 },
            ],
            remunerationList: [
                { label: this.$t('howManyDollarsStart'), value: 'China', id: 1 },
                { label: this.$t('mainlyBasedExchange'), value: 'China', id: 2 },
                { label: this.$t('DpecificIndividually'), value: 'China', id: 3 }
            ],
            IDList: [
                { label: this.$t('KOL'), value: 'China', id: 1 },
                { label: this.$t('otherParties'), value: 'China', id: 2 },
                { label: this.$t('otherPromotion'), value: 'China', id: 3 },
            ],
            addressArr: [
                { address: this.$t('korea'), id: 1 },
                { address: this.$t('Japan'), id: 2 },
                { address: this.$t('Russia'), id: 3 },
                { address: this.$t('Singapore'), id: 4 },
                { address: this.$t('U.S.A'), id: 5 },
                { address: this.$t('India'), id: 6 },
                { address: this.$t('britain'), id: 7 },
                { address: this.$t('China'), id: 8 },
            ],
            sexList: [
                { label: this.$t('man'), id: 1 },
                { label: this.$t('women'), id: 2 },
            ],
            platFormArr: [{ name: 'Twitter', isChecked: false },
            { name: 'Telegram', isChecked: false },
            { name: 'Youtube', isChecked: false },
            { name: this.$t('otherIpnut'), isChecked: false },
            ],
            twitterObj: {
                platformName: 'Twitter',
                nickName: '',
                followers: '',
                accountUrl: ''
            },
            telegramObj: {
                platformName: 'Telegram',
                nickName: '',
                followers: '',
                accountUrl: ''
            },
            youtubeObj: {
                platformName: 'Youtube',
                nickName: '',
                followers: '',
                accountUrl: ''
            },
            otherPlatObj: {
                platformName: '',
                nickName: '',
                followers: '',
                accountUrl: ''
            },
            form: {
                projectName: '',
                distributor: '',
                approximateUsersNumber: '',
            },
            noform: {
                projectIntro: '',
                webLink: '',
                twitter: '',
                quLink: '',
                projectHope: '',
                publishT: '',
                serve: '',
                QFII: '',
                jys: '',
                token: '',
                startTime: '',
                tokenSatrt: '',
                china: ''
            },
            Unlimited: {
                contactsName: '',
                country: '',
                otherCountry: '',
                gender: '',
                identity: null,
                telegramId: '',
                otherContact: ''
            },
            platforms: [],
            isUnlimited: false,
            overNeed: true,
            TipText: '',
            tipShow: false,
            dis: false
        }
    },
    async mounted() {
        const { data } = await dictionary({ code: "position_key" });
        this.IDList = data;
        {
            const { data } = await dictionary({ code: "form_contry" })
            this.addressArr = data
        }
        {
            const { data } = await dictionary({ code: "sex" })
            this.sexList = data
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        // closeDia() {
        //     this.PublishNowVisible = false;
        // },
        optionActive(list, item, index, mod, type) {
            console.log();
            type === 1 ? this.Unlimited[mod] = item.label : type === 2 ? this.form[mod] = item.label : this.noform[mod] = item.label;

            list.forEach((it, i) => {
                if (i === index) {
                    list[i].active = true;
                } else {
                    it.active = false;
                }
            })
            this.reset()
        },
        reset() {
            this.needSel = false;
            this.placeSel = false;
            this.partnersSel = false;
            this.remunerationSel = false;
            this.idSel = false;
        },
        async sub() {
            this.overNeed = true;

            if (this.Unlimited['country'] == '0' && this.Unlimited['otherCountry'] == '') {
                this.overNeed = false;
            } else {
                for (const key in this.Unlimited) {
                    if (key != 'otherCountry' && !this.Unlimited[key]) {
                        this.overNeed = false;
                    }
                }
            }

            if (this.overNeed && this.Unlimited.identity == '1' && this.form.projectName == '') {
                this.overNeed = false;
            }

            if (this.overNeed && this.Unlimited.identity == '2' && (!this.form.distributor || !this.form.approximateUsersNumber)) {
                this.overNeed = false;
            }

            if (this.overNeed && this.Unlimited.identity == '0' && this.platforms.length) {
                this.platforms.forEach(item => {
                    for (const key in item) {
                        if (!item[key]) {
                            this.overNeed = false;
                        }
                    }
                })
            }

            if (!this.overNeed) {
                // this.tipShow = true;
                // this.TipText = this.$t('mustInput');
                this.$toast.show(this.$t('mustInput'))
                return;
            }
            if (this.Unlimited['countryRegion'] != '0') {
                this.Unlimited['otherCountry'] = '';
            }
            let Ent = { ...this.form, ...this.Unlimited, memberId: this.$store.state.userInfo.user_id }
            if (this.currenId!='') {
                Ent.demandId = this.currenId
                const { code } = await signUp(params);
                if (code == 200) {
                    this.$emit('subDis')
                    this.$toast.show(this.$t('Operation-succeeded'));
                }
            }
            let params = {};
            params = Ent;
            await  additionalUser({ toSignUpEntity: Ent, platforms: this.platforms });
            this.$parent.closeDia();
            updateUser();
            this.$toast.show(this.$t('oneDay'))
        },
        checkEvent(item, index) {
            item.isChecked = !item.isChecked;
            if (index == 0 && item.isChecked) {
                this.platforms.push(this.twitterObj)
            } else if (index == 0 && !item.isChecked) {
                this.platforms.forEach((item, index) => {
                    if (item.platformName == 'Twitter') {
                        this.platforms.splice(index, 1)
                    }
                })

            }

            if (index == 1 && item.isChecked) {
                this.platforms.push(this.telegramObj)
            } else if (index == 1 && !item.isChecked) {
                this.platforms.forEach((item, index) => {
                    if (item.platformName == 'Telegram') {
                        this.platforms.splice(index, 1)
                    }
                })

            }


            if (index == 2 && item.isChecked) {
                this.platforms.push(this.youtubeObj)
            } else if (index == 2 && !item.isChecked) {
                this.platforms.forEach((item, index) => {
                    if (item.platformName == 'Youtube') {
                        this.platforms.splice(index, 1)
                    }
                })

            }


            if (index == 3 && item.isChecked) {
                this.platforms.push(this.otherPlatObj)
            } else if (index == 3 && !item.isChecked) {
                this.platforms.forEach((item, index) => {
                    if (item.platformName == '') {
                        this.platforms.splice(index, 1)
                    }
                })
            }


            console.log(this.platforms, 'this.platforms');

        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    margin-bottom: 20px;
}

/deep/ .el-input__inner {
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
}

/deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #C198FF;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #C198FF;
}

/deep/ .el-select {
    width: 100%;
}

.must {
    font-size: 30px;
    color: #FF3A8A;
    margin-right: 15px;
    width: 10px;
}

.dao-inner {
    width: 87%;
    margin: 0 auto;
    box-sizing: border-box;

    .titles {
        display: flex;
        align-items: center;
        line-height: 32px;
        margin-bottom: 16px;
        margin-top: 16px;

        .tit {
            font-size: 24px;
            color: #FFFFFF;
        }
    }
}

.platform {
    display: flex;
    justify-content: space-between;

    .platbox {
        width: 127px;
        height: 48px;
        border: 1px solid #C198FF;
        border-radius: 10px;
        color: #8361B5;
        font-size: 18px;
        text-align: center;
        line-height: 48px;
    }
}

.twitterbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #FFE698;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #FFE698;
    }

    /deep/ .el-input__inner:hover {
        border-color: #FFE698;
    }
}



.telegrambox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #98FF9D;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #98FF9D;
    }

    /deep/ .el-input__inner:hover {
        border-color: #98FF9D;
    }
}


.youtubebox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #5792FF;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #5792FF;
    }

    /deep/ .el-input__inner:hover {
        border-color: #5792FF;
    }
}

.otherplatbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #6FECE7;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #6FECE7;
    }

    /deep/ .el-input__inner:hover {
        border-color: #6FECE7;
    }
}

.pbox {
    padding-bottom: 70px;
}

.btn {
    width: 534px;
    height: 88px;
    cursor: pointer;
    background: #FF3A8A;
    border-radius: 40px 5px 40px 5px;
    text-align: center;
    line-height: 88px;
    font-size: 36px;
    color: #FFFFFF;
    margin: 51px auto 0 auto;
}
</style>

<style lang="scss">
.el-select-dropdown__item.selected {
    color: #C198FF !important;
}

.el-popper[x-placement^=bottom] .popper__arrow {
    display: none;
}

.el-select-dropdown {
    background: #12042E !important;
    border: none !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
    background-color: #12042E !important;
}
</style>