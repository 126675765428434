<template>
    <div>
        <transition enter-active-class="animate__animated fadeInDown">
            <div :class="['header', 'animated', 'flex', isFixed ? 'fixed' : '']" id="searchBar">
                <img src="@Img/logo.png" alt="" class="logo" @click="goRouter">
                <div class="flex">
                    <ul class="flex menus">
                        <!-- <li  @click="$router.push('/')">KOL DAO</li>
                        <li @click="$router.push('/public-square')">{{$t('DemandPool')}}</li>
                        <li @click="$router.push('/promotion-channels')">{{$t('PromotionChannels')}}</li>
                        <li @click="$router.push('/more')">{{$t('LearnMore')}}</li> -->
                        <li @click="Select(index)" v-for="(item,index) in menusArr" :key="index" :class="[$store.state.selectIndex == index?'active':'']">
                            {{item.name}}
                        </li>
                    
                    </ul>
                    <div class="flex">
                        <!-- <div class="langSel" @mouseenter="langSel = true" @mouseleave="langSel = false">
                            <div class="input">
                                <span class="no-warp">{{ lang | getLangLabel }}</span> <img
                                    src="../../assets/uni-meta/sel-opt.png" alt="">
                            </div>
                            <div>
                                <SeleOption :list="langList" @active="active" v-show="langSel" :width="200" />
                            </div>
                        </div> -->
                        <div class="lang">
                            <el-select v-model="lang" :placeholder="$t('pleaseSele')" popper-class="langs"
                                @change="switchLang">
                                <el-option v-for="item in langList" :key="item.id" :label="item.label"
                                    :value="item.value">

                                </el-option>
                            </el-select>
                        </div>
                        <div class="chain">
                            <el-select v-model="chainId" :placeholder="$t('pleaseSele')"
                                :disabled="$store.state.account&&$store.state.sigStr ? true: false" @change="slectChina">
                                <template #prefix>
                                    <div class="sel-icon">
                                        <img src="../../assets/uni-meta/bsc.png" alt="" class="sel-icon"
                                            v-if="chainId==56" />
                                        <img src="../../assets/uni-meta/eth.png" alt="" class="sel-icon" v-else />
                                    </div>
                                </template>

                                <el-option v-for="(item,index) in options" :key="item.id" :label="item.label"
                                    :value="item.value">
                                    <span style="float: left;">
                                        <img :src="item.img" alt="" :class="['option-img',index==1?'biger':'']">
                                    </span>
                                    <span style="padding-left: 6px;">{{ item.label }}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <!-- <div :class="['langSel', 'chinaSel', $store.state.account ? 'disable' : '']"
                            @mouseenter="!$store.state.account ? chinaSel = true : ''">
                            <div class="input">
                                <span>{{ chainId | getChinaLabel }}</span> <img src="../../assets/uni-meta/sel-opt.png"
                                    alt="" v-show="!$store.state.account">
                            </div>
                            <div @mouseleave="chinaSel = false">
                                <SeleOption :list="options" @active="chinaActive" v-show="chinaSel" :width="270"
                                    :top="70" :left="-10" />
                            </div>
                        </div> -->

                        <Popover v-if="accountStr&&$store.state.sigStr" placement="bottom-end" :offset="250" :disabled="!accountStr||!$store.state.sigStr"
                            trigger="hover">
                            <wallet-info @agentClickCallback="agentVisible = true"></wallet-info>
                            <div slot="reference" class="connet-btn">
                                {{ accountStr }}
                            </div>
                        </Popover>
                        <button class="connet-btn" @click="dialogVisible = true" v-else>
                            {{$t('wallet-connect')}}
                        </button>
                        <img src="@Img/customer.png" alt="" class="customer" @click="customerDialogVisible = true">
                        <div class="left-ctrl__btn" @click="menu">
                            <img src="@Img/mens-col.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="clone" v-if="isFixed">
        </div>
        <el-dialog :visible.sync="dialogVisible" custom-class="wallets-dialog" :show-close="false">
            <template>
                <div class="title">{{$t('wallet-connect')}}</div>
                <div class="wallets">
                    <div class="tips">
                        {{$t('CurrentlyFor')}}{{ chainId === 1 ? 'Ethereum' : 'BNB Smart Chain' }}{{$t('OtherChain')}}
                    </div>
                    <BlockOption :list="walletList" @active="walletSelect"></BlockOption>
                    <div class="agreement">
                        {{$t('ByUnimeta')}} <a :href="`https://www.unimeta.top/service-${$store.state.lang}.html`"
                            v-text="$t('TermsServiceConfirm')"></a>{{$t('thatReadUnimeta')}}<a
                            :href="`https://www.unimeta.top/disclaimer-${$store.state.lang}.html`">{{$t('AgreementDisclaimer')}}</a>
                    </div>
                </div>
            </template>
        </el-dialog>

        <connet-custom v-if="customerDialogVisible">
        </connet-custom>

        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
    </div>


</template>

<script>
import { WalletConnet } from '@/plugins/walletconnet'
import { connect } from '@/plugins/metamask'
import { Popover } from '@/components/UIKIT'
import walletInfo from '@/components/wallet-info/index.vue'
import bsc from "@Img/bsc.png"
import eth from "@Img/eth.png"
import MetaMask from "@Img/metamask.png"
// import WalletConnect from "@Img/walletconnect.png"
import { isPc } from '../../utils';
import { setI18nLanguage } from '@/plugins/i18n'
// import SeleOption from '../SletOption.vue'
import BlockOption from '../BlockOption.vue'
import store from '../../store'
import ConnetCustom from './components/ConnetCustom.vue'
import TipDialog from '@/components/TipDialog'

// import store from '@/store'
export default {
    components: { Popover, walletInfo, BlockOption, ConnetCustom,TipDialog },
    filters: {
        getLangLabel(val) {
            return val == 'en' ? 'English' : '中文';
        },
        getChinaLabel(val) {
            return val == 1 ? 'Ethereum' : 'BNB Smart Chain';
        }
    },
    computed: {
        accountStr() {
            let account = this.$store.state.account
            console.log('account------------', account)
            if (!account) {
                return ''
            }
            return account.slice(0, 5) + '...' + account.slice(account.length - 4)
            // console.log(account.slice(0,5)+'...'+account.slice(account.length-4))
            // return ''
        },
        
        ischainId (){
            return this.$store.state.isBscChain;
        },

    },
    watch:{
        ischainId(newVal,old){
            this.chainId = newVal ? 56 : 1
            console.log(newVal,old);
        }
    },
    data() {
        return {
            lang: store.state.lang,
            chainId:1,
            TipText: '',
            tipShow: false,
            langList: [
                { label: 'English', value: 'en', id: 1 },
                { label:  '中文', value: 'zh', id: 2 },
            ],
            menusArr:[
                {link:'/',name:'KOL List',active:true},
                {link:'/public-square',name:this.$t('DemandPool'),active:false},
                {link:'/promotion-channels',name:this.$t('PromotionChannels'),active:false},
                {link:'/more',name:this.$t('LearnMore'),active:false}
            ],
            options: [
                {
                    value: 1, label: 'Ethereum', img: eth, pLabel: 'ETH', id: 1
                },
                {
                    value: 56, label: "Binnance smart chain", img: bsc, pLabel: 'BSC', id: 2
                }
            ],
            walletList: [
                {
                    index: 0, label: 'MetaMask', img: MetaMask, active: true
                }
                // ,
                // {
                //     index: 1, label: "WalletConnect", img: WalletConnect, active: false
                // }
            ],
            agentVisible: false,
            isFixed: false,
            dialogVisible: false,
            isPc: true,
            langSel: false,
            chinaSel: false,
            citySel: false,
            IDSel: false,
            customerDialogVisible: false,
            customerForm: {
                name: '',
                city: '中国',
                ID: 'KOL',
                TelegramID: '',
                otherConnet: '',
                hopeDo: ''
            },
            cityList: [
                { label: '中国', value: 'China', active: true },
                { label: '美国', value: 'China', active: false },
                { label: '英国', value: 'China', active: false },
                { label: '伦敦', value: 'China', active: false },
            ],
            IDList: [
                { label: 'KOL', value: 'China', active: true },
                { label: '项目方', value: 'China', active: false },
                { label: '其他推广方', value: 'China', active: false },
            ]
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('scroll', () => {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                var height = document.querySelector('#searchBar').clientHeight;
                if (scrollTop >= height) {
                    this.isFixed = true;
                } else if (scrollTop == 0) {
                    this.isFixed = false;
                }
            })
            this.chainId = this.$store.state.isBscChain?56:1;
            console.log(this.chainId,'this.chainId');
        })
        this.isPc = isPc();
        this.menusArr.forEach(item=>item.active=false);
    },
    methods: {
        closeDia() {
            this.customerDialogVisible = false;
        },
        goRouter(){
            this.$router.push('/');
            this.Select(0)
        },
        Select(index){
            this.$store.commit('SET_INDEX', index)
            this.menusArr.forEach(item=>item.active=false);
            this.menusArr[index].active = true;
            this.$router.push(this.menusArr[index].link);
        },
        async metaMaskConnet() {
            connect();
            this.dialogVisible = false;
        },
        async connet() {
            await WalletConnet(this.chainId);
            this.dialogVisible = false;
        },
        menu() {
            this.$emit('menu')
        },
        active(item) {
            this.langSel = false;
            this.lang = item.value;
        },
        chinaActive(item) {
            this.chinaSel = false;
            if (this.$store.state.account) return;
            if (this.$store.state.account) {
                console.log(1111);
            } else {
                this.chainId = item.value;
                this.$store.state.chainId = this.chainId;
            }
        },
        walletSelect(item) {
            item.index ? this.connet() : this.metaMaskConnet();


           
        },
        slectChina(){
            console.log(this.$store.state.isBscChain,this.chainId);
            // if(this.$store.state.isBscChain&&this.chainId!=56) {
            //     this.tipShow = true;
            //     this.TipText = this.$t('okLian');
            //     this.chainId = 56;
            // }else if(!this.$store.state.isBscChain&&this.chainId!=1) {
            //     this.tipShow = true;
            //     this.chainId = 1
            //     this.TipText = this.$t('okLian');
            // }
            console.log(this.$store.state.isBscChain,this.chainId);
        },
        cityActive(val) {
            console.log(val);
        },
        optionActive(item, i, type) {
            if (type === 'ID') {
                this.customerForm.ID = item.label;
                this.IDList.forEach((item, index) => {
                    if (i === index) {
                        
                        this.IDList[i].active = true;
                    } else {
                        item.active = false;
                    }
                    this.IDSel = false
                })
            } else {
                this.customerForm.city = item.label;
                this.cityList.forEach((item, index) => {
                    if (i === index) {
                        this.cityList[i].active = true;
                    } else {
                        item.active = false;
                    }
                    this.citySel = false

                })
            }

        },
        switchLang() {
            console.log(this.lang, 'this.lang')
            // let lang = this.lang == 'zh' ? 'en' : 'zh'
            // this.$store.commit('SET_LANG', lang)
            // console.log(this.$store.state.lang)
            this.$store.state.lang = this.lang;
            store.dispatch('changeload')
            setI18nLanguage(this.lang)
        }
    }
}
</script>

<style scoped lang="scss">
.lang {
    /deep/ .el-select {
        width: 120px !important;
    }

    /deep/ .el-input__inner {
        text-align: left;
        border: none !important;
    }
}

/deep/ .el-input__inner {
    height: 72px !important;
    width: 226px;
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 20px;
    font-size: 18px;
    color: #fff;
}

/deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #C198FF;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #C198FF;
}

/deep/ .el-select {
    width: 226px !important;
    height: 72px;
    width: 100%;
}
.active {
    color: #FF3A8A;
}
.option-img {
    width: 16px;
    vertical-align: middle;
    margin-right: 7px;
}

.sel-icon {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: 25px;
        height: auto;
        vertical-align: middle;
    }
}

.tips {
    font-size: 12px;
    background: #f1f1f1;
    padding: 8px 10px;
    border-radius: 5px;
}

.disable {
    background-color: #ccc;
    color: #eee;
    border: none !important;
}

.wallets {
    font-size: 18px;
    color: #333;

    .title {
        height: 60px;
        line-height: 60px;

    }

    .tips {
        background: transparent;
        color: #7851B3;
    }

    .agreement {
        margin-top: 35px;
        color: #7851B3;

        a {
            color: #FF3A8A;
            text-decoration: underline;
        }
    }

    img {
        width: 40px;
    }
}


.fixed {
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
}

.clone {
    height: 150px;
    width: 100%;
}

.header {
    height: 150px;
    background-color: #371381;
    color: #fff;
    padding: 0 3.64vw;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-warp {
    white-space: nowrap;
}

.langSel {
    position: relative;
    width: 60px;
    margin-right: 34px;

    .input {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            margin-right: 8px;
        }

        cursor: pointer;

        img {
            width: 9px;
        }
    }
}

.chinaSel {
    width: 226px;
    margin-right: 15px;
    padding: 25px 33px 29px 43px;
    border-radius: 50px;
    border: 1px solid #FF3A8A;
}

select::-ms-expand {
    display: none;
}

select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.logo {
    width: 200px;
    // height: 126px;
    margin-right: 70px;
    cursor: pointer;
    @media screen and (max-width:1800px) {
        margin-right: 10px;
    }

    @media screen and (max-width:1200px) {
        width: 200px;
    }
}

.chian {
    margin-right: 15px;
}

.connet-btn {
    width: 9.53vw;
    height: 72px;
    overflow: hidden;
    line-height: 72px;
    margin-right: 60px;
    text-align: center;
    border-radius: 36px;
    color: #fff;
    font-size: 20px;
    border: none;
    background-color: #D06DC7;
    cursor: pointer;
}

.left-ctrl__btn img {
    display: none;
    width: 35px;
    margin-left: 10px;
}

.menus {
    // margin-right: 100px;

    // @media screen and (max-width:1800px) {
    //     margin-right: 20px;
    // }

    li {
        font-size: 24px;
        margin-right: 80px;

        // @media screen and (max-width:1800px) {
        //     margin-right: 30px;
        // }

        cursor: pointer;
        white-space: nowrap;
    }
}
.biger {
    width: 25px;
    margin-right: 0;
}
@media screen and (max-width:768px) {
    .menus {
        margin-right: 0;

        li {
            margin-right: 30px;
        }
    }

    .lang {
        display: none;
        /deep/ .el-select {
            width: 200px !important;
        }

        /deep/ .el-input__inner {
            text-align: right;
            padding-right: 85px !important;
            border: none !important;
        }
    }

    .chain {
        /deep/ .el-input__inner {
            border-radius: 100px !important;
            width: 10px;
        }

        /deep/ .el-input__suffix {
            right: -15px;
        }

        .sel-icon img {
            width: 40px;
        }

        /deep/ .el-input--suffix .el-input__inner {
            padding-right: 50px;
        }

        .el-select {
            width: 100px !important;
        }
    }

    /deep/ .el-input__suffix {
        height: 115%;
    }

    .connet-btn {
        width: 190px;
        margin-left: 50px;
    }
}

@media screen and (max-width:570px) {
    .menus {
        display: none;
    }



    .left-ctrl__btn img {
        display: block;
    }
}






.customer {
    width: 34px;
    height: 36px;
    cursor: pointer;
}

.left-ctrl__menu-collapse {
    width: 24px;
    height: 24px;
}

.el-select {
    margin-right: 15px;
}

.wallets-dialog {
    .title {
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .el-dialog__title {
        color: #fff !important;
    }
}

.dialog {
    width: 60% !important;
    max-width: 670px;

    .customer-dialog {
        padding: 0 0 !important;

        .title {
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 30px;

            // padding: 40px 0;
        }

        .form-box {
            padding-top: 40px;
        }

        .form-item {
            display: flex;
            flex-direction: column;
            justify-content: start;
            font-size: 24px;
            color: #fff;
            margin-bottom: 20px;

            .sel {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 10px;

                .option-box {
                    width: 100%;
                    position: absolute;
                    top: 48px;
                    left: 0;
                    z-index: 100000;
                    // top: 35px;
                    // left: -100px;
                    // width: 270px;
                    // height: 264px;
                    border-radius: 3px;
                    background-color: #12042E;
                    border: 1px solid #C198FF;
                    // padding-left: 26px;
                    // padding-right: 26px;
                    box-sizing: border-box;

                    .items {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                .radio {
                    cursor: pointer;
                    position: relative;
                    border: 1px solid #C198FF;
                    width: 22px;
                    height: 22px;
                    border-radius: 11px;
                    background-color: transparent;
                    padding: 5px;
                    box-sizing: border-box;

                    .active {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: inline-block;
                        width: 13px;
                        height: 13px;
                        border-radius: 50%;
                        background: #FF3A8A;
                    }
                }
            }

            span {
                margin: 0 0 16px 10px;
            }

            .input {
                height: 48px;
                line-height: 48px;
                border-radius: 5px;
                border: 1px solid #C198FF;
                background: transparent;
                padding-left: 26px;
                box-sizing: border-box;
                color: #C198FF;

                &::placeholder {
                    color: #C198FF;
                }

                img {
                    width: 9px;
                    height: 7px;
                    line-height: 48px;
                    cursor: pointer;
                }

                .inputs {
                    &::placeholder {
                        color: #C198FF;
                    }

                    width: 100%;
                    border: none;
                    color: #C198FF;
                    background: transparent;
                }
            }
        }

        .submit-box {
            text-align: center;
            width: 477px;
            justify-content: center;
            margin-top: 80px;
            width: 100%;

            .submit {
                text-align: center;
                width: 100%;
                height: 87px;
                border-radius: 50px 0 50px 0;
                background-color: #FF3A8A;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
            }
        }

    }

}
</style>

<style lang="scss">
.langs {
    // /deep/ .el-popper[x-placement^=bottom] {
    // top: 90px !important;
    // }
}

.chain {
    .el-input__inner {
        border-radius: 50px !important;
    }
}

.wallets-dialog {
    width: 60% !important;

    @media screen and(max-width:768px) {
        width: 100% !important;
    }

    max-width: 670px;
    // height: 464px;
    margin: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px !important;
    background: url(../../assets/uni-meta/option-bg.png) no-repeat !important;
    background-size: 100% 100% !important;
    padding: 0px 60px !important;

    .el-dialog__title {
        color: #fff !important;
    }

    .el-dialog__header {
        padding-top: 0;
    }

    .el-dialog__body {
        padding-top: 0;
        color: #7851B3 !important;
    }
}

.el-icon-arrow-up:before {
    content: '\e790' !important;
}

.el-select .el-input .el-select__caret {
    color: #DF8CFF !important;
    transform: rotate(180) !important;
}
</style>