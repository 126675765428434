
<template>
  <div class="wallet-info-box" :class="[inner ? 'is-inner' : '']">
    <h2 class="custom-title fs-22">{{ $t("wallet") }}</h2>
    <div class="logout-btn">
      <Button type="error" size="small" @click.native="logout">{{
        $t("Sign-Out")
      }}</Button>
    </div>
    <div class="wallet-info__detail">
      <!-- <Input class="wallet-info__input" label="钱包地址" v-model="userInfo.address" disabled></Input> -->
      <div class="form-item-info">
        <div>
          {{ $t("wallet-address") }}
          <i
            class="copy-icon"
            @click="copyHandler"
            id="copy-text"
            :data-clipboard-text="$store.state.account"
          ></i>
        </div>
        <p>{{ $store.state.account }}</p>
      </div>

      <!-- <Input
        class="wallet-info__input"
        label="当前等级"
        v-model="form.level"
        disabled
        v-if="userLevel "
      > -->
      <!-- <svg
          @click="showAgentDialog"
          slot="label-prefix"
          class="svg-icon agent-info-icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-dailiwenhao"></use>
        </svg> -->
      <!-- </Input> -->

      <div class="form-item-info" v-if="userLevel">
        <div>
          {{ $t("current-level") }}
          <!-- <svg
            @click="showAgentDialog"
            slot="label-prefix"
            class="svg-icon agent-info-icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-dailiwenhao"></use>
          </svg> -->
        </div>
        <p>{{ userLevel | levelToStr }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Button} from "@/components/UIKIT";
import { mapGetters } from "vuex";
// import Clipboard from "clipboard";
import { toast } from "@/plugins/toast";
import CopyToClipBoard from "copy-to-clipboard";
import { i18nText } from "@/plugins/i18n";
import {disconnect} from "@/plugins/walletconnet"
export default {
  components: { Button},
  props: {
    inner: Boolean,
  },
  data() {
    return {
      form: {
        recInvitationCode: "",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "userLevel", "userISAlreadyRegisted"]),
    //已经完成注册
    // userISAlreadyRegisted() {
    //   let userData = this.$store.state.userInfo.user_data;
    //   //userData为空 代表没注册
    //   if (userData) {
    //     return !!Object.keys(userData).length;
    //   } else {
    //     return false;
    //   }
    // },
  },
  filters: {
    levelToStr(levelVal) {
      const list = [
        i18nText("basic-user"),
        i18nText("junior-agent"),
        i18nText("Senior-Agent"),
        i18nText("Super-Agent"),
      ];
      return levelVal || levelVal === 0 ? list[levelVal] : "";
    },
  },
  methods: {
    logout() {
      localStorage.selectIndex = 0; 
      this.$store.dispatch("LogOut");
      this.$store.dispatch('changeload')
      disconnect()
      toast({
        type: "success",
        msg: this.$t("logout-successful"),
      });
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    showAgentDialog() {
      this.$emit("agentClickCallback");
    },
    registHandler() {
      if (this.form.recInvitationCode) {
        this.$store.dispatch("regist", {
          inviteCode: this.form.recInvitationCode,
          userid: this.userInfo.id,
        });
      }
    },
    copyHandler() {
      CopyToClipBoard(this.userInfo.address);
      toast({
        type: "success",
        msg: this.$t("Copied-Successfully"),
      });

      // var clipboard = new Clipboard("#copy-text");

      // console.log("clipboard", clipboard,this.userInfo.address);

      // clipboard.on("success", (e) => {
      //   console.log('uuuu',e);
      //   toast({
      //     type: "success",
      //     msg: "复制成功",
      //   });
      //   // 释放内存
      //   clipboard.destroy();
      // });

      // clipboard.on("error", (e) => {
      //   console.log(e);
      //   // 不支持复制
      //   toast({
      //     type: "error",
      //     msg: "复制失败",
      //   });
      //   // 释放内存
      //   clipboard.destroy();
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.agent-info-icon {
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.wallet-info-box {
  width: 370px;
  min-height: 100px;
  padding: 12px;
  position: relative;
  &.is-inner {
    padding-bottom: 80px;
    .logout-btn {
      top: auto;
      bottom: 20px;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
    }
  }
  &.wallet-info--mobile {
    width: 100%;
  }
 
}
@media screen and (max-width:450px) {
  .wallet-info-box {
    width: 450px !important;
  }
  .fs-22 {
    font-size: 14px;
  }
}

.logout-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
.wallet-info__input {
  margin-bottom: 18px;
}
.wallet-info__detail {
  margin-top: 32px;
}

.form-item-info {
  margin-bottom: 18px;
  border-bottom: 1px dashed #d8d8d8;
  padding-bottom:6px;
  div {
    position: relative;
    height: 30px;
    line-height: 30px;
    color: #8b7b73;
    font-size: 14px;
  }
  p {
    color: #ffcd40;
    font-size: 14px;
    font-weight: bolder;
    word-break: break-all;
  }
}
.copy-icon {
  width: 13px;
  height: 13px;
  display: block;
  background: url("../../assets/copy-icon.png") no-repeat;
  background-size: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media screen and(max-width:768px) {
    width: 23px;
    height: 23px;
  }
}

@media screen and(max-width:768px) {
  .copy-icon {
    width: 23px;
    height: 23px;
  }
  .form-item-info {
    margin-top: 55px;
  } 
}

//特殊处理下
.flex-wrap {
  position: relative;
  .reg-btn {
    width: 60px;
    height: 40px;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #ffcd40;
    border-color: #ffcd40;
    &.el-button.is-disabled {
      color: #c0c4cc;
      border-color: #c0c4cc;
    }
  }
}
/deep/.z-ipt.z-sp input{
       width: calc(100% - 80px);
  }
</style>