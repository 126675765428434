<template>
    <div class="p-page">
        <div class="options">
            <OptionBox :optionArr="optionArr" @choose="choose"></OptionBox>
        </div>
        <div class="context" v-if="list.length">
            <ul class="list-box">
                <li class="top-tip">
                    <p v-text="$t('youNownTou')"></p>
                    <p>{{ $t('TellIsGood') }}</p>
                </li>
                <li class="list-con scal" v-for="(item, index) in list" :key="index">

                    <div class="left" style="display:table">
                        <span style="display:table-cell;vertical-align:middle;">
                            <img :src="item.logo?item.logo:logo" alt="" />
                        </span>
                    </div>

                    <div class="center">
                        <p class="font-30">{{item.distributor}}</p>
                        <p>{{ $t('chinnalType') }}:{{ item.distributorType }}</p>
                        <p>{{ $t('officialWebsite') }}: <a :href="item.distributorWebsite" target="_blank"> <i>{{item.distributorWebsite}}</i></a> </p>
                        <p>{{ $t('chinnalUser') }}:<span v-text="(item.distributorFansNumber<5000?'<5000':item.distributorFansNumber)"></span> </p>
                        <p>{{ $t('Channel-Description') }}:<span>{{ item.descriptionOfChannel }}</span> </p>

                    </div>

                    <div class="right">
                        <div class="btns" v-if="!item.active">
                            <p @click="vote(index, 1, item.id)" class="jelly">{{ $t('good') }}</p>
                            <p @click="vote(index, 2, item.id)" class="jelly">{{ $t('soso') }}</p>
                            <p @click="vote(index, 3, item.id)" class="jelly">{{ $t('bad') }}</p>
                        </div>
                        <div class="btns active" v-else>
                            <p class="flex">
                                <span v-text="$t('good')"></span> <span>{{ item.easyUseNumber }}{{ $t('piao') }}</span>
                            </p>
                            <p class="flex"><span v-text="$t('soso')"></span>
                                <span>{{ item.justSoNumber }}{{ $t('piao') }}</span></p>
                            <p class="flex"><span v-text="$t('bad')"></span>
                                <span>{{ item.badReviewNumber }}{{ $t('piao') }}</span></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else class="empty">
            {{ this.$t('no-con') }}
        </div>
        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
        <pages-vue @handleCurrentChange="handleCurrentChange" :total="total" :size="params.size" v-if="total">
        </pages-vue>
    </div>
</template>

<script>
import logo from "../../assets/uni-meta/foot-1.png"
import OptionBox from '@/components/optionbox'
import pagesVue from "../../components/pages.vue"
import TipDialog from '@/components/TipDialog.vue'
import { dictionary, distributorsList, evaluation } from '@/api/user'
export default {
    components: { OptionBox, pagesVue, TipDialog },
    data() {
        return {
            tipShow: false,
            TipText: '',
            logo,
            list: [],
            optionArr: [
                { title: this.$t('PromotionChannels'), option: [], choose: 0 },
            ],
            params: {
                current: 1,
                size: 10,
                distributorType: null,
                status:1
            },
            total: 0
        }
    },
    async mounted() {
        const { data } = await dictionary({ code: "promotion_channels" })
        this.optionArr[0].option = [...data]
        this.getList();
    },
    methods: {
        choose(obj) {
            const { index, ind, val } = obj
            this.optionArr[index].choose = ind
            let temVal = val!=0&&val!=''&&ind ? val : null;
            this.params.distributorType = temVal;
            this.getList();
        },
        handleCurrentChange(val) {
            this.params.current = val;
            this.getList()
        },
        vote(index, type, id) {
            if (!this.$store.state.account||!this.$store.state.sigStr) {
                this.$toast.show(this.$t('beforeChina'));
                // this.tipShow = true;
                // this.TipText = this.$t('beforeChina');
                // setTimeout(() => {
                //     this.tipShow = false
                // }, 2000)
                return;
            }
           
            this.list[index].active = true;
            this.$forceUpdate();
            switch (type) {
                case 1:
                    this.list[index].easyUseNumber++;
                    break;
                case 2:
                    this.list[index].justSoNumber++;
                    break;
                case 3:
                    this.list[index].badReviewNumber++;
                    break;
                default:
                    break;
            }

            evaluation({
                type: index,
                id,
            })
            console.log(index, type, this.list[index]);
        },
        async getList() {
            const { data } = await distributorsList(this.params)
            const { total, records } = data;
            this.total = total;
            console.log(records);
            this.list = records;
            this.list.forEach(item => {
                item.active = false
            })
            console.log(this.list);
            // this.list[index].active = true;


        }
    }
}
</script>

<style lang="scss">
.p-page {
    background: url('../../assets/uni-meta/kol-u.png') no-repeat top;
    background-color: #371381;
    background-size: 1920px 1428px;
    width: 100%;
    // min-height: 1800px;
    padding: 0 236px 75px 240px;
    box-sizing: border-box;
}

.empty {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 40px;
}

.font-30 {
    font-size: 30px;
}

.options {
    padding-top: 60px;
}

.context {
    max-width: 1440px;
    margin: auto;
    padding-top: 150px;

    .list-box {
        position: relative;

        .top-tip {
            position: absolute;
            width: 298px;
            height: 131px;
            // padding-top: 45px;
            padding: 45px 10px 20px;
            text-align: center;
            top: -145px;
            font-size: 16px;
            color: #fff;
            right: 0;
            background: url(../../assets/uni-meta/top-tip.png) no-repeat;
            background-size: contain;

            @media screen and(max-width:920px) {
                width: 410px;
                top: -200px;

            }
        }
    }

    .list-con {
        display: flex;
        height: auto;
        align-items: center;
        margin-bottom: 20px;
        background: #4317A0;

        .left {
            // position: relative;
            display: table-cell;
            width: 320px;
            height: 100%;

            vertical-align: middle;
            background-color: #4317A0;
            text-align: center;

            img {


                // position: absolute;
                width: 177px;


            }
        }

        .center {
            flex: 1;
            height: 100%;
            padding: 45px 56px 49px;
            box-sizing: border-box;
            font-size: 24px;
            background-color: #3D1693;
            color: #fff;

            p {
                margin-bottom: 14px;

                &:nth-child(1) {
                    margin-bottom: 18px;
                    font-size: 28px;
                }
            }

            i {
                font-style: normal;
                color: #6FECE7;
            }

            span {
                color: #FF3A8A;
            }
        }

        .right {
            width: 300px;
            height: 100%;
            background-color: #4317A0;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btns {
                width: 229px;

                p {
                    width: 100%;
                    height: 37px;
                    line-height: 37px;
                    text-align: center;
                    border-radius: 4px;
                    cursor: pointer;

                    &:nth-child(1) {
                        margin-bottom: 9px;
                        color: #D06DC7;
                        border: 1px solid #D06DC7;
                    }

                    &:nth-child(2) {
                        margin-bottom: 9px;
                        color: #C198FF;
                        border: 1px solid #C198FF;
                    }

                    &:nth-child(3) {
                        color: #66B2FF;
                        border: 1px solid #66B2FF;
                    }
                }
            }

            .active {
                .flex {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 15px;
                    padding-right: 10px;
                    box-sizing: border-box;

                    &:nth-child(1) {
                        color: #fff;
                        background-color: #D06DC7;
                    }

                    &:nth-child(2) {
                        color: #fff;
                        background-color: #C198FF;
                    }

                    &:nth-child(3) {
                        color: #fff;
                        background-color: #66B2FF;
                    }
                }
            }
        }
    }
}

@media screen and(max-width:650px) {
    .p-page {
        padding: 0 40px 75px 40px;
    }

    .context {
        .list-con {

            height: auto;
            display: block;

            .left,
            .center,
            .right {
                width: 100%;
                text-align: center;
            }

            margin-bottom: 30px;

            .right {
                .btns {
                    width: 350px;

                    p {
                        height: 60px;
                        line-height: 60px;
                    }
                }

                padding: 20px 0;
                box-sizing: border-box;
            }
        }
    }
}
</style>