<template>
    <Dia :title="$t('Applytobeamember')">
        <div class="dao-inner">
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">NickName</div>
            </div>
            <div class="input">
                <el-input v-model="obj.nickName" :placeholder="$t('PleaseEnter')"></el-input>
            </div>
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Gender</div>
            </div>
            <el-select v-model="obj.gender" :placeholder="$t('Pleaseselect')">
                <el-option key="1" label="male" value="1">
                </el-option>
                <el-option key="0" label="female" value="0">
                </el-option>
            </el-select>
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Telegram ID</div>
            </div>
            <div class="input">
                <el-input v-model="obj.telegram" :placeholder="$t('PleaseEnter')"></el-input>
            </div>
            <!-- 其他常用联系方式 -->
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Other common contact information</div>
            </div>
            <div class="input">
                <el-input v-model="obj.otherContact" :placeholder="$t('PleaseEnter')"></el-input>
            </div>
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Area</div>
            </div>
            <div class="input">
                <el-select v-model="obj.area" :placeholder="$t('Pleaseselect')">
                    <el-option v-for="item in addressArr" :key="item.id" :label="item.dictValue" :value="item.dictKey">
                    </el-option>
                </el-select>
            </div>

            <div class="titles" v-if="obj.area == '0'">
                <div class="must">*</div>
                <div class="tit" v-text="$t('otherCountry')"></div>
            </div>
            <div class="input" v-if="obj.area == '0'">
                <el-input v-model="obj.otherCountry" :placeholder="$t('pleaseEnter')"></el-input>
            </div>
            <!-- 语言 -->
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Language</div>
            </div>
            <div class="input">

                <el-select v-model="obj.language" :placeholder="$t('Pleaseselect')">
                    <el-option key="0" label="Zh" value="0">
                    </el-option>
                    <el-option key="1" label="En" value="1">
                    </el-option>
                </el-select>
            </div>

            <!-- 擅长/感兴趣的合作领域 -->
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Fields that KOL is Good at / Interested in</div>
            </div>
            <div class="input">
                <el-select v-model="obj.interests" :placeholder="$t('Pleaseselect')">
                    <el-option v-for="item in goodArr" :key="item.id" :label="item.dictValue" :value="item.dictKey">
                    </el-option>
                </el-select>
            </div>
            <div class="titles">
                <div class="must">*</div>
                <div class="tit">Platform</div>
            </div>
            <div class="platform">
                <div v-for="(item, index) in platFormArr" :key="index" class="platbox"
                    :style="item.isChecked ? 'border-color:#FF3A8A;color:#FF3A8A' : ''" @click="checkEvent(item)">
                    {{ item.name }}
                </div>
            </div>
            <!-- <div class="titles">
                <div class="must"></div>
                <div class="tit">昵称</div>
            </div>
            <div class="input">
                <el-input v-model="obj.nickname" placeholder="请输入"></el-input>
            </div>
            <div class="titles">
                <div class="must"></div>
                <div class="tit">粉丝数</div>
            </div>
            <div class="input">
                <el-input v-model="obj.fansNum" placeholder="请输入"></el-input>
            </div>
            <div class="titles">
                <div class="must"></div>
                <div class="tit">自己的账号链接</div>
            </div> -->
            <!-- <div class="input">
                <el-input v-model="obj.link" placeholder="请输入"></el-input>
            </div> -->

            <div class="twitterbox pbox" v-show="platFormArr[0].isChecked">
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Twitter nickname</div>
                </div>
                <div class="input">
                    <el-input v-model="twitterObj.nickName" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Twitter followers</div>
                </div>
                <div class="input">
                    <el-input v-model="twitterObj.fansNum" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Twitter account link</div>
                </div>
                <div class="input">
                    <el-input v-model="twitterObj.link" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
            </div>



            <div class="telegrambox pbox" v-show="platFormArr[1].isChecked">
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Telegram nickname</div>
                </div>
                <div class="input">
                    <el-input v-model="telegramObj.nickName" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Telegram followers</div>
                </div>
                <div class="input">
                    <el-input v-model="telegramObj.fansNum" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Telegram account link</div>
                </div>
                <div class="input">
                    <el-input v-model="telegramObj.link" :placeholder="$t('PleaseEnter')"></el-input>
                </div>


            </div>


            <div class="youtubebox pbox" v-show="platFormArr[2].isChecked">

                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Youtube nickname</div>
                </div>
                <div class="input">
                    <el-input v-model="youtubeObj.nickName" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Youtube followers</div>
                </div>
                <div class="input">
                    <el-input v-model="youtubeObj.fansNum" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Youtube account link</div>
                </div>
                <div class="input">
                    <el-input v-model="youtubeObj.link" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
            </div>

            <div class="otherplatbox pbox" v-show="platFormArr[3].isChecked">
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Other platform</div>
                </div>
                <div class="input">
                    <el-input v-model="otherPlatObj.platName" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Other platform nickname</div>
                </div>
                <div class="input">
                    <el-input v-model="otherPlatObj.nickName" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Other platform followers</div>
                </div>
                <div class="input">
                    <el-input v-model="otherPlatObj.fansNum" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Account link</div>
                </div>
                <div class="input">
                    <el-input v-model="otherPlatObj.link" :placeholder="$t('PleaseEnter')"></el-input>
                </div>
            </div>
            <div class="btn" @click="submit">
                Submit
            </div>
        </div>
    </Dia>
</template>
  
<script>
import Dia from '@/components/Dialog'
import { applyKol } from '@/api/kol'
import {updateUser} from '@/utils'
export default {
    components: {
        Dia
    },
    props: {
        title: {
            type: String,
            default: () => { return '请填写' }
        },
        addressArr: {
            type: Array,
            default: () => { return [] }
        }
        ,
        goodArr: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {
            obj: {
                nickName: '',
                gender: '',
                telegram: '',
                otherContact: '',
                area: '',
                language: '',
                interests: '',
                platforms: [],
                otherCountry: '',
                memberType:'kol',
                id:this.$store.state.userInfo.user_id
                // nickname: '',
                // fansNum: '',
                // link: ''
            },
            platFormArr: [{ name: 'Twitter', isChecked: false },
            { name: 'Telegram', isChecked: false },
            { name: 'Youtube', isChecked: false },
            { name: 'Other', isChecked: false },
            ],
            twitterObj: {
                nickName: '',
                fansNum: '',
                link: ''
            },
            telegramObj: {
                nickName: '',
                fansNum: '',
                link: ''
            },
            youtubeObj: {
                nickName: '',
                fansNum: '',
                link: ''
            },
            otherPlatObj: {
                platName: '',
                nickName: '',
                fansNum: '',
                link: ''
            }
        }
    },
    methods: {
        checkEvent(item) {
            item.isChecked = !item.isChecked
        },
        submit() {
            this.obj.platforms = [];
            for (let i = 0; i < this.platFormArr.length; i++) {
                if (this.platFormArr[i].isChecked) {
                    if (this.platFormArr[i].name == 'Twitter') {
                        this.obj.platforms.push({
                            platformName: this.platFormArr[i].name,
                            nickName: this.twitterObj.nickName,
                            followers: this.twitterObj.fansNum,
                            accountUrl: this.twitterObj.link,
                        })
                    }
                    if (this.platFormArr[i].name == 'Telegram') {
                        this.obj.platforms.push({
                            platformName: this.platFormArr[i].name,
                            nickName: this.telegramObj.nickName,
                            followers: this.telegramObj.fansNum,
                            accountUrl: this.telegramObj.link,
                        })
                    }
                    if (this.platFormArr[i].name == 'Youtube') {
                        this.obj.platforms.push({
                            platformName: this.platFormArr[i].name,
                            nickName: this.youtubeObj.nickName,
                            followers: this.youtubeObj.fansNum,
                            accountUrl: this.youtubeObj.link,
                        })
                    }
                    if (this.platFormArr[i].name == 'Other') {
                        this.obj.platforms.push({
                            platformName: this.otherPlatObj.platName,
                            nickName: this.otherPlatObj.nickName,
                            followers: this.otherPlatObj.fansNum,
                            accountUrl: this.otherPlatObj.link,
                        })
                    }
                }
            }
            // console.log(this.obj[ms])
            if(this.obj.area!='0'){
               delete this.obj.otherCountry
            }
            for (let ms in this.obj) {
                if (!this.obj[ms]) {
                    this.$toast.show(this.$t('mustbeInput'))
                    return false
                }
                if (Array.isArray(this.obj[ms])) {
                    if (this.obj[ms].length == 0) {
                        this.$toast.show(this.$t('mustbeInput'))
                        return false
                    }
                    for (let i of this.obj[ms]) {
                        for (let is in i) {
                            if (!i[is]) {
                                this.$toast.show(this.$t('mustbeInput'))
                                return false
                            }
                        }
                    }
                }
            }
            let cloneObj = JSON.parse(JSON.stringify(this.obj))
            delete cloneObj.platforms
            applyKol({ daoMemberEntity: cloneObj, platforms: this.obj.platforms }).then(res => {
                if (res.code == 200) {
                    this.$toast.show(this.$t('quicktodoS'))
                    this.$parent.closeDia()
                    updateUser();
                }
            })
        }
    }
}
</script>
  
<style lang="scss" scoped>
/deep/ .el-input__inner {
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
}

/deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #C198FF;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #C198FF;
}

/deep/ .el-select {
    width: 100%;
}

.must {
    font-size: 30px;
    color: #FF3A8A;
    margin-right: 15px;
    width: 10px;
}

.dao-inner {
    width: 87%;
    margin: 0 auto;
    box-sizing: border-box;

    .titles {
        display: flex;
        align-items: center;
        line-height: 32px;
        margin-bottom: 16px;
        margin-top: 16px;

        .tit {
            font-size: 24px;
            color: #FFFFFF;
        }
    }
}

.platform {
    display: flex;
    justify-content: space-between;

    .platbox {
        width: 127px;
        height: 48px;
        border: 1px solid #C198FF;
        border-radius: 10px;
        color: #8361B5;
        font-size: 18px;
        text-align: center;
        line-height: 48px;
    }
}

.twitterbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #FFE698;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #FFE698;
    }

    /deep/ .el-input__inner:hover {
        border-color: #FFE698;
    }
}



.telegrambox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #98FF9D;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #98FF9D;
    }

    /deep/ .el-input__inner:hover {
        border-color: #98FF9D;
    }
}


.youtubebox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #5792FF;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #5792FF;
    }

    /deep/ .el-input__inner:hover {
        border-color: #5792FF;
    }
}

.otherplatbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #6FECE7;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #6FECE7;
    }

    /deep/ .el-input__inner:hover {
        border-color: #6FECE7;
    }
}

.pbox {
    padding-bottom: 70px;
}

.btn {
    width: 534px;
    height: 88px;
    background: #FF3A8A;
    border-radius: 40px 5px 40px 5px;
    text-align: center;
    line-height: 88px;
    font-size: 36px;
    color: #FFFFFF;
    margin: 51px auto 0 auto;
}
</style>