
import request from '@/plugins/axios'

//获取签名随机数据
export function getSignData (params){
  return request({
    url: '/user/RegisterLogin/getSignData',
    method: 'post',
    params,
  })
}

//表单提交
export function submit (data){
  return request({
    url: '/blade-memberHelp/memberHelp/submit',
    method: 'post',
    data,
  })
}

export function dictionary (params){
  return request({
    url: '/blade-system/dict-biz/dictionary',
    method: 'get',
    params,
  })
}

//分页

export function getList (params){
  return request({
    url: '/blade-cooperationDemand/cooperationDemand/list',
    method: 'get',
    params,
  })
}

//渠道分页

export function distributorsList (params){
  return request({
    url: '/blade-distributors/distributors/list',
    method: 'get',
    params,
  })
}


//需求发布

export function cooperationDemand (data){
  return request({
    url: '/blade-cooperationDemand/cooperationDemand/submit',
    method: 'post',
    data,
  })
}

//评论

export function evaluation (params){
  return request({
    url: '/blade-distributors/distributors/evaluation',
    method: 'get',
    params,
  })
}

//需求发布

export function toSignUp (data){
  return request({
    url: '/toSignUp/toSignUp/submit',
    method: 'post',
    data,
  })
}


//立即报名
export function signUp (data){
  return request({
    url: '/toSignUp/toSignUp/signUp',
    method: 'post',
    data,
  })
}


//获取用户已经报名的需求列表
export function getRequireList (params){
  console.log(params)
  return request({
    url: '/toSignUp/toSignUp/list',
    method: 'get',
    params,
  })
}

//获取签名字符串
export function verify (data){
  return request({
    url: '/toSignUp/toSignUp/metamask/verify',
    method: 'post',
    data,
  })
}

//接口登录
export function login (data){
  return request({
    url: '/toSignUp/toSignUp/metaMask/login',
    method: 'post',
    data,
  })
}

//更新用户信息
export function refreshUser (params){
  return request({
    url: '/blade-daoMember/daoMember/refresh/member',
    method: 'get',
    params,
  })
}


//完善用户信息
export function additionalUser (data){
  return request({
    url: '/toSignUp/toSignUp/additional/member',
    method: 'post',
    data,
  })
}

