import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/pages/index/index.vue'
import publicSquare from '@/pages/public-square/index.vue'
import promotionChannels from '@/pages/promotion-channels/index.vue'
import more from '@/pages/more/index.vue'
import demandDetail from '@/pages/demandDetail/index.vue'
import kolDetail from '@/pages/kolDetail/index.vue'
import store from "@/store"

Vue.use(VueRouter)
const routes = [
  { path: '/',name:'0', component: Index,meta:{keepAlive:true} },
  { path: '/public-square',name:'1', component: publicSquare,meta:{keepAlive:true} },
  { path: '/promotion-channels',name:'2', component: promotionChannels },
  { path: '/more',name:'3', component: more,meta:{keepAlive:true} },
  { path: '/demandDetail', component: demandDetail },
  { path: '/kolDetail', component: kolDetail },
]
const router = new VueRouter({ routes })
router.afterEach(() => {
  document.body.scrollTop = document.documentElement.scrollTop = 0
})
router.beforeEach((to,from,next)=>{
  if(to.name){
    store.commit('SET_INDEX', to.name)
  }
  next();
})
export default router
