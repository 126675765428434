/*
 * @Author: zw
 * @Date: 2021-08-25 16:41:33
 * @LastEditors: zw
 * @LastEditTime: 2021-11-04 11:03:01
 * @Description: 
 */
import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
