<template>
    <div class="option">
        <div class="option_box" v-for="(item,index) in optionArr" :key="index">
            <div class="option_line">
                <div class="title">
                    {{item.title}}
                </div>
                <div class="btns">
                    <div class="btn" v-for="(ite,ind) in item.option" :key="ind"
                        :style="item.choose == ind?' border: 1px solid #FF3A8A;':' border: 1px solid #72F3EC;'" @click="chooseEvent(index,ind,ite.dictValue)">
                        {{ite.dictValue}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>


export default {
    props: {
        optionArr: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {

        };
    },
    computed: {
    },
    filters: {


    },
    methods: {
        chooseEvent(index,ind,val){
            this.$emit('choose',{
                index,ind,val
            })
        }
    },
    created() {

    },
};
</script>
  
<style scoped lang="scss">
.option_box {
    margin-bottom: 58px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
    }

    .btns {
        display: flex;
        flex-wrap: wrap;

        .btn {
            cursor: pointer;
            margin-top: 35px;
            margin-right: 12px;
            padding: 24px 50px 24px 50px;
            border: 1px solid #72F3EC;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}
</style>
  