/*
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-22 16:17:49
 * @Description:
 */
import { getToken } from '@/utils/auth'
import { isMobile } from '@/components/utils'
export default {
  lang: localStorage.getItem('lang') || 'en',
  isMobile,
  //zw code start
  userLevel: '', //用戶等級 （普通 初級 高級 特技）
  userISAlreadyRegisted: false,
  chainId: '', //主網id
  isBscChain: '', //是否是bsc（boolean）
  sigStr:'',
  selectIndex:'0',
  account: '', //錢包地址
  token: getToken(),
  chainInfo:{},
  userInfo: {},
  //是否跳过输入邀请码（如果是，登录时不再跳到注册页面）
  //格式是accountA,accountB 做多用户的跳过记录
  isPassInputInviteCode: localStorage.getItem('isPassInputInviteCode') || false, //不做在前端了 记录数据库 bitkeep本地存不了
  //zw code end
  PFTContractAddress: '',
  isReload: true,
  keepAliveComponents: [], // 需要缓存的组件数组
  whiteUser:false,
  memberType:false //是否完善了信息
}
