import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Store from '../store/index'
import { Notification } from 'element-ui';
import { verify, login } from '@/api/user'
import { updateUser } from '@/utils'
import { i18nText } from "../plugins/i18n";
export async function EthGetWalletProvider() {
    let WalletProvider = null;
    const provide = new WalletConnectProvider({
        chainId: 1,
        rpc: {
            1: "https://api.infura.io/v1/jsonpc/mainnet"
        }
    });
    WalletProvider = new ethers.providers.Web3Provider(provide)
    subscribe(provide)
    return {
        WalletProvider,
        provide
    };
}

export async function BscGetWalletProvider() {
    let WalletProvider = null;
    const provide = new WalletConnectProvider({
        chainId: 56,
        rpc: {
            56: "https://bsc-dataseed1.defibit.io/"
        }
    });
    WalletProvider = new ethers.providers.Web3Provider(provide)
    subscribe(provide)
    return {
        WalletProvider,
        provide
    };
}

export async function WalletConnet(chainId) {
    Store.state.chainId = chainId;
    const { provide, WalletProvider } = chainId == 1 ? await EthGetWalletProvider() : await BscGetWalletProvider();
    await provide.enable();
    if (provide.chainId !== chainId) {
        Notification({
            title: i18nText('tips'),
            message: i18nText('okLian'),
            duration: 3000
        });
        await provide.disconnect();
        resetApp();
    } else {
        Store.commit('SET_ACCOUNT', provide.accounts[0])
        const { data } = await verify({ address: provide.accounts[0] })
        const signer = WalletProvider.getSigner()
        signer.signMessage(data).then(async (res) => {
            let etherString =''
            if( provide.chainId == 56){
                const balance = await WalletProvider.getBalance(provide.accounts[0])
                etherString = ethers.utils.formatEther(balance)
            }
            let Info = {
                walletAddress: provide.accounts[0],
                chain: provide.chainId == 1 ? 'Ethereum' : 'Binnance smart chain',
                currency: provide.chainId == 1 ? 'ETH' : 'BNB',
                balanceOfCurrency:etherString
            }
            Store.commit('SET_chainInfo', Info);
            const response = await login({
                message: data,
                address: provide.accounts[0],
                signature: res,
                ...Info
            })
            if (response.code == 200) {
                Store.commit('SET_SIG_STR', res);
                const flag = response.data.WhiteList == 'yes' ? true : false
                Store.commit('SET_White_User', flag)
                Store.commit('SET_USER_INFO', response.data)
                updateUser();

            }
        }).catch(() => {

        })
    }

}

export async function disconnect() {
    const { provide } = Store.state.chainId == 1 ? await EthGetWalletProvider() : await BscGetWalletProvider();
    await provide.disconnect()
    resetApp()
}

// async function getSignatureDataBSC(data) {

// let signData = await getSignData()
// let signStr = `${signData.data.timestamp}${signData.data.id}${signData.data.random}`

// const signature = await getSignature(signStr)
// data.signature = signature

// data.signStr=signStr
// data.random = signData.data.random
// data.chain = 'BSC'
// data.timestamp = parseInt(new Date().getTime().toString().substr(0, 10))
// return data
//   }


function subscribe(provider) {
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
        console.log(accounts);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        if (chainId !== 1 && chainId !== 56) {
            disconnect();
            Notification({
                title: '提示',
                message: '请选择正确的链后再连接',
                duration: 3000
            });
        } else {
            Store.state.chainId = chainId;
        }
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
        localStorage.walletconnect = ''
        Store.dispatch("LogOut");
        resetApp();
        console.log(code, reason);
    });
}

function resetApp() {
    localStorage.selectIndex = 0;
    Store.commit('SET_ACCOUNT', '');
    Store.commit('SET_SIG_STR', '');
    localStorage.walletconnect = ''
    localStorage.walletconnect = ''
}
