<template>
  <div class="page-home">
    <div class="swiper-box" >
      <a href="https://forms.gle/wes6atYvTKxF7zEfA" target="_blank">
      <img src="https://kol-unimeta.oss-cn-hongkong.aliyuncs.com/logo/icon/unimeta_banner.jpeg" alt="">
    </a>
    </div>
    <div class="kol-box">
      <div class="under-banner">
        <div class="opts">
          <div class="search">
            <el-input :placeholder="$t('TryRecruit')" prefix-icon="el-icon-search"
              v-model="searchObj.selectFollowersCeiling" @blur="selectInput" class="jelly" autofocus>
            </el-input>
          </div>
          <OptionBox :optionArr="optionArr" @choose="choose"></OptionBox>
          <div class="slider">
            <span class="demonstration" v-text="$t('fans')"></span>
            <div class="sli-box" style="height: 38px;">
              <el-slider v-model="value1" :format-tooltip="formatTooltip" :min="0" :max="100000000"
                @change="sliderChange" range :marks="marks" v-if="changeShow">
              </el-slider>
            </div>
            <div class="demonstration_num">
              <span v-text="$t('Unlimited')"></span>
              <span v-text="$t('80000K')"></span>
            </div>
            <div class="innerbox-i">
              <el-input type="number" v-model="searchObj.followersFloor" placeholder="请输入最小粉丝数" @blur="mapFormMin">
              </el-input>
              <div class="zhi"> -</div>
              <el-input type="number" v-model="searchObj.followersCeiling" placeholder="请输入最大粉丝数" @blur="mapFormMax">
              </el-input>
            </div>
          </div>
        </div>

        <div class="cards-box">
          <div class="card" v-for="(item, index) in kolArr" :key="index">
            <div class="user_info">
              <div class="head">
                <img :src="item.photo">
              </div>
              <div class="info">
                <div class="one">
                  <div class="name">{{ item.nickName }}</div>
                  <div class="add">
                    <img src="../../assets/uni-meta/woman_icon.png" v-if="item.gender == 'woman'" class="icon">
                    <img src="../../assets/uni-meta/man_icon.png" v-else class="icon">
                    <img src="../../assets/uni-meta/auth.png" alt="" class="auth"
                      v-if="item.author != 'unauthorized' && item.author != '未认证'">
                    <span class="language">{{ item.language }}</span>
                  </div>
                  <div class="two">
                    {{ $t('from') }} {{ item.area }}
                  </div>
                </div>

                <div style="display: flex; flex-wrap: wrap; min-height: 60px; justify-content: space-between;">
                  <a :href="item.platformLink" target="_blank">
                    <div class="three">
                      {{ item.mediaPlatform }}
                    </div>
                  </a>
                  <div v-for="(ite, inde) in item.platforms" :key="inde">
                    <a :href="ite.accountUrl" target="_blank">
                      <div class="three">
                        {{ ite.platformName }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="fans">
              <span class="fans1" v-text="$t('fans')"></span>
              <span class="fans2">{{ item.followers }}</span>
            </div>
            <!-- <div class="fans_num">
              <span class="fans1" v-text="$t('AverageCoverageUser')"></span>
              <span class="fans2">{{ item.average }}</span>
            </div> -->
            <div class="fans_price">
              <span class="price1" v-text="$t('StartingCcooperation')"></span>
              <img src="../../assets/uni-meta/offer.png" alt="" v-if="!item.showDe">
              <span style="color: #C198FF;" v-else> {{ item.minNum ? item.minNum : $t('ChatI') }}</span>
            </div>
            <div class="search_btn" @click="showDetail(item)"><span v-text="$t('ViewQuotation')"></span></div>
            <div class="sc">
              {{ $t('GoodCooperation') }} {{ item.mediaPlatform }}
            </div>
            <div class="detail jelly" v-text="$t('ViewDetails')" @click="jumpDetail(item.id)">

            </div>
          </div>
          <div class="w-full text-center">
            <pages-vue :total="total" :size="12" @handleCurrentChange="handleCurrentChange"></pages-vue>
          </div>

        </div>
        <div class="join-member">
          <div class="title_msg">
            <div></div>
            <span v-text="$t('ApplyUniMetaDao')"></span>
          </div>
          <div class="join_msg">
            {{ $t('YouApply') }}
          </div>
          <div class="able_box">
            <div class="able jelly" v-for="(item, index) in kolMember" :key="index">
              <div class="line1"></div>
              <div><img :src="item.img"></div>
              <div class="l_box">
                <div class="line2"></div>
              </div>
              <div class="msg">
                {{ item.msg }}
              </div>
            </div>
          </div>
          <div class="apply jelly" @click="applyEvent" v-text="$t('liApply')">
          </div>
        </div>
      </div>
    </div>
    <Apply v-if="applyDia" :addressArr="otherCon" :goodArr="optionArr[0].option.slice(1)"></Apply>

    <PublishNow v-if="PublishNowVisible" :isWhitelistUsers="$store.state.whiteUser">
    </PublishNow>
  </div>
</template>

<script>
import OptionBox from '@/components/optionbox'
// import Woman from '@/assets/uni-meta/woman.jpg'
// import Man from '@/assets/uni-meta/man.jpg'
import Add from '@/assets/uni-meta/add.png'
import Opportunity from '@/assets/uni-meta/opportunity.png'
import Giveaway from '@/assets/uni-meta/giveaway.png'
import Study from '@/assets/uni-meta/study.png'
import Apply from '@/components/applyDao'
import pagesVue from "@/components/pages.vue"
import { dictionary, kolList } from '@/api/kol'
import PublishNow from '@/components/PublishNow'
import {updateUser} from '@/utils'
export default {
  components: {
    OptionBox,
    Apply,
    pagesVue,
    PublishNow
  },
  data() {
    return {
      searchObj: {
        current: 1,
        size: 12,
        followersFloor: 0,
        followersCeiling: 100000000
      },
      total: 0,
      changeShow: true,
      inputMin: '',
      inputMax: '',
      optionArr: [
      ],
      value1: [0, 100000000],
      marks: {
        20000000: '10 thousand',
        40000000: '100 thousand',
        60000000: '1 million',
        80000000: '10 million',

        // 50: {
        //   style: {
        //     color: '#1989FA'
        //   },
        //   label: this.$createElement('strong', '50%')
        // }
      },
      input2: '',
      kolArr: [
      ],
      kolMember: [
        {
          img: Add,
          msg: this.$t('ReceivedHundreds')
        },
        {
          img: Opportunity,
          msg: this.$t('ConnectWith')
        },
        {
          img: Giveaway,
          msg: this.$t('IrregularlyObtain')
        },
        {
          img: Study,
          msg: this.$t('PriorityTo')
        },
      ],
      applyDia: false,
      PublishNowVisible: false,
      otherCon: []
    };
  },
  filters: {
  },
  methods: {
    showDetail(i) {
      // 直接展示价格 先注掉判断逻辑
      // if (!this.$store.state.account || !this.$store.state.sigStr) {
      //   this.$toast.show(this.$t('beforeChina'))
      //   return
      // } else if (!this.$store.state.whiteUser) {
      //   if (this.$store.state.memberType) {
      //     this.$toast.show(this.$t('quicktodoS'))
      //     return
      //   }
      //     this.$toast.show(this.$t('Youarenot'))
      //     setTimeout(() => {
      //       this.PublishNowVisible = true;
      //     }, 10000)
      //     return
      //   }
      this.$set(i, "showDe", true);
      this.$forceUpdate();
    },
    jumpDetail(id) {
      this.$router.push('/kolDetail?id=' + id);
    },
    applyEvent() {
      if (!this.$store.state.account || !this.$store.state.sigStr) {
        this.$toast.show(this.$t('beforeChina'))
        return
      } else if (this.$store.state.whiteUser) {
        this.$toast.show(this.$t('youismember'))
        return
      } else if (!this.$store.state.whiteUser) {
        if (this.$store.state.memberType) {
          this.$toast.show(this.$t('quicktodoS'))
          return
        }
        this.applyDia = true;
      }
    },
    closeDia() {
      this.applyDia = false;
      this.PublishNowVisible = false;
    },
    choose(obj) {
      const { index, ind } = obj
      this.optionArr[index].choose = ind
      let i0 = this.optionArr[0].choose
      let i1 = this.optionArr[1].choose
      let i2 = this.optionArr[2].choose
      this.searchObj.interests = this.optionArr[0].option[i0].dictKey
      this.searchObj.mediaPlatform = this.optionArr[1].option[i1].dictKey
      this.searchObj.area = this.optionArr[2].option[i2].dictKey
      if (i0 == 0) {
        delete this.searchObj.interests
      }
      if (i1 == 0) {
        delete this.searchObj.mediaPlatform
      }
      if (i2 == 0) {
        delete this.searchObj.area
      }
      this.searchObj.current = 1
      this.getKolList();
    },
    mapFormMin() {
      this.changeShow = false
      if (Number(this.searchObj.followersFloor) > -1 && Number(this.searchObj.followersFloor) < 10000) {
        this.value1[0] = Number(this.searchObj.followersFloor) * 2000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersFloor) == 10000) {
        this.value1[0] = 20000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersFloor) > 10000 && Number(this.searchObj.followersFloor) < 100000) {
        this.value1[0] = ((Number(this.searchObj.followersFloor) / 10000) - 1) * (20000000 / 9) + 20000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersFloor) == 100000) {
        this.value1[0] = 40000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersFloor) > 100000 && Number(this.searchObj.followersFloor) < 1000000) {
        this.value1[0] = ((Number(this.searchObj.followersFloor) / 100000) - 1) * (20000000 / 9) + 40000000
        setTimeout(() => {
          this.changeShow = true
        })
        console.log(this.value1[0])
      } else if (Number(this.searchObj.followersFloor) == 1000000) {
        this.value1[0] = 60000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersFloor) > 1000000 && Number(this.searchObj.followersFloor) < 10000000) {
        this.value1[0] = ((Number(this.searchObj.followersFloor) / 1000000) - 1) * (20000000 / 9) + 60000000
        setTimeout(() => {
          this.changeShow = true
        })
        console.log(this.value1[0])
      } else if (Number(this.searchObj.followersFloor) == 10000000) {
        this.value1[0] = 80000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersFloor) > 10000000 && Number(this.searchObj.followersFloor) < 100000000) {
        this.value1[0] = ((Number(this.searchObj.followersFloor) / 10000000) - 1) * (20000000 / 9) + 80000000
        setTimeout(() => {
          this.changeShow = true
        })
        console.log(this.value1[0])
      } else {
        this.value1[0] = 100000000
        setTimeout(() => {
          this.changeShow = true
        })
      }
      this.selectInput();
    },


    mapFormMax() {
      this.changeShow = false
      if (Number(this.searchObj.followersCeiling) > -1 && Number(this.searchObj.followersCeiling) < 10000) {
        this.value1[1] = Number(this.searchObj.followersCeiling) * 2000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) == 10000) {
        this.value1[1] = 20000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) > 10000 && Number(this.searchObj.followersCeiling) < 100000) {
        this.value1[1] = ((Number(this.searchObj.followersCeiling) / 10000) - 1) * (20000000 / 9) + 20000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) == 100000) {
        this.value1[1] = 40000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) > 100000 && Number(this.searchObj.followersCeiling) < 1000000) {
        this.value1[1] = ((Number(this.searchObj.followersCeiling) / 100000) - 1) * (20000000 / 9) + 40000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) == 1000000) {
        this.value1[1] = 60000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) > 1000000 && Number(this.searchObj.followersCeiling) < 10000000) {
        this.value1[1] = ((Number(this.searchObj.followersCeiling) / 1000000) - 1) * (20000000 / 9) + 60000000
        setTimeout(() => {
          this.changeShow = true
        })
        console.log(this.value1[1])
      } else if (Number(this.searchObj.followersCeiling) == 10000000) {
        this.value1[1] = 80000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else if (Number(this.searchObj.followersCeiling) > 10000000 && Number(this.searchObj.followersCeiling) < 100000000) {
        this.value1[1] = ((Number(this.searchObj.followersCeiling) / 10000000) - 1) * (20000000 / 9) + 80000000
        setTimeout(() => {
          this.changeShow = true
        })
      } else {
        this.value1[1] = 100000000
        setTimeout(() => {
          this.changeShow = true
        })
      }
      this.selectInput();
    },
    formatTooltip(val) {
      if (val > -1 && val < 20000000) {
        // (val / 10000).toFixed(2) + '万'
        return (val / 2000).toFixed(0)
      } else if (val == 20000000) {
        return '10 thousand'
      } else if (val > 20000000 && val < 40000000) {
        return this.$store.state.lang == "en" ? ((((val % 20000000) / 20000000) * 9 + 1) * 10).toFixed(2) + 'Thousand' : (((val % 20000000) / 20000000) * 9 + 1).toFixed(2) + '万'
      } else if (val == 40000000) {
        return '100 thousand'
      } else if (val > 40000000 && val < 60000000) {
        return this.$store.state.lang == "en" ? ((((val % 20000000) / 20000000) * 90 + 10) / 100).toFixed(2) + 'million' : (((val % 20000000) / 20000000) * 90 + 10).toFixed(2) + '万'
      } else if (val == 60000000) {
        return '1 million'
      } else if (val > 60000000 && val < 80000000) {
        return this.$store.state.lang == "en" ? ((((val % 20000000) / 20000000) * 900 + 100) / 100).toFixed(2) + 'million' : (((val % 20000000) / 20000000) * 900 + 100).toFixed(2) + '万'
      } else if (val == 80000000) {
        return '10 million'
      } else if (val > 80000000 && val < 100000000) {
        return this.$store.state.lang == "en" ? ((((val % 20000000) / 20000000) * 9000 + 1000) / 100).toFixed(2) + 'million' : (((val % 20000000) / 20000000) * 9000 + 1000).toFixed(2) + '万'
      } else {
        return '100 million'
      }

    },
    sliderChange(arr) {
      this.searchObj.followersFloor = this.mapNumber(arr[0]);
      this.searchObj.followersCeiling = this.mapNumber(arr[1]);
      this.selectInput();
    },
    getKolList() {
      kolList(this.searchObj).then(res => {
        this.kolArr = res.data.records
        for (let i of this.kolArr) {
          i.showDe = false;
          let priceArr = [Number(i.originalTweetPostOffer),
          Number(i.originalVideoPostOffer),
          Number(i.spaceHostingOffer),
          Number(i.tweetRetweet),
          Number(i.videoImplantationOffer),
          Number(i.quoteTweet),
          Number(i.pinToPage),
          Number(i.otherOffers),
          Number(i.ama),
          Number(i.giveawayEvent),
          Number(i.infographicTweet)
          ]
          if (i.services.length > 0) {
            for (let t of i.services) {
              priceArr.push(Number(t.amount))
            }
          }
          priceArr = priceArr.filter(el => el > 0)
          if (priceArr.length > 0) {
            i.minNum = Math.min.apply(null, priceArr);
          } else {
            i.minNum = false;
          }
        }
        this.total = res.data.total
      })
    },
    async getOptionArr() {
      Promise.all([dictionary({ code: "interests_key" }), dictionary({ code: "platform_channel" }), dictionary({ code: "countries_region_cn" })]).then(res => {
        this.optionArr.push({
          title: "Fields that KOL is Good at / Interested in",
          choose: 0,
          option: res[0].data.sort(function (a, b) {
            return a.sort - b.sort;
          })
        })
        this.optionArr.push({
          title: "Social media",
          choose: 0,
          option: res[1].data.sort(function (a, b) {
            return a.sort - b.sort;
          })
        })
        this.optionArr.push({
          title: "Countries / regions",
          choose: 0,
          option: res[2].data.sort(function (a, b) {
            return a.sort - b.sort;
          })
        })
      })
      const { data } = await dictionary({ code: "form_contry" })
      this.otherCon = data

    },
    handleCurrentChange(n) {
      this.searchObj.current = n
      this.getKolList();
    },
    selectInput() {
      this.searchObj.current = 1
      this.getKolList();
    },
    mapNumber(val) {
      if (val > -1 && val < 20000000) {
        // (val / 10000).toFixed(2) + '万'
        return (val / 2000).toFixed(0)
      } else if (val == 20000000) {
        return 10000
      } else if (val > 20000000 && val < 40000000) {
        return ((((val % 20000000) / 20000000) * 9 + 1) * 10000).toFixed(0)
      } else if (val == 40000000) {
        return 100000
      } else if (val > 40000000 && val < 60000000) {
        return ((((val % 20000000) / 20000000) * 90 + 10) * 10000).toFixed(0)
      } else if (val == 60000000) {
        return 1000000
      } else if (val > 60000000 && val < 80000000) {
        return ((((val % 20000000) / 20000000) * 900 + 100) * 10000).toFixed(0)
      } else if (val == 80000000) {
        return 10000000
      } else if (val > 80000000 && val < 100000000) {
        return ((((val % 20000000) / 20000000) * 9000 + 1000) * 10000).toFixed(0)
      } else {
        return 100000000
      }
    }
  },
  created() {
    this.getOptionArr()
    this.getKolList();
    // updateUser();
  },
  computed: {
  },
  activated(){
    updateUser();
  }
};
</script>

<style scoped lang="scss">
.innerbox-i {
  display: flex;
  color: #fff;
  align-items: center;
  margin-top: 50px;

  .zhi {
    margin-left: 30px;
    margin-right: 30px;
  }

  /deep/ .el-input__inner {
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
  }

  /deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
  }

  /deep/ .el-input__inner:hover {
    border-color: #C198FF;
  }
}

/deep/ .el-slider__stop {
  background-color: #FB568C !important;
}

/deep/ .el-slider__marks-text {
  color: #909399;
  font-size: 12px;
}

.swiper-box {
  width: 100%;

  img {
    width: 100%;
    display: block;
    /* 设置图片为块级元素 */

  }
}

.kol-box {
  background: #371381;
  width: 100%;

  .under-banner {
    background: url('../../assets/uni-meta/kol-u.png') no-repeat top;
    background-size: 1920px 1428px;
    width: 100%;
    min-height: 1800px;

    .opts {
      padding-left: 242px;

      .search {
        padding-top: 42px;
        display: flex;
        flex-direction: row-reverse;
        box-sizing: border-box;
        padding-right: 35px;
        margin-bottom: 7px;

        .el-input {
          width: 40%;
          border-radius: 39px;
        }

        /deep/ .el-input__inner {
          border-radius: 39px;
          background: none;
        }
      }
    }

    .slider {
      width: 70vw;

      .demonstration {
        font-size: 24px;
        color: #FFFFFF;
        font-weight: bold;
      }

      .demonstration_num {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        color: #FFFFFF;
      }
    }
  }
}

.cards-box {
  margin-left: 243px;
  width: 1440px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 69px;
  justify-content: space-between;
  margin-bottom: 50px;

  .card {
    width: 460px;
    min-height: 634px;
    background: url(../../assets/uni-meta/card-border.png) no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 95px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 32px;
    padding-bottom: 60px;

    .user_info {
      display: flex;
      justify-content: space-evenly !important;
      min-height: 121px;

      .head {
        background: url(../../assets/uni-meta/user-head.png) no-repeat center;
        width: 154px;
        height: 121px;
        background-size: 100% 100%;
        flex-shrink: 0;

        img {
          width: 104px;
          height: 104px;
          border-radius: 50%;
          margin-top: 0.5rem;
          margin-left: 0.5rem;
          display: block;
        }
      }

      .info {
        // flex: 1;
        margin-left: 5px;
        min-height: 121px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;

        .one {
          // display: flex;
          // justify-content: space-between;
          // align-items: center;

          .name {
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            // width: 150px;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }

          .add {
            margin-top: 5px;
            margin-bottom: 5px;
            display: flex;

          }

          .language {
            font-size: 18px;
            color: #FFFFFF;
            margin-left: 10px;
          }

          .icon {
            width: 24px;
            height: 24px;
          }

          .auth {
            width: 24px;
            height: 26px;
          }
        }

        .two {
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 8px;
        }

        .three {
          width: 60px;
          padding-right: 15px;
          padding-left: 15px;
          height: 30px;
          border: 1px solid #C198FF;
          border-radius: 10px;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #C198FF;
          margin-bottom: 10px;

          img {
            width: 16px;
            height: 13px;
            margin-left: 11px;
            margin-right: 2px;
          }
        }

      }

    }

    .fans {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #F6F8FC;
      margin-top: 25px;

      .fans2 {
        font-size: 24px;
        color: #C198FF;
      }
    }

    .fans_num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #F6F8FC;
      margin-top: 21px;

      .fans2 {
        font-size: 24px;
        color: #C198FF;
      }

    }

    .fans_price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #F6F8FC;
      margin-top: 21px;

      img {
        width: 166px;
        height: 49px;
      }
    }

    .search_btn {
      font-size: 20px;
      color: #6FECE7;
      margin-top: 21px;
      display: flex;
      flex-direction: row-reverse;
      cursor: pointer;

      span {
        padding: 14px 40px;
        border: 2px solid #6FECE7;
        border-radius: 15px;
        margin-top: 21px;
      }
    }

    .sc {
      font-size: 18px;
      color: #F6F8FC;
      margin-top: 24px;
    }

    .detail {
      width: 278px;
      height: 58px;
      background: #FF3A8A;
      border-radius: 30px 5px 30px 5px;
      margin: 33px auto 0 auto;
      text-align: center;
      line-height: 58px;
      font-size: 20px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.join-member {
  width: 100%;
  min-height: 954px;
  background: linear-gradient(0deg, rgba(251, 184, 160, .1), #5C1F9E, #4217B4, #5F16A3);
  box-sizing: border-box;
  padding-top: 69px;
  padding-bottom: 88px;

  .title_msg {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
    align-items: center;

    div {
      width: 6px;
      height: 32px;
      background: #FF3A8A;
      margin-right: 15px;
    }
  }

  .join_msg {
    text-align: center;
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 30px;
  }
}

.able_box {
  width: 1439px;
  display: flex;
  margin: 85px auto 0 auto;
  justify-content: space-between;

  .able {
    .line1 {
      margin-left: 11px;
      width: 87px;
      height: 3px;
      background: #FF3A8A;
      margin-bottom: 7px;
    }

    img {
      width: 332px;
      height: 311px;
    }

    .l_box {
      display: flex;
      flex-direction: row-reverse;
      padding-right: 11px;
      box-sizing: border-box;
      margin-top: 3px;

      .line2 {
        width: 87px;
        height: 3px;
        background: #6FECE7;

      }

    }

    .msg {
      max-width: 330px;
      font-size: 20px;
      color: #FFFFFF;
      text-align: center;
      margin-top: 49px;
    }

  }
}

.apply {
  width: 534px;
  height: 88px;
  background: #FF3A8A;
  border-radius: 40px 5px 40px 5px;
  margin: 87px auto 0 auto;
  text-align: center;
  line-height: 88px;
  font-size: 36px;
  color: #FFFFFF;
  cursor: pointer;
}


/deep/ .el-slider__button {
  border: none;
  width: 20px;
  height: 20px;
  background: #FB568C;
}

/deep/ .el-slider__bar {
  background: #FB568C;
}

// /deep/ .el-tooltip__popper.is-dark {
//     background: red !important;
//     color: #FFF;
// }
@media screen and (max-width: 768px) {
  .kol-box .under-banner .opts {
    padding-left: 2vw;
  }

  .slider {
    margin: 0 auto;
  }

  .cards-box {
    // margin-left: 0;
    width: 95vw;
    display: flex;
    flex-wrap: wrap;
    margin-top: 69px;
    justify-content: space-evenly;
    margin: 69px auto 50px 0;
  }

  .able_box {
    width: 95vw;
    display: flex;
    flex-wrap: wrap;
    margin: 5.3125rem auto 0 auto;
    justify-content: space-evenly;

    .able {
      margin-top: 30px;
    }

  }

  .three {
    width: 200px !important;
  }

  .demonstration_num {
    margin-top: 40px;
  }
}
/deep/ .el-input__inner {
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
  }

  /deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
  }

  /deep/ .el-input__inner:hover {
    border-color: #C198FF;
  }
</style>
