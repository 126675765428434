/*
 * @Author: zw
 * @Date: 2021-08-25 15:36:21
 * @LastEditors: zw
 * @LastEditTime: 2021-11-23 14:05:47
 * @Description: 
 */

import axios from 'axios'
import { Toast } from 'vant'
// import _this from '..main.js'
import { getToken } from '../utils/auth'
import store from '../store'
// import qs from 'qs'
// import { i18nText } from "../plugins/i18n";
// import { router } from '../router/index'

// 创建axios实例

const service = axios.create({
    // baseURL: '',
    baseURL: '/api',
    timeout: 50000, // 请求超时时间
})

let ToastInst = null
// request拦截器
service.interceptors.request.use(
    config => {
       
        config.headers.Token = getToken()
        // if (config.showIndicator) {
            
        //注意这个加载是vant！！！！（懒得自己弄了）
        // ToastInst = Toast.loading({
        //     icon:require('@/assets/loading.gif'),
        //     className:"loading-lay",
        //     message: i18nText('loading'),
        //     // message: i18nText('tips.loading'),
        //     // forbidClick: true,
        //     // duration:0
        // })
        // }
        // if(config.url==='/service/Upload/upload'){
        //     // config.headers['Content-Type']= "multipart/form-data"
        //     // config.headers.Content-Type= null
        //     // config.headers['Content-Type']= "multipart/form-data"
        //     config.headers = {
        //         'Content-Type': 'multipart/form-data'
        //       }
        //       debugger
        // }
        config.method === 'post'
                ? config.headers['content-type']= 'application/json;charset=utf-8' :''
               
        
        config.headers['Authorization']='Basic c2FiZXI6c2FiZXJfc2VjcmV0';
        config.headers['Blade-Auth']=`bearer ${store.state.userInfo.access_token}`;
        const langMap={
            zh:'cn',
            en:'en'
          }
        let lang=localStorage.getItem('lang')||'en'
        config.headers['Accept-Language'] = langMap[lang]
        config.data=JSON.stringify(config.data)
        return config
    },
    error => {
        // Do something with request error
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        /**
         * code为非20000是抛错 可结合自己业务进行修改
         */
        if (ToastInst) {
            ToastInst.clear()
        }

        const res = response.data
        if (res.code === 0 || res.code == 200) {
            return response.data
        } else if (res.code === 4) {
            ToastInst = Toast.fail({

                message: res.msg,
                duration: 3 * 1000,
            })
            return response.data
        } else if (res.code === 3) {
            ToastInst = Toast.fail({
                message: res.msg,
                duration: 3 * 1000,
            })
            // _this.$store.commit('SET_TOKEN', '')
            // _this.$router.replace('/')
            // router.push('/')
            
            // window.location.href = res.data.url
            return Promise.reject()
        } else if (res.code === 17) {
            // ToastInst = Toast.fail({
            //     message: res.msg,
            //     duration: 3 * 1000,
            // })
            // _this.$store.commit('SET_TOKEN', '')
            // _this.$router.replace('/')
            // router.push('/')
            
            // window.location.href = res.data.url
            console.log('接口报错：用户没没注册')
            return Promise.reject()
        } else {
            ToastInst = Toast.fail({
                message: res.msg,
                duration: 3 * 1000,
            })
            return Promise.reject(res)
        }
    },
    error => {
        console.log('报错了 ==> ',error)
        // ToastInst = Toast.fail({
        //     message: error,
        //     duration: 3 * 1000,
        // })
        return Promise.reject(error)
    }
)

export default service
