<template>
    <div class="dialog-mask">
        <img src="@/assets/uni-meta/closeDia.png" class="close"  @click="closeDia">
        <div class="card" @click.stop>
            <img src="@/assets/uni-meta/closeDia.png" class="close"  @click="closeDia">
            <div class="title">{{title}}</div>
            <div class="inner">
                <div class="box">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        title: {
            type: String,
            default: () => { return '请填写' }
        },
        top: {
            type: Number,
            default: () => { return 16 }
        },

    },
    data() {
        return {
            
        }
    },
    methods: {
        closeDia(){
            // this.$emit('')
            // this.$root.closeDia();
            this.$parent.$parent.closeDia();
        }
    },
    mounted() {
        this.stopScroll()
    },
    beforeDestroy() {
        this.canScroll()
    }

}
</script>
  
<style lang="scss" scoped>
.close{
    width: 80px;
    height: 80px;
    position: absolute;
    right: -80px;
    top: -20px;
}
.dialog-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 997;
    background-color: rgba($color: #000000, $alpha: 0.7);

    .card {
        width: 770px;
        height: 1200px;
        background: url(../../assets/uni-meta/dailog_bg.png) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        transform: translate(-50%, -50%) scale(0.7);
        top: 50%;
        left: 50%;
        z-index: 998;

        .title {
            font-size: 30px;
            color: #FFFFFF;
            text-align: center;
            padding-top: 21px;
        }

        .inner {
            max-height: 1115px;
            width: 670px;
            overflow-y: scroll;
            margin: 22px auto 0 auto;
            .box {
                padding-bottom: 40px;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
    // background-color: #00C6FF;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #00C6FF;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);

}
</style>