/*
 * @Author: zw
 * @Date: 2021-11-08 11:23:43
 * @LastEditors: zw
 * @LastEditTime: 2021-11-22 16:17:11
 * @Description: 
 */

export default {
    lang: state => {
        // if (state.lang === 'zh') return '中文';
        return state.lang;
    },
    goodsList: state => state.goodsList,
    userISAlreadyRegisted: state => state.userISAlreadyRegisted,
    nftList: state => state.nftList,
    isMobile: state => state.isMobile,
    account: state => state.account,
    userInfo: state => state.userInfo,
    userLevel: state => state.userLevel,
    // userLevel: () => 1,
    isPassInputInviteCode:(state)=>{
        //A用户跳过，但是b用户还是要显示出来
        let s=localStorage.getItem('isPassInputInviteCode')
        return s ? s.split(',').find((account)=>account===state.account)!=null :false  
    },
    PFTContractAddress:state => state.PFTContractAddress,
    keepAliveComponents:state => state.keepAliveComponents,
}
