<template>
    <div class="box">
        <div class="box flex">
            <div class="left">
                <!-- <img src="@Img/customer.png" alt="" class="logo"> -->
                <ul class="content">
                    <img src="@Img/logo.png" alt="" class="logo" />
                    <li class="connet">{{ $t('Contact-Us') }}:</li>
                    <li><a href="https://discord.gg/GDyhqZe3KX" target="_blank">Discord ID: unimeta#0656</a> </li>
                    <li>
                        <a href="https://twitter.com/Unimeta_Dao" target="_blank">
                            <img src="../../assets/uni-meta/foot-1.png" alt="" class="foot-1"> Twitter：@ unimeta_com
                        </a>
                    </li>
                    <li class="flex al-cen">
                        <div class="links">{{ $t('link') }}:</div>
                        <div class="link">
                            <a href="https://twitter.com/unimeta_com/"
                                target="_blank">https://twitter.com/unimeta_com/</a>
                        </div>
                    </li>
                    <li>
                        <a href="https://t.me/unimeta_announcement" target="_blank">
                            Telegram ID：Unimeta <img src="../../assets/uni-meta/foot-2.png" alt="" class="foot-2" />
                        </a>
                    </li>
                    <li>
                        <a href="https://debox.pro/dao/JXe7kn6T" target="_blank">
                            <img src="../../assets/uni-meta/De.png" alt="" class="foot-1"> Debox: https://debox.pro/dao/JXe7kn6T
                        </a>
                    </li>
                </ul>

            </div>

            <div class="right">
                <div class="connet">{{ $t('LeaveQuickly') }}</div>
                <ul class="info">
                    <li class="flex">
                        <div class="titles">
                            <div class="must">*</div>
                            <span v-text="$t('name')"></span>
                        </div>
                        <input type="text" v-model="ruleForm.name" :placeholder="$t('pleaseEnter')">
                    </li>
                    <li class="flex">
                        <div class="titles">
                            <div class="must">*</div>
                            <span v-text="$t('Country/Region')"></span>
                        </div>
                        <div class="w-full text-r">
                            <div class="input">
                                <el-select v-model="ruleForm.countryRegion" :placeholder="$t('pleaseSele')">
                                    <el-option v-for="item in addressArr" :key="item.id" :label="item.dictValue"
                                        :value="item.dictKey">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                        <div class="titles mt10" v-if="ruleForm.countryRegion=='0'"> 
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('otherCountry')"></div>
                        </div>
                        <div class="input mt10" v-if="ruleForm.countryRegion=='0'">
                            <el-input v-model="ruleForm.otherCountry" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>

                        <!-- <input type="text" v-model="ruleForm.city" placeholder="请输入"> -->
                    </li>
                    <li class="flex">
                        <div class="titles">
                            <div class="must">*</div>
                            <span v-text="$t('Identity')"></span>
                        </div>
                        <div class="input">
                            <el-select v-model="ruleForm.position" :placeholder="$t('pleaseSele')">
                                <el-option v-for="item in IDList" :key="item.id" :label="item.dictValue"
                                    :value="item.dictValue">
                                </el-option>
                            </el-select>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="titles">
                            <div class="must">*</div>
                            <span>Telegram</span>
                        </div>
                        <div class="w-full text-r">
                            <input type="text" v-model="ruleForm.telegram" :placeholder="$t('pleaseEnter')">
                        </div>
                    </li>
                    <li class="flex">
                        <div class="titles">
                            <!-- <div class="must">*</div> -->
                            <span v-text="$t('OtherInformation')"></span>
                        </div>
                        <div class="w-full text-r">
                            <input type="text" v-model="ruleForm.otherContact" :placeholder="$t('pleaseEnter')">
                        </div>
                    </li>
                    <li class="flex">
                        <div class="titles">
                            <div class="must">*</div>
                            <span v-text="$t('WeYou')"></span>
                        </div>
                        <div class="w-full text-r">
                            <textarea name="" id="" cols="30" rows="10" :placeholder="$t('pleaseEnter')"
                                v-model="ruleForm.help" @input=getHeight ref="textareaRef"></textarea>
                        </div>
                    </li>
                    <li class="submit-box">
                        <button type="button" class="submit" v-text="$t('Submit')" @click="submit"></button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flag">
            2022 UniMeta Pte. Ltd. All Rights Reserved.
        </div>
        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
    </div>

</template>

<script>
import { submit, dictionary } from '@/api/user'
import TipDialog from '@/components/TipDialog'
export default {
    components: {
        TipDialog
    },
    data() {
        return {
            ruleForm: {
                name: '',
                countryRegion: '',
                position: '',
                telegram: '',
                otherContact: '',
                help: '',
                otherCountry: ''
            },
            IDList: [
                { label: 'KOL', value: 'China', id: 1 },
                { label: '项目方', value: 'China', id: 2 },
                { label: '其他推广渠道', value: 'China', id: 3 },
            ],
            addressArr: [
                { address: '韩国', id: 1 },
                { address: '日本', id: 2 },
                { address: '俄罗斯', id: 3 },
                { address: '新加坡', id: 4 },
                { address: '美国', id: 5 },
                { address: '印度', id: 6 },
                { address: '英国', id: 7 },
                { address: '中国', id: 8 },
            ],
            TipText: '',
            tipShow: false,
            overNeed: true,
        }
    },
    async mounted() {
        const { data } = await dictionary({ code: "position_key" });
        this.IDList = data;
        {
            const { data } = await dictionary({ code: "form_contry" })
            this.addressArr = data
        }
    },
    methods: {
        getHeight() {
            // let _this = this;
            this.$nextTick(() => {
                // let textarea = _this.$refs.textareaRef;
                // var scrollHeight = textarea.scrollHeight; // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
                // console.log(scrollHeight);
                // var height = textarea.offsetHeight; // 屏幕上显示的高度
                // if (height <= scrollHeight) {
                // textarea.style.height = 'auto'; // 恢复默认值，这个作用就是根据内容自适应textarea高度
                // textarea.style.height = textarea.scrollHeight + 'px'; // 拿到最新的高度改变textarea的高度
                // }

            })
        },
        async submit() {
            this.overNeed = true;
            
            if (this.ruleForm['countryRegion'] == '0' && this.ruleForm['otherCountry'] == '') {
                this.overNeed = false;
                console.log(this.ruleForm);
            } else {
                for (const key in this.ruleForm) {
                    if (key !== 'otherContact' && !this.ruleForm[key] && key != 'otherCountry') {
                        this.overNeed = false;
                    }
                }
                console.log(this.ruleForm);
            }
            console.log(this.ruleForm);
            if (!this.overNeed) {
                // this.tipShow = true;
                // this.TipText = this.$t('mustbeInput');
                this.$toast.show(this.$t('mustInput'))
                return;
            }
            if(this.ruleForm['countryRegion'] != '0'){
                this.ruleForm['otherCountry'] = '';
            }
            const {code} = await submit(this.ruleForm);
            if(code==200){
                for (const key in this.ruleForm) {
                    this.ruleForm[key] = '';
                }
                this.$toast.show(this.$t('Operation-succeeded'));

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.must {
    font-size: 30px;
    color: #FF3A8A;
    margin-right: 15px;
    width: 10px;
}

a {
    color: #fff;
}

.titles {
    display: flex;
    align-items: center;
    line-height: 32px;
    margin-bottom: 16px;
    margin-top: 16px;

    .tit {
        font-size: 24px;
        color: #FFFFFF;
    }
}
.mt10 {
    margin-top: 10px;
}
.box {
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 52px;
    background-color: #210854;
    padding: 2.71vw 3.64vw 40px;
    color: #fff;
}

.flag {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #B3B3B3;
}

.foot-1 {
    width: 32px;
    vertical-align: middle;
    margin-right: 15px;
}

.foot-2 {
    width: 39px;
    vertical-align: middle;
    margin-left: 20px;
}

.flex {
    display: flex;
}

.links {
    margin-right: 10px;
}

.link {
    padding: 10px 34px;
    background-color: #D06DC7;
    border-radius: 20px;

}

.al-cen {
    align-items: center;
}

.left {
    text-align: left;

    // width: 800px;
    .content {
        font-size: 24px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;

        .connet {
            font-size: 30px;
        }

        li {
            margin-bottom: 30px;
        }
    }

    .logo {
        width: 233px;
        margin-bottom: 35px;
    }
}

/deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    font-size: 24px !important;
    border-color: #C198FF;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #C198FF;
}

/deep/ .el-select {
    height: 69px;
    width: 327px;
    width: 100%;
}

/deep/ .el-input__inner {
    height: 69px;
    width: 327px;
    font-size: 24px !important;
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #C198FF;
}

/deep/ .el-input__inner::placeholder {
    font-size: 24px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

@media screen and(max-width:620px) {

    .al-cen {
        flex-wrap: wrap;
    }

    .box {
        justify-content: center;
    }

    .left {
        justify-content: center;
        margin-bottom: 50px;
        flex-wrap: wrap;

        .content {
            height: auto;
        }
    }

    .connet {
        width: 600px;
    }

}

.w-full {
    width: 100%;
}

.text-l {
    text-align: left;
}

.text-r {
    text-align: right;
}

.right {
    .connet {
        font-size: 30px;
        margin-bottom: 18px;
    }

    .info {
        font-size: 24px;

        li {
            width: 477px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            align-self: flex-end;
            margin-bottom: 10px;

            textarea,
            input {
                width: 327px;
                height: 69px;
                background: transparent;
                border: 1px solid #C198FF;
                border-radius: 10px;
                padding-left: 10px;
                box-sizing: border-box;
                color: #C198FF;

                &::placeholder {
                    color: #C198FF;
                }

                &.hope {
                    height: 112px;
                }
            }

            textarea {
                min-height: 112px;
                resize: none;
                // overflow: hidden;
                scrollbar-track-color: transparent;
            }

            textarea::-webkit-scrollbar {
                width: 4px;
            }

            textarea::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            textarea::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);

            }
        }

        .submit-box {
            text-align: center;
            width: 477px;
            justify-content: center;
            margin-left: 40px;

            .submit {
                text-align: center;
                width: 256px;
                height: 77px;
                border-radius: 10px;
                background-color: #FF3A8A;
                color: #fff;
                border: none;
                outline: none;
                cursor: pointer;
            }
        }

    }
}
</style>