<template>
    <div>
        <Dia :title="$t('ContactService')">
            <div class="dao-inner">
                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit" v-text="$t('name')"></div>
                </div>
                <div class="input">
                    <el-input v-model="obj.name" :placeholder="$t('pleaseEnter')"></el-input>
                </div>

                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">{{ $t('Country/Region') }}</div>
                </div>
                <div class="input">
                    <el-select v-model="obj.countryRegion" :placeholder="$t('pleaseSele')"
                        style="width:100% !important">
                        <el-option v-for="item in addressArr" :key="item.id" :label="item.dictValue"
                            :value="item.dictValue">
                        </el-option>
                    </el-select>
                </div>

                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit" v-text="$t('Identity')"></div>
                </div>
                <div class="input">
                    <el-select v-model="obj.position" :placeholder="$t('pleaseSele')" style="width:100% !important">
                        <el-option v-for="item in IDList" :key="item.id" :label="item.dictValue"
                            :value="item.dictValue">
                        </el-option>
                    </el-select>
                </div>

                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit">Telegram ID</div>
                </div>
                <div class="input">
                    <el-input v-model="obj.telegram" :placeholder="$t('pleaseEnter')"></el-input>
                </div>
                <!-- 其他常用联系方式 -->
                <div class="titles">
                    <!-- <div class="must">*</div> -->
                    <div class="tit" v-text="$t('OtherInformation')"></div>
                </div>
                <div class="input">
                    <el-input v-model="obj.otherContact" :placeholder="$t('pleaseEnter')"></el-input>
                </div>

                <div class="titles">
                    <div class="must">*</div>
                    <div class="tit" v-text="$t('WeYou')"></div>
                </div>
                <div class="input">
                    <el-input v-model="obj.help" :placeholder="$t('pleaseEnter')"></el-input>
                </div>

                <div class="btn" v-text="$t('Submit')" @click="submit">
                </div>
            </div>
        </Dia>
        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
    </div>

</template>
  
<script>
import Dia from '@/components/Dialog'
import { submit, dictionary } from '@/api/user'
import TipDialog from '@/components/TipDialog'
export default {
    components: {
        Dia, TipDialog
    },
    data() {
        return {
            obj: {
                name: '',
                countryRegion: '',
                position: '',
                telegram: '',
                otherContact: '',
                help: ''
            },
            addressArr: [
                { address: '韩国', id: 1 },
                { address: '日本', id: 2 },
                { address: '俄罗斯', id: 3 },
                { address: '新加坡', id: 4 },
                { address: '美国', id: 5 },
                { address: '印度', id: 6 },
                { address: '英国', id: 7 },
                { address: '中国', id: 8 },
            ],
            IDList: [
                { label: 'KOL', value: 'China', id: 0 },
                { label: '项目方', value: 'China', id: 1 },
                { label: '其他推广方', value: 'China', id: 2 },
            ],
            platFormArr: [{ name: 'Twitter', isChecked: false },
            { name: 'Telegram', isChecked: false },
            { name: 'Youtube', isChecked: false },
            { name: '其他请填写', isChecked: false },
            ],
            twitterObj: {
                nickName: '',
                fansNum: '',
                link: ''
            },
            telegramObj: {
                nickName: '',
                fansNum: '',
                link: ''
            },
            youtubeObj: {
                nickName: '',
                fansNum: '',
                link: ''
            },
            otherPlatObj: {
                platName: '',
                nickName: '',
                fansNum: '',
                link: ''
            },
            TipText: '',
            tipShow: false,
            overNeed: true,
        }
    },
    async mounted() {
        const { data } = await dictionary({ code: "position_key" });
        this.IDList = data;
        {
            const { data } = await dictionary({ code: "countries_region_cn" })
            this.addressArr = data
        }
    },
    methods: {
        checkEvent(item) {
            item.isChecked = !item.isChecked
        },
        async submit() {
            for (const key in this.obj) {
                console.log(this.obj[key]);
                if (key !== 'otherContact' && !this.obj[key]) {
                    this.overNeed = false;
                }else{
                    this.overNeed = true;
                }
            }
            if (!this.overNeed) {
                this.tipShow = true;
                this.TipText = this.$t('mustbeInput');
                return;
            }
            await submit(this.obj);
        }
    }
}
</script>
<style lang="scss" scoped>
.input {
    margin-bottom: 20px;
}

/deep/ .el-input__inner {
    width: 100% !important;
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
}

/deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #C198FF;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #C198FF;
}

// /deep/ .el-select {
//     width: 100%;
// }

.must {
    font-size: 30px;
    color: #FF3A8A;
    margin-right: 15px;
    width: 10px;
}

.dao-inner {
    width: 87%;
    margin: 0 auto;
    box-sizing: border-box;

    .titles {
        display: flex;
        align-items: center;
        line-height: 32px;
        margin-bottom: 16px;
        margin-top: 16px;

        .tit {
            font-size: 24px;
            color: #FFFFFF;
        }
    }
}

.platform {
    display: flex;
    justify-content: space-between;

    .platbox {
        width: 127px;
        height: 48px;
        border: 1px solid #C198FF;
        border-radius: 10px;
        color: #8361B5;
        font-size: 18px;
        text-align: center;
        line-height: 48px;
    }
}

.twitterbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #FFE698;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #FFE698;
    }

    /deep/ .el-input__inner:hover {
        border-color: #FFE698;
    }
}



.telegrambox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #98FF9D;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #98FF9D;
    }

    /deep/ .el-input__inner:hover {
        border-color: #98FF9D;
    }
}


.youtubebox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #5792FF;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #5792FF;
    }

    /deep/ .el-input__inner:hover {
        border-color: #5792FF;
    }
}

.otherplatbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #6FECE7;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #6FECE7;
    }

    /deep/ .el-input__inner:hover {
        border-color: #6FECE7;
    }
}

.pbox {
    padding-bottom: 70px;
}

.btn {
    width: 534px;
    height: 88px;
    cursor: pointer;
    background: #FF3A8A;
    border-radius: 40px 5px 40px 5px;
    text-align: center;
    line-height: 88px;
    font-size: 36px;
    color: #FFFFFF;
    margin: 51px auto 0 auto;
}
</style>
