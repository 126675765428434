<template>
    <div>
        <Dia :title="$t('neddPush')">
            <div class="dao-inner">
                <div class="limit" v-if="!$store.state.memberType">
                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('name')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="Unlimited.name" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('Country/Region')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="Unlimited.countryRegion" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in addressArr" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>

                        <div class="titles" v-if="Unlimited.countryRegion == '0'">
                            <div class="must">*</div>
                            <div class="tit" v-text="$t('otherCountry')"></div>
                        </div>
                        <div class="input" v-if="Unlimited.countryRegion == '0'">
                            <el-input v-model="Unlimited.otherCountry" :placeholder="$t('pleaseEnter')"></el-input>
                        </div>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('sex')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="Unlimited.gender" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in sexList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('Identity')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="Unlimited.identity" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in IDList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit">Telegram ID</div>
                    </div>
                    <div class="input">
                        <el-input v-model="Unlimited.telegram" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('OtherInformation')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="Unlimited.otherCommonContactDetails" :placeholder="$t('pleaseEnter')">
                        </el-input>
                    </div>
                </div>
                <div class="must-need">
                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('xuType')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="form.requirementType" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in needList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                        <el-input v-model="form.otherNeed" :placeholder="$t('typeNeddType')"
                            v-if="form.requirementType == 6"></el-input>
                    </div>


                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('VenueRequirement')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="form.onlineOrOffline" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in placeList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('RequireFans')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.requirementsFansNumber" :placeholder="$t('pleaseEnter')">
                        </el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('PartnersSeek')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="form.seekPartners" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in seekPartnersList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('BriefDesRequir')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.detailedDescription" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('NumberRecruitedCollaborators')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.numberRecruitedCollaborators" :placeholder="$t('pleaseEnter')">
                        </el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('Remuneration')"></div>
                    </div>
                    <div class="input">
                        <el-select v-model="form.remuneration" :placeholder="$t('pleaseSele')">
                            <el-option v-for="item in remunerationList" :key="item.id" :label="item.dictValue"
                                :value="item.dictKey">
                            </el-option>
                        </el-select>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('RegistrationDeadline')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.registrationDeadline" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('ProjectName')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.projectName" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="must">*</div>
                        <div class="tit" v-text="$t('ProjectFields')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="form.projectFields" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit">
                            <span v-text="$t('Project-poster')"></span>  
                            <span class="rep" v-text="$t('rep')"></span>
                        </div>
                    </div>
                    <el-upload class="avatar-uploader" action="/api/blade-resource/oss/endpoint/put-file-super"
                        :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" accept=".jpg,.png">
                        <img v-if="this.noform.promoteImg" :src="this.noform.promoteImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    

                    <div class="titles">
                        <div class="tit" v-text="$t('ProjectIntroduction')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.projectIntroduction" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                    <div class="titles">
                        <div class="tit" v-text="$t('OfficialWebsite')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.officialWebsite" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                    <div class="titles">
                        <div class="tit" v-text="$t('TwitterAccount')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.twitterAccount" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                    <!-- <div class="titles">
                        <div class="tit" v-text="$t('quLink')"></div>
                    </div> -->
                    <!-- <div class="input">
                        <el-input v-model="noform.quLink" :placeholder="$t('pleaseEnter')"></el-input>
                    </div> -->

                    <div class="titles">
                        <div class="tit" v-text="$t('proHope')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.projectVision" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('pushNeddT')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.marketDemand" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('fixService')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.solutions" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('tou')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.investor" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('JHitBtc')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.issuedOnWhichExchange" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('TokenFen')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.economicModel" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('proStart')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.startTime" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>


                    <div class="titles">
                        <div class="tit" v-text="$t('firstTime')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.estimated" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>

                    <div class="titles">
                        <div class="tit" v-text="$t('publicChain')"></div>
                    </div>
                    <div class="input">
                        <el-input v-model="noform.basedOnWhichChain" :placeholder="$t('pleaseEnter')"></el-input>
                    </div>
                </div>

                <div class="btn" v-text="$t('Submit')" @click="sub">
                </div>

            </div>
        </Dia>
        <TipDialog :tipVisible="tipShow" :text="TipText" @close="tipShow = false">
        </TipDialog>
    </div>

</template>

<script>
// import Dialog from '@/components/Dialog'
import TipDialog from '@/components/TipDialog'
import selOpt from "@/assets/uni-meta/sel-opt.png"
import Dia from '@/components/Dialog'
import { cooperationDemand, dictionary } from '@/api/user'
import { updateUser } from '@/utils'
export default {
    components: {
        TipDialog, Dia,
    },
    props: {
        isWhitelistUsers: {
            type: Boolean,
            default: () => { return false }
        }
    },
    data() {
        return {
            selOpt: selOpt,
            needSel: false,
            placeSel: false,
            partnersSel: false,
            remunerationSel: false,
            idSel: false,
            needList: [
                { label: this.$t('Giveaway'), value: 'China', id: 1 },
                { label: this.$t('twitterSpace'), value: 'China', id: 2 },
                { label: this.$t('ProjectPromotion'), value: 'China', id: 3 },
                { label: this.$t('activePuish'), value: 'China', id: 4 },
                { label: this.$t('actionUderline'), value: 'China', id: 5 },
                { label: this.$t('otherIpnut'), value: 'China', id: 6 },
            ],
            placeList: [
                { label: this.$t('online'), value: 'China', id: 1 },
                { label: this.$t('unline'), value: 'China', id: 2 },
            ],
            seekPartnersList: [
                { label: this.$t('KOL'), value: 'China', id: 1 },
                { label: this.$t('otherParties'), value: 'China', id: 2 },
                { label: this.$t('otherPromotion'), value: 'China', id: 3 },
            ],
            remunerationList: [
                { label: this.$t('howManyDollarsStart'), value: 'China', id: 1 },
                { label: this.$t('mainlyBasedExchange'), value: 'China', id: 2 },
                { label: this.$t('DpecificIndividually'), value: 'China', id: 3 }
            ],
            IDList: [
                { label: this.$t('KOL'), value: 'China', id: 1 },
                { label: this.$t('otherParties'), value: 'China', id: 2 },
                { label: this.$t('otherPromotion'), value: 'China', id: 3 },
            ],
            addressArr: [
                { address: this.$t('korea'), id: 1 },
                { address: this.$t('Japan'), id: 2 },
                { address: this.$t('Russia'), id: 3 },
                { address: this.$t('Singapore'), id: 4 },
                { address: this.$t('U.S.A'), id: 5 },
                { address: this.$t('India'), id: 6 },
                { address: this.$t('britain'), id: 7 },
                { address: this.$t('China'), id: 8 },
            ],
            sexList: [
                { label: this.$t('man'), id: 1 },
                { label: this.$t('women'), id: 2 },
            ],
            form: {
                requirementType: '',
                otherNeed: '',
                onlineOrOffline: '',
                requirementsFansNumber: '',
                seekPartners: '',
                detailedDescription: '',
                numberRecruitedCollaborators: '',
                remuneration: '',
                registrationDeadline: '',
                projectName: '',
                projectFields: ''
            },
            noform: {
                promoteImg: '',
                projectIntroduction: '',
                officialWebsite: '',
                twitterAccount: '',
                projectVision: '',
                marketDemand: '',
                solutions: '',
                investor: '',
                issuedOnWhichExchange: '',
                economicModel: '',
                startTime: '',
                estimated: '',
                basedOnWhichChain: ''
            },
            Unlimited: {
                name: '',
                countryRegion: '',
                otherCountry: '',
                gender: '',
                identity: '',
                telegram: '',
                otherCommonContactDetails: ''
            },
            isUnlimited: false,
            overNeed: true,
            TipText: '',
            tipShow: false
        }
    },
    async mounted() {
        updateUser();
        const { data } = await dictionary({ code: "position_key" });
        this.IDList = data;
        {
            const { data } = await dictionary({ code: "form_contry" })
            this.addressArr = data
        }
        {
            const { data } = await dictionary({ code: "sex" })
            this.sexList = data
        }
        {
            const { data } = await dictionary({ code: "site_requirements" })
            this.placeList = data
        }
        {
            const { data } = await dictionary({ code: "expected_partners" })
            this.seekPartnersList = data
        }
        {
            const { data } = await dictionary({ code: "remunerationList" })
            this.remunerationList = data
        }
        {
            const { data } = await dictionary({ code: "demand_type" })
            this.needList = data;
            this.needList.push({ dictKey: 6, dictValue: this.$t('otherIpnut') })

        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            console.log(res.data.link,file);
            this.noform.promoteImg = res.data.link;
        },
        beforeAvatarUpload(file) {
            console.log(file);
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                this.$message.error(this.$t('uploaded-500'));
            }
            return isLt2M;
        },
        close() {
            this.$emit('close')
        },
        closeDia() {
            console.log(123);
            this.pushDialogVisible = false;
        },
        optionActive(list, item, index, mod, type) {
            console.log();
            type === 1 ? this.Unlimited[mod] = item.label : type === 2 ? this.form[mod] = item.label : this.noform[mod] = item.label;

            list.forEach((it, i) => {
                if (i === index) {
                    list[i].active = true;
                } else {
                    it.active = false;
                }
            })
            this.reset()
        },
        reset() {
            this.needSel = false;
            this.placeSel = false;
            this.partnersSel = false;
            this.remunerationSel = false;
            this.idSel = false;
        },
        async sub() {
            this.overNeed = true;

            if (!this.isWhitelistUsers) {
                if (this.Unlimited['countryRegion'] == '0' && this.Unlimited['otherCountry'] == '') {
                    this.overNeed = false;
                } else {
                    for (const key in this.Unlimited) {
                        if (key != 'otherCountry' && !this.Unlimited[key]) {
                            this.overNeed = false;
                        }
                    }
                }
            }
            console.log(this.Unlimited,this.form,this.overNeed,'this.overNeed');
            for (const key in this.form) {
                if (this.form.requirementType == 6) {
                    if (!this.form[key]) {
                        this.overNeed = false;
                    }
                } else {
                    if (!this.form[key] && key != 'otherNeed') {
                        this.overNeed = false;
                    }
                }

            }

            if (!this.overNeed) {
                this.$toast.show(this.$t('mustInput'));
                return;
                // this.tipShow = true;
                // this.TipText = this.$t('mustInput');
            }
            if (this.Unlimited['countryRegion'] != '0') {
                this.Unlimited['otherCountry'] = '';
            }
            const params = { ...this.form, ...this.noform, ...this.Unlimited }
            const { code } = await cooperationDemand(params)
            if (code == 200) {
                updateUser();
                this.$toast.show(this.$t('Operation-succeeded'));
                this.close()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    margin-bottom: 20px;
}
.rep {
    font-size: 18px;
}
/deep/ .el-input__inner {
    background-color: transparent;
    border: 1px solid #C198FF;
    border-radius: 10px;
    font-size: 18px;
    color: #fff;
}

/deep/ .el-input__inner::placeholder {
    font-size: 18px;
    color: #8361B5;
}

/deep/ .el-input__inner:hover {
    border-color: #C198FF;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #C198FF;
}

/deep/ .el-select .el-input__inner:focus {
    border-color: #C198FF;
}

/deep/ .el-select {
    width: 100%;
}

.must {
    font-size: 30px;
    color: #FF3A8A;
    margin-right: 15px;
    width: 10px;
}

.dao-inner {
    width: 87%;
    margin: 0 auto;
    box-sizing: border-box;

    .titles {
        display: flex;
        align-items: center;
        line-height: 32px;
        margin-bottom: 16px;
        margin-top: 16px;

        .tit {
            font-size: 24px;
            color: #FFFFFF;
        }
    }
}

.platform {
    display: flex;
    justify-content: space-between;

    .platbox {
        width: 127px;
        height: 48px;
        border: 1px solid #C198FF;
        border-radius: 10px;
        color: #8361B5;
        font-size: 18px;
        text-align: center;
        line-height: 48px;
    }
}

.twitterbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #FFE698;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #FFE698;
    }

    /deep/ .el-input__inner:hover {
        border-color: #FFE698;
    }
}



.telegrambox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #98FF9D;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #98FF9D;
    }

    /deep/ .el-input__inner:hover {
        border-color: #98FF9D;
    }
}


.youtubebox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #5792FF;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #5792FF;
    }

    /deep/ .el-input__inner:hover {
        border-color: #5792FF;
    }
}

.otherplatbox {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #6FECE7;
        border-radius: 10px;
        font-size: 18px;
        color: #fff;
    }

    /deep/ .el-input__inner::placeholder {
        font-size: 18px;
        color: #6FECE7;
    }

    /deep/ .el-input__inner:hover {
        border-color: #6FECE7;
    }
}

.pbox {
    padding-bottom: 70px;
}

.btn {
    width: 534px;
    height: 88px;
    cursor: pointer;
    background: #FF3A8A;
    border-radius: 40px 5px 40px 5px;
    text-align: center;
    line-height: 88px;
    font-size: 36px;
    color: #FFFFFF;
    margin: 51px auto 0 auto;
}
</style>

<style lang="scss">
.el-select-dropdown__item.selected {
    color: #C198FF !important;
}

.el-popper[x-placement^=bottom] .popper__arrow {
    display: none;
}

.el-select-dropdown {
    background: #12042E !important;
    border: none !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
    background-color: #12042E !important;
}
</style>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>