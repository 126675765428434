<template>
    <div class="page-home">
        <div class="kol-box">
            <div class="under-banner">
                <img src="@/assets/uni-meta/isbackicon.png" class="goback" @click="backEvent">
                <div class="banner">
                    <div class="user-img">
                        <img src="../../assets/uni-meta/demand/rl.png" alt="">
                    </div>
                    <div class="title">
                        {{ kolDetailObj.projectName }}
                    </div>
                    <div class="search-more" v-html="kolDetailObj.projectVision">
                    </div>
                    <div class="tip">
                        <img src="../../assets/uni-meta/demand/tip.png">
                        <div class="btn" v-for="(item, index) in kolDetailObj.projectFields" :key="index">{{ item }}
                        </div>
                        <div class="btn">{{ kolDetailObj.onlineOrOffline }}</div>
                        <!-- <div class="btn" v-text="$t('OnlineActivities')"></div> -->
                    </div>
                    <div class="apply">
                        <div class="remuneration">
                            <span class="one">{{ $t('Remuneration') }}:</span>
                            <span class="two" v-text="$t('ChatI')"></span>
                        </div>
                        <div class="apply-btn" v-text="$t('ApplyCooperation')" @click="showDetail"
                            v-if="isApplyMember == -1">
                        </div>
                        <div class="apply-btns" v-text="$t('ApplyCooperation')" v-if="isApplyMember == 1">
                        </div>
                    </div>
                    <div class="time">
                        <img src="../../assets/uni-meta/demand/rl.png" alt="">
                        <span>{{ $t('RegistrationDeadline')
                        }}:{{ this.kolDetailObj.overTime > 5 ? this.$t('Longterm') :
        this.kolDetailObj.registrationDeadline
}}</span>
                    </div>
                </div>

                <div class="card-box">
                    <div class="card" v-for="(item, index) in cardArr" :key="index">
                        <div class="title">{{ item.title }}</div>
                        <div class="kind">{{ item.kind }}</div>
                    </div>
                </div>
                <div class="detail-card">
                    <div class="title" v-text="$t('BriefDesRequir')"></div>
                    <div class="detail" v-html="kolDetailObj.detailedDescription">
                    </div>
                </div>
                <div class="data-box">
                    <div class="title" v-text="$t('proInfo')"></div>
                    <div class="box1 box">
                        <div class="inner">

                            <div class="titles">{{ $t('ProjectDescription') }}</div>
                            <div class="msg" v-html=" kolDetailObj.projectIntroduction">
                            </div>
                        </div>

                    </div>
                    <div class="boxinner">
                        <div class="box2 box">
                            <div class="inner">
                                <div class="titles" v-text="$t('pushNeddT')"></div>
                                <div class="msg" v-html=" kolDetailObj.marketDemand"></div>
                            </div>
                        </div>
                        <div class="box2 box">
                            <div class="inner">
                                <div class="titles" v-text="$t('Core')"></div>
                                <div class="msg" v-html=" kolDetailObj.solutions"></div>
                            </div>
                        </div>

                    </div>
                    <div class="box1 box">
                        <div class="inner">

                            <div class="titles" v-text="$t('InvestmentOrganization')"></div>
                            <div class="msg" v-html=" kolDetailObj.investor"></div>
                        </div>
                    </div>
                </div>
                <!-- link box -->
                <div class="linkline" v-for="(item, index) in linkArr" :key="index">
                    <div class="left">
                        <span class="name">{{ item.name }}</span>
                        <a :href="item.link" target="_blank"><span class="link">{{ item.link }}</span></a>
                    </div>
                    <img src="../../assets/uni-meta/demand/link.png" alt="">
                </div>
            </div>
        </div>
        <PublishNow v-if="PublishNowVisible" :isWhitelistUsers="$store.state.whiteUser">
        </PublishNow>
    </div>
</template>
  
<script>
import { cooperationDemandDetail } from '@/api/kol'
import { getRequireList, toSignUp } from '@/api/user'
import PublishNow from '@/components/PublishNow'
export default {
    components: {
        PublishNow
    },
    data() {
        return {
            cardArr: [
                { title: this.$t('xuType'), kind: '' },
                { title: this.$t('NumberRecruitedCollaborators'), kind: this.$t('Unlimited') },
                { title: this.$t('PartnersSeek'), kind: this.$t('otherParties') },
                { title: this.$t('RequireFans'), kind: this.$t('Unlimited') }
            ],
            requireList: [],
            linkArr: [
                { name: this.$t('OfficialWebsite'), link: 'https://link3.to/onbd ' },
                { name: 'Twitter', link: 'https://twitter.com/onbd_art' }
            ],
            kolDetailObj: {},
            isApplyMember: -1,
            PublishNowVisible: false
        };
    },
    computed: {
    },
    filters: {
    },
    methods: {
        closeDia() {
            this.PublishNowVisible = false;
        },
        backEvent() {
            this.$router.back()
        },
        showDetail() {
            if (!this.$store.state.account || !this.$store.state.sigStr) {
                this.$toast.show(this.$t('beforeChina'))
            } else if (this.$store.state.whiteUser) {
                this.whiteUp()
            } else if (!this.$store.state.whiteUser) {
                if (this.$store.state.memberType) {
                    this.$toast.show(this.$t('quicktodoS'))
                    return
                }
                this.$toast.show(this.$t('Youarenot'))
                setTimeout(() => {
                    this.PublishNowVisible = true;
                }, 10000)
                return
            }
        },
        async whiteUp() {
            await toSignUp({
                demandId: this.$route.query.id,
                memberId: this.$store.state.userInfo.user_id	//用户的id
            })
            this.isApplyMember = 1;
            this.$toast.show(this.$t('okSub'))
        },
        getDetail(par) {
            cooperationDemandDetail(par).then(res => {
                this.kolDetailObj = res.data;
                this.kolDetailObj.projectFields = res.data.projectFields.split(',');
                // registrationDeadline
                console.log()
                this.kolDetailObj.overTime = Number(this.kolDetailObj.registrationDeadline.slice(0, 4)) - new Date().getFullYear()
                this.cardArr[0].kind = this.kolDetailObj.requirementType
                this.cardArr[1].kind = this.kolDetailObj.numberRecruitedCollaborators
                this.cardArr[2].kind = this.kolDetailObj.seekPartners
                this.cardArr[3].kind = this.kolDetailObj.requirementsFansNumber
                this.linkArr[0].link = this.kolDetailObj.officialWebsite
                this.linkArr[1].link = this.kolDetailObj.twitterAccount

            })
        },
        async getRequireList() {
            const { data } = await getRequireList({ memberId: this.$store.state.userInfo.user_id })
            const { records } = data;
            this.requireList = records;
            let i = this.requireList.findIndex(el => el.demandId == this.$route.query.id)
            if (i != -1) {
                this.isApplyMember = 1
            } else {
                this.isApplyMember = -1
            }
        },
    },
    created() {
        this.getDetail({ id: this.$route.query.id })
        this.getRequireList();
    },
};
</script>
  
<style scoped lang="scss">
.kol-box {
    background: #371381;
    width: 100%;

    .under-banner {
        background: url('../../assets/uni-meta/kol-u.png') no-repeat top;
        background-size: 1920px 1428px;
        width: 100%;
        min-height: 1800px;
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;

        .goback {
            position: absolute;
            top: 0px;
            left: 30px;
            width: 50px;
            height: 50px;
            cursor: pointer;
        }

        .banner {
            width: 1443px;
            min-height: 612px;
            background: url('../../assets/uni-meta/demand/demand-banner.png') no-repeat center;
            background-size: 100% 100%;
            margin: 0 auto 35px auto;
            padding-top: 49px;
            box-sizing: border-box;
            padding-left: 83px;
            box-sizing: border-box;
            padding-bottom: 50px;

            .user-img {
                width: 85px;
                height: 85px;
                border-radius: 50%;

                img {
                    width: 85px;
                    height: 85px;
                    border-radius: 50%;
                }
            }

            .title {
                // width: 193px;
                height: 58px;
                font-size: 72px;
                font-weight: normal;
                color: #FF3A8A;
                margin-top: 55px;
            }

            .search-more {
                font-size: 24px;
                font-weight: normal;
                color: #D06DC7;
                margin-top: 32px;
            }

            .tip {
                display: flex;
                align-items: center;
                margin-top: 27px;

                img {
                    width: 26px;
                    height: 23px;
                }

                .btn {
                    min-width: 126px;
                    height: 40px;
                    border: 1px solid #C198FF;
                    border-radius: 10px;
                    font-size: 20px;
                    font-weight: normal;
                    color: #C198FF;
                    line-height: 40px;
                    margin-left: 15px;
                    box-sizing: border-box;
                    padding-right: 15px;
                    padding-left: 15px;

                }


            }

            .apply {
                display: flex;
                align-items: center;
                margin-top: 33px;

                .remuneration {
                    width: 289px;
                    height: 91px;
                    background: url('../../assets/uni-meta/demand/money.png') no-repeat center;
                    background-size: 100% 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    .one {
                        font-size: 24px;
                        font-weight: normal;
                        color: #FFFFFF;
                    }

                    .two {
                        font-size: 18px;
                        font-weight: normal;
                        color: #FF3A8A;
                    }
                }

                .apply-btn {
                    width: 327px;
                    height: 62px;
                    background: #FF3A8A;
                    border-radius: 30px 0px 30px 0px;
                    margin-left: 75px;
                    font-size: 30px;
                    font-weight: normal;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 62px;
                    cursor: pointer;
                }

                .apply-btns {
                    width: 327px;
                    height: 62px;
                    background: #ccc;
                    border-radius: 30px 0px 30px 0px;
                    margin-left: 75px;
                    font-size: 30px;
                    font-weight: normal;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 62px;
                    cursor: pointer;
                }
            }

            .time {
                margin-top: 35px;
                display: flex;
                align-items: center;

                img {
                    width: 26px;
                    height: 26px;
                }

                span {
                    font-size: 20px;
                    font-weight: normal;
                    color: #FFFFFF;
                    margin-left: 17px;
                }
            }
        }

        .card-box {
            width: 1443px;
            margin: 46px auto 46px auto;
            display: flex;
            justify-content: space-between;

            .card {
                background: url('../../assets/uni-meta/demand/card.png') no-repeat center;
                background-size: 100% 100%;
                width: 323px;
                min-height: 159px;
                padding-top: 40px;
                padding-left: 38px;
                padding-bottom: 10px;
                box-sizing: border-box;

                .title {
                    font-size: 20px;
                    font-weight: normal;
                    color: #E781FF;
                }

                .kind {
                    margin-top: 27px;
                    font-size: 24px;
                    font-weight: normal;
                    color: #C198FF;
                }
            }
        }

        .detail-card {
            width: 1443px;
            min-height: 297px;
            background: url('../../assets/uni-meta/demand/detail.png') no-repeat center;
            background-size: 100% 100%;
            margin: 0 auto;
            box-sizing: border-box;
            padding-top: 52px;
            padding-left: 52px;

            .title {
                font-size: 20px;
                font-weight: normal;
                color: #E781FF;
            }
            .detail {
                margin-top: 36px;
                font-size: 18px;
                font-weight: normal;
                color: #C198FF;
                line-height: 36px;
            }

        }
        .data-box {
            width: 1442px;
            min-height: 930px;
            margin: 95px auto 99px auto;
            background: url('../../assets/uni-meta/demand/data.png') no-repeat center;
            background-size: 100% 100%;
            position: relative;
            box-sizing: border-box;
            padding-top: 129px;
            padding-bottom: 80px;

            .title {
                position: absolute;
                font-size: 30px;
                font-weight: normal;
                color: #C198FF;
                right: 48px;
                top: -20px;
            }
            .box {

                border-radius: 10px;
                background-color: #2B2592;
                box-sizing: border-box;


            }

            .titles {
                font-size: 22px;
                color: #FFFFFF;
                margin-bottom: 20px;

            }

            .msg {
                font-size: 14px;
                color: #DAD0DA;
                line-height: 30px;
            }

            .box1 {
                width: 1180px;
                min-height: 200px;
                margin: 0 auto 44px auto;
                display: flex;
                align-items: center;
                justify-content: center;

                .inner {
                    width: 1180px;
                    min-height: 200px;
                    padding-left: 58px;
                    padding-right: 58px;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    background: url(../../assets/uni-meta/demand/borderline.png) no-repeat center;
                    background-size: 100% 100%;
                }
            }

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: url(../../assets/uni-meta/demand/borderline.png) no-repeat center;
                background-size: 100% 100%;
                padding-bottom: 20px;
                padding-top: 20px;
                box-sizing: border-box;

            }

            .boxinner {
                display: flex;
                width: 1180px;
                margin: 0 auto;
                justify-content: space-between;
                margin-bottom: 51px;

                .inner {
                    width: 100%;
                    height: 100%;
                    // border-radius: 10px;
                    padding-left: 58px;
                    padding-right: 58px;
                    box-sizing: border-box;
                }

                .box2 {
                    width: 563px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .linkline {
        width: 1436px;
        height: 77px;
        display: flex;
        align-items: center;
        border: 1px solid #C198FF;
        border-radius: 38px;
        margin: 0 auto 52px auto;
        box-sizing: border-box;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: space-between;

        .name {
            font-size: 24px;
            color: #FFFFFF;
        }

        .link {
            font-size: 24px;
            font-weight: normal;
            color: #E781FF;
        }

        .left {
            display: flex;
            justify-content: space-between;
            flex: 1;
        }

        img {
            width: 10px;
            height: 10px;
            margin-left: 62px;
        }
    }
}

@media screen and (max-width: 768px) {
    .banner {
        width: 100vw !important;
        padding-bottom: 30px;
        height: auto !important;

        .title {
            margin-top: 0px !important;
        }

        .remuneration {
            width: 339px !important;
            height: 109px !important;
            margin-bottom: 20px;
        }

        .apply {
            display: block !important;

            .apply-btn {
                margin-left: 0px !important;
            }
        }
    }

    .card-box {
        width: 98vw !important;
        flex-wrap: wrap;

        .card {
            width: 48vw !important;
            margin-top: 20px !important;
            height: 190px !important;
        }
    }

    .detail-card {
        width: 100vw !important;
        padding-left: 0px !important;
        text-align: center;
        height: 370px !important;
    }

    .data-box {
        width: 100vw !important;
        height: auto !important;
        padding-bottom: 30px !important;

        .boxinner {
            width: 100% !important;
            height: 300px !important;

            .box2 {
                width: 48% !important;
                height: 300px !important;

                .inner {
                    width: 99% !important;
                    height: 98% !important;
                }
            }
        }
    }

    .box1 {
        width: 80% !important;
        height: 400px !important;

        .inner {
            width: 99% !important;
            height: 98% !important;
        }
    }

    .linkline {
        width: 98vw !important;
    }
}
</style>
  