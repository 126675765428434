// import store from '@/store'
// import { Toast } from 'vant'

// const { ecc } = Eos.modules
// console.log('store===>',store.state.lang)


// export function objKeySort(arys) {
//   //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
//   var newkey = Object.keys(arys).sort()
//   var newObj = '' //创建一个新的对象，用于存放排好序的键值对
//   for (var i = 0; i < newkey.length; i++) {
//     //遍历newkey数组
//     newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&'
//   }
//   return newObj.substring(0, newObj.length - 1)
// }
import {refreshUser} from '@/api/user'
import store from '../store';
export function isPc() {
  return !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

export function isIos() {
  return navigator.userAgent.match(/(iPhone|iPod|ios|iPad|)/i)
}

export function isAndroid() {
  return navigator.userAgent.match(/(Android)/i)
}

export function isBitKeep() {
  return navigator.userAgent.match(/(BitKeep)/i)
}

export function isWechat() {
  return navigator.userAgent.match(/(MicroMessenger)/i)
}

/*
  浮点数截取位数精度
  参数 _float 取药处理的浮点数
      _decimal精度位数

  区别toFixed, toFixed会四舍五入
*/
export function tofixedWithEndPad(_float, _decimal) {
  if (!_float) {
    console.warn('num is required')
    return '0'
  }
  // let intLength = parseInt(String(_float)).toString().length
  let u=Math.pow(10,_decimal)

  let resNum=Math.floor(Number(_float)*u)/u

  //处理小数位数不足的情况
  let strResNumArr=String(resNum).split('.')
  
  let decimalDidget=strResNumArr[1] && strResNumArr[1].length ? strResNumArr[1].length :0 

  let decimalDidgetStr=(strResNumArr[1]?strResNumArr[1]:'') + (String(Math.pow(10,_decimal-decimalDidget)).slice(1))

  return strResNumArr[0]+'.'+decimalDidgetStr
}

export async function updateUser(){
  if(store.state.userInfo.user_id){
    const {code,data} = await refreshUser({memberId:store.state.userInfo.user_id});
    if(code==200){
      if(data.memberType!='3'){
        store.commit('SET_MEMBER_TYPE',true);
      }else{
        store.commit('SET_MEMBER_TYPE',false);
      }
      store.state.whiteUser = data.whiteList=='yes'?true:false;
    }
  }
 
}

