<template>
    <div class="page-home">
        <div class="bannertop">
            <img src="@/assets/uni-meta/kolDeatil/banner.png" class="b">
            <img src="@/assets/uni-meta/isbackicon.png" class="back" @click="backEvent">
        </div>
        <div class="kol-box">
            <div class="under-banner">
                <div class="kolcard c">
                    <div class="userimg">
                        <img :src="kolDetailObj.photo" alt="">
                    </div>
                    <div class="userinfo">

                        <div class="usermsg">
                            <div class="l">
                                <div v-for="(ite, inde) in kolDetailObj.platforms" :key="inde">
                                    <a :href="ite.accountUrl" target="_blank">
                                        <div class="three jelly">
                                            {{ ite.platformName }}
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="r">
                                <div class="name">{{ kolDetailObj.nickName }}</div>
                                <div class="base">
                                    <img src="../../assets/uni-meta/woman_icon.png" alt=""
                                        v-if="kolDetailObj.gender == 'woman'">
                                    <img v-else src="../../assets/uni-meta/man_icon.png" alt="">
                                    <div class="rz">
                                        <img src="../../assets/uni-meta/auth.png" alt="" class="auth"
                                            v-if="kolDetailObj.author != 'unauthorized' && kolDetailObj.author != '未认证'">
                                    </div>
                                    <div class="lan">
                                        {{ kolDetailObj.language }}
                                    </div>
                                </div>
                                <div class="address">
                                    <img src="../../assets/uni-meta/kolDeatil/dw.png" alt="">
                                    <div class="info">
                                        {{ kolDetailObj.area }}
                                    </div>
                                </div>

                                <div v-if="isApplyMember == -1" class="hz" v-text="$t('Appointment')"
                                    @click="appleEvent">
                                </div>
                                <div v-if="isApplyMember == 1" class="hzs" v-text="$t('alreadyAppy')">
                                </div>
                            </div>
                        </div>
                        <div class="jianjie">
                            <div class="title" v-text="$t('Introduction')"></div>
                            <div class="detail">
                                {{ kolDetailObj.introduction }}
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="cooperation">
                        <div class="title" v-text="$t('Cooperation')">
                        </div>
                        <div class="innerbox">
                            <div class="inn" v-for="(item, index) in cardArr" :key="index">
                                <div class="ror" v-if="index != cardArr.length - 1">
                                    >>
                                </div>
                                <div class="info">
                                    <img :src="item.icon" alt="">
                                    <div>{{ item.info }}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="center c">
                    <div class="left">
                        <div class="plat">
                            <div v-for="(item, index) in kolDetailObj.platforms" :key="index"
                                :class="platChoose == index ? 'platforma' : 'platform'" @click="changePlat(index)">
                                <div class="in">
                                    <img
                                        :src="platChoose == index ? 'https://minio.unimeta.com:9000/kol/icon/' + item.platformName + 'On.png' : 'https://minio.unimeta.com:9000/kol/icon/' + item.platformName + '.png'">
                                    <span>
                                        {{ item.platformName }}
                                    </span>
                                </div>
                                <div class="line" v-show="platChoose == index">
                                </div>
                            </div>

                        </div>
                        <div class="num-box" v-if="kolDetailObj.platforms && kolDetailObj.platforms.length > 0">
                            <div class="card">
                                <div class="title" v-text="$t('followers')"></div>
                                <div class="num">{{ kolDetailObj.platforms[platChoose].followers || '' }}</div>
                            </div>
                            <!-- <div class="card">
                                <div class="title">平均触达用户数： </div>
                                <div class="num">1200万</div>
                            </div> -->
                            <div class="card">
                                <div class="title" v-text="$t('posts')"></div>
                                <div class="num">{{ kolDetailObj.platforms[platChoose].posts || '' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="h-title" v-text="$t('CooperationTypesandFees')">

                        </div>
                        <div class="line">

                        </div>
                        <div class="scroll-money">
                            <div class="money" v-for="(item, index) in kolDetailObj.services" :key="index">
                                <div class="title">{{ item.serviceName }}</div>
                                <img src="../../assets/uni-meta/kolDeatil/msk.png" alt="" v-show="!kolDetailObj.showDe">
                                <div v-show="kolDetailObj.showDe" style="color: #FF3A8A; text-align: center;flex: 1;">
                                    {{ item.amount != "0" ? item.amount + ' U' : $t('ChatI') }}</div>
                            </div>
                        </div>

                        <!-- <div class="money">
                            <div class="title">原创推文发布 </div>
                            <img src="../../assets/uni-meta/kolDeatil/msk.png" alt="">
                        </div>
                        <div class="money">
                            <div class="title">植入视频发布</div>
                            <img src="../../assets/uni-meta/kolDeatil/msk.png" alt="">
                        </div> -->
                        <div class="btn" v-text="$t('Viewthequotation')" @click="showDetail">
                        </div>
                    </div>
                </div>
                <div class="data-box c">
                    <div class="avarage">
                        <div class="number">
                            <div>100%</div>
                            <div>50%</div>
                            <div>0%</div>
                        </div>
                        <div class="column">
                            <div class="columns">
                                <div class="real1" :style="'height:' + kolDetailObj.retweetRatio + '%;'"></div>
                            </div>
                            <div class="font" v-text="$t('Average_Retweet_rate')"></div>
                        </div>
                        <div class="column two">
                            <div class="columns">
                                <div class="real2" :style="'height:' + kolDetailObj.replyRatio + '%;'"></div>
                            </div>
                            <div class="font" v-text="$t('Average_Response_rate')"></div>
                        </div>
                        <div class="column">
                            <div class="columns">
                                <div class="real3" :style="'height:' + kolDetailObj.shareRatio + '%;'"></div>
                            </div>
                            <div class="font" v-text="$t('Average_Share_rate')"></div>
                        </div>
                    </div>
                    <div class="u-line">
                        <div class="le"></div>
                        <div class="lin"></div>
                        <div class="le"></div>
                    </div>
                    <div class="score-box">
                        <div class="title" v-text="$t('CooperationScore')"></div>
                        <div class="innercard">
                            <div>
                                <div class="head">
                                    <img :src="kolDetailObj.photo" />

                                </div>
                                <div class="zx-box">
                                    <img src="../../assets/uni-meta/kolDeatil/zx.png" class="zx">
                                </div>
                            </div>
                            <div class="score">
                                {{ kolDetailObj.cooperationScore != 0 ? kolDetailObj.cooperationScore : '-' }}
                            </div>
                            <div class="right-font">
                                <div v-text="$t('DataScore')"></div>
                                <div class="centers" v-text="$t('ProfessionalLevelScore')"></div>
                                <div v-text="$t('ComprehensiveScore')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="u-line">
                        <div class="le"></div>
                        <div class="lin"></div>
                        <div class="le"></div>
                    </div>
                    <div class="ebox">
                        <div class="echart">
                            <Echarts :echartsArr="echartsArr" v-if="echartsArr.length > 0"></Echarts>
                        </div>

                    </div>
                </div>
                <div class="same-box c">
                    <div class="title" v-text="$t('Similaraccounts')"></div>
                    <div class="user" v-for="(item, index) in sameArr" :key="index">
                        <img :src="item.photo" alt="">
                        <div class="us">
                            <div class="name">{{ item.nickName }}</div>
                            <div class="address">{{ $t('region') }}:{{ item.area }}</div>
                        </div>
                        <div class="lan">
                            <div style="height:30px"></div>
                            <div class="address">{{ $t('Language') }}:{{ item.language }}</div>
                        </div>
                        <div class="num">
                            <div style="height:30px"></div>
                            <div class="address">{{ $t('followers') }}:{{ item.followers }}</div>
                        </div>
                        <div class="ch">
                            <div class="nums">90%</div>
                            <div class="font" v-text="$t('audiencecoincidence')"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PublishNow v-if="PublishNowVisible" :isWhitelistUsers="$store.state.whiteUser">
        </PublishNow>
    </div>
</template>
  
<script>
import One from '@/assets/uni-meta/kolDeatil/one.png'
import Two from '@/assets/uni-meta/kolDeatil/two.png'
import Three from '@/assets/uni-meta/kolDeatil/three.png'
import Four from '@/assets/uni-meta/kolDeatil/four.png'
import Five from '@/assets/uni-meta/kolDeatil/five.png'
import Six from '@/assets/uni-meta/kolDeatil/six.png'
import Seven from '@/assets/uni-meta/kolDeatil/seven.png'

import Te from '@/assets/uni-meta/kolDeatil/telegram-original.png'
import Tea from '@/assets/uni-meta/kolDeatil/telegram-originala.png'
import Twitter from '@/assets/uni-meta/kolDeatil/twitter.png'
import Twittera from '@/assets/uni-meta/kolDeatil/twitter-a.png'
import Face from '@/assets/uni-meta/kolDeatil/facebook_facebook52.png'
import Facea from '@/assets/uni-meta/kolDeatil/facebook_facebook52a.png'

import Echarts from './echarts.vue'
import { kolDetail, getEcharts, kolList, getkols, setkols } from '@/api/kol'
import PublishNow from '@/components/PublishNow'
import { updateUser } from '@/utils'

export default {
    components: {
        Echarts,
        PublishNow
    },
    data() {
        return {
            cardArr: [
                { icon: One, info: this.$t('influencers') },
                { icon: Two, info: this.$t('Appointment') },
                { icon: Three, info: this.$t('Free') },
                { icon: Four, info: this.$t('Peer-to-peer') },
                { icon: Five, info: this.$t('Assistance') },
                { icon: Six, info: this.$t('communication') },
                { icon: Seven, info: this.$t('FinishCase') },
            ],
            platForm: [{ icon: Te, icons: Tea, msg: 'Telegram' },
            { icon: Twitter, icons: Twittera, msg: 'Twitter' },
            { icon: Face, icons: Facea, msg: 'Facebook' },
            ],
            platChoose: 1,
            kolDetailObj: {},
            echartsArr: [],
            sameArr: [],
            PublishNowVisible: false,
            isApplyMember: -1
        };
    },
    computed: {
    },
    filters: {
    },
    methods: {
        closeDia() {
            this.PublishNowVisible = false;
        },
        appleEvent() {
            if (!this.$store.state.account || !this.$store.state.sigStr) {
                this.$toast.show(this.$t('beforeChina'))
                return
            } else if (this.$store.state.whiteUser) {
                this.setkollist()
                return
            } else if (!this.$store.state.whiteUser) {
                if (this.$store.state.memberType) {
                    this.$toast.show(this.$t('quicktodoS'))
                    return
                }
                this.$toast.show(this.$t('Youarenot'))
                setTimeout(() => {
                    this.PublishNowVisible = true;
                }, 10000)
                return
            }
        },
        showDetail() {
            // 先直接进行展示
            // if (!this.$store.state.account || !this.$store.state.sigStr) {
            //     this.$toast.show(this.$t('beforeChina'))
            //     return
            // } else if (this.$store.state.whiteUser) {
                this.kolDetailObj.showDe = true;
                this.$forceUpdate()
                return
            // } else if (!this.$store.state.whiteUser) {
            //     if (this.$store.state.memberType) {
            //         this.$toast.show(this.$t('quicktodoS'))
            //         return
            //     }
            //     this.$toast.show(this.$t('Youarenot'))
            //     setTimeout(() => {
            //         this.PublishNowVisible = true;
            //     }, 10000)
            //     return
            // }
            // this.$toast.show(this.$t('beforeChina'))
        },
        changePlat(i) {
            this.platChoose = i;
        },
        getKolDetail(par) {
            kolDetail(par).then(res => {
                console.log(res)
                console.log('res')
                this.kolDetailObj = res.data;
                this.kolDetailObj.platforms.push({
                    platformName: res.data.mediaPlatform,
                    followers: res.data.followers,
                    posts: res.data.posts,
                    accountUrl:res.data.platformLink
                })
                this.kolDetailObj.showDe = false;
                let i = this.kolDetailObj.platforms.findIndex(el => el.platformName == 'Twitter')
                if (i != -1) {
                    this.platChoose = i
                } else {
                    this.platChoose = this.kolDetailObj.platforms.findIndex(el => el.platformName == 'YouTube')
                }
                let objArr = ['otherOffers', 'spaceHostingOffer', 'originalVideoPostOffer', 'videoImplantationOffer', 'InfographicTweet', 'giveawayEvent', 'ama', 'quoteTweet', 'pinToPage', 'tweetRetweet', 'originalTweetPostOffer'];
                for (let i of objArr) {
                    this.kolDetailObj.services.unshift({
                        serviceName: this.$t(i),
                        amount: this.kolDetailObj[i] ? this.kolDetailObj[i] : '0',
                        show: false
                    })
                }
                this.kolDetailObj.services.sort(function (a, b) {
                    return Number(b.amount) - Number(a.amount);
                })
                let cloneDetailArr = JSON.parse(JSON.stringify(this.kolDetailObj.services))
                cloneDetailArr = cloneDetailArr.filter(el => {
                    return Number(el.amount) > 0
                })
                if (cloneDetailArr.length < 2) {
                    this.kolDetailObj.services = this.kolDetailObj.services.slice(0, 3)
                } else {
                    this.kolDetailObj.services = cloneDetailArr
                }
                console.log(this.kolDetailObj.services)
                this.getSameAccount()
            })
        },
        getEchart() {
            getEcharts({ code: 'interests_pie_chart' }).then(res => {

                this.echartsArr = res.data.map(el => ({ value: parseFloat(el.dictKey), name: el.dictValue, zb: el.dictKey }));

            })
        },
        getSameAccount() {
            kolList({
                current: 1,
                size: 4, interests: this.kolDetailObj.interests
            }).then(res => {
                console.log(res)
                console.log('res')
                this.sameArr = res.data.records
            })
        },
        backEvent() {
            this.$router.back()
        },
        getkolEvent() {
            if (!this.$store.state.account || !this.$store.state.sigStr) {
                return
            }
            getkols({
                memberId: this.$store.state.userInfo.user_id
            }).then(res => {
                let i = res.data.findIndex(el => el.kolMemberId == this.$route.query.id)
                console.log(i)
                if (i != -1) {
                    this.isApplyMember = 1
                } else {
                    this.isApplyMember = -1
                }
            })
        },
        setkollist() {
            setkols({
                memberId: this.$store.state.userInfo.user_id,
                memberName: this.$store.state.userInfo.user_name,
                kolMemberId: this.$route.query.id,
                kolMemberName: this.kolDetailObj.nickName
            }).then(res => {
                if (res.code == 200) {
                    this.isApplyMember = 1
                    this.$toast.show(this.$t('oneDay'))
                }
            })
        }
    },
    created() {
        console.log(this.$route.query.id)
        this.getKolDetail({ id: this.$route.query.id })
        this.getEchart();
        this.getkolEvent();
        updateUser();
    },
};
</script>
  
<style scoped lang="scss">
.c {
    width: 1443px;
}

.bannertop {
    position: relative;

    .b {
        width: 100vw;
        display: block;
    }

    .back {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
}

.kol-box {
    background: #371381;
    width: 100%;

    .under-banner {
        background: url('../../assets/uni-meta/kol-u.png') no-repeat top;
        background-size: 1920px 1428px;
        width: 100%;
        min-height: 1800px;
        padding-top: 30px;
        padding-bottom: 30px;

        .kolcard {
            min-height: 548px;
            background: #371381;
            border-radius: 6px;
            margin: -120px auto 0 auto;
            position: relative;
            box-sizing: border-box;
            padding-top: 40px;
            padding-bottom: 57px;

            .userimg {
                width: 211px;
                height: 211px;
                position: absolute;
                top: -68px;
                left: 68px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .userinfo {
                display: flex;
                justify-content: space-between;
                padding-right: 76px;

                .usermsg {
                    // margin-left: 338px;
                    display: flex;
                    justify-content: space-between;

                    .l {
                        width: 338px;
                        box-sizing: border-box;
                        padding-right: 15px;
                        padding-top: 120px;
                        // padding-left: 30px;
                        display: flex;
                        justify-content: space-evenly;
                        flex-wrap: wrap;
                        .three {
                            width: 100px;
                            height: 50px;
                            text-align: center;
                            border: 1px solid #C198FF;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            font-size: 16px;
                            color: #C198FF;
                            margin-bottom: 10px;
                            margin-right: 5px;
                            //   img {
                            //     width: 16px;
                            //     height: 13px;
                            //     margin-left: 11px;
                            //     margin-right: 2px;
                            //   }
                        }
                    }

                    .name {
                        font-size: 36px;
                        font-weight: normal;
                        color: #FFFFFF;
                    }

                    .base {
                        display: flex;
                        margin-bottom: 31px;
                        margin-top: 10px;

                        img {
                            width: 36px;
                            height: 36px;
                            margin-left: 21px;
                            margin-right: 30px;

                            &:first-child {
                                margin-left: 0px;
                            }
                        }

                        .rz {
                            font-size: 30px;
                            color: #C198FF;
                            margin-right: 35px;

                        }

                        .lan {
                            font-size: 30px;
                            color: #C198FF;
                        }
                    }

                    .address {
                        display: flex;
                        align-items: center;
                        margin-bottom: 45px;

                        img {
                            width: 28px;
                            height: 35px;
                        }

                        .info {
                            margin-left: 25px;
                            font-size: 30px;
                            color: #C198FF;
                        }
                    }

                    .hz {
                        min-width: 327px;
                        height: 62px;
                        background: #FF3A8A;
                        border-radius: 30px 0px 30px 0px;
                        font-size: 30px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 62px;
                        box-sizing: border-box;
                        padding-right: 20px;
                        padding-left: 20px;
                        cursor: pointer;
                    }

                    .hzs {
                        min-width: 327px;
                        height: 62px;
                        background: #cccccc;
                        border-radius: 30px 0px 30px 0px;
                        font-size: 30px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 62px;
                        box-sizing: border-box;
                        padding-right: 20px;
                        padding-left: 20px;
                        cursor: pointer;
                    }
                }

                .jianjie {
                    width: 498px;

                    .title {
                        font-size: 36px;
                        color: #FFFFFF;
                    }

                    .detail {
                        font-size: 20px;
                        color: #C198FF;
                        margin-top: 32px;
                    }
                }
            }

            .line {
                width: 100%;
                height: 1px;
                background-color: rgba(193, 152, 255, .3);
                margin-top: 39px;
            }

            .cooperation {
                padding-top: 38px;
                padding-right: 85px;
                padding-left: 85px;

                .title {
                    text-align: center;
                    font-size: 30px;
                    color: #FFFFFF;
                }

                .innerbox {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 40px;

                    .inn {
                        width: 200px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row-reverse;
                        margin-right: 10px;

                        &:last-child {
                            width: 100px;

                        }

                        .info {
                            text-align: center;
                            font-size: 20px;
                            color: #C198FF;

                            img {
                                width: 54px;
                                height: 54px;
                            }

                            div {
                                font-size: 20px;
                            }
                        }

                        .ror {
                            color: rgba(193, 152, 255, 1);
                            transform: scaleY(2);
                            width: 24px;
                            height: 24px;
                            padding-top: 5px;
                        }
                    }
                }
            }
        }

        .center {
            min-height: 400px;
            display: flex;
            margin: 9px auto 9px auto;

            .left {
                width: 72%;
                margin-right: 9px;

                .plat {
                    background-color: rgba(33, 8, 84, .7);
                    width: 100%;
                    height: 125px;
                    border-radius: 6px;
                    box-sizing: border-box;
                    padding: 40px 60px 0 50px;
                    display: flex;
                    justify-content: space-evenly;

                    .platforma {
                        color: #FF3A8A;
                        cursor: pointer;

                    }

                    .platform {
                        color: #C198FF;
                        cursor: pointer;

                    }

                    .in {
                        display: flex;
                        align-items: center;
                    }

                    img {
                        width: 43px;
                        height: 43px;
                    }

                    span {
                        font-size: 36px;
                        margin-left: 23px;
                    }

                    .line {
                        width: 80%;
                        height: 3px;
                        background: #FF3A8A;
                        border-radius: 2px;
                        margin: 36px auto 0 auto;
                    }
                }

                .num-box {
                    margin-top: 9px;
                    width: 100%;
                    height: 264px;
                    background-color: rgba(33, 8, 84, .7);
                    border-radius: 6px;
                    box-sizing: border-box;
                    padding-right: 50px;
                    padding-left: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;

                    .card {
                        width: 417px;
                        height: 195px;
                        background: url('../../assets/uni-meta/kolDeatil/bg.png') no-repeat center;
                        background-size: 100% 100%;
                        box-sizing: border-box;
                        padding-top: 23px;
                        text-align: center;

                        .title {
                            font-size: 24px;
                            color: #FFFFFF;
                        }

                        .num {
                            font-size: 42px;
                            color: #FFFFFF;
                            margin-top: 33px;
                        }
                    }
                }
            }

            .right {
                flex: 1;
                min-height: 400px;
                background-color: rgba(33, 8, 84, .7);
                border-radius: 6px;
                box-sizing: border-box;
                padding-top: 25px;

                .h-title {
                    padding-left: 34px;
                    font-size: 24px;
                    color: #FFFFFF;
                }

                .line {
                    height: 1px;
                    background: #C198FF;
                    opacity: 0.3;
                    margin-top: 22px;
                    margin-bottom: 17px;
                }

                .scroll-money {
                    height: 220px;
                    overflow-y: auto;
                }

                .money {
                    display: flex;
                    margin-bottom: 27px;

                    .title {
                        width: 212px;
                        text-align: center;
                        margin-right: 10px;
                        font-size: 24px;
                        color: #FFFFFF;

                    }

                    img {
                        width: 117px;
                        height: 41px;
                    }
                }

                .btn {
                    width: 230px;
                    height: 58px;
                    border: 2px solid #6FECE7;
                    border-radius: 15px;
                    font-size: 24px;
                    color: #6FECE7;
                    text-align: center;
                    line-height: 58px;
                    margin: 16px auto 0 auto;
                    box-sizing: border-box;
                    cursor:pointer;
                }
            }
        }

        .data-box {
            margin-top: 9px;
            padding-top: 116px;
            padding-right: 116px;
            padding-left: 116px;
            background-color: rgba(33, 8, 84, .7);
            box-sizing: border-box;
            margin: 9px auto 0 auto;
            border-radius: 6px;

            .avarage {
                position: relative;
                display: flex;
                justify-content: center;
                padding-bottom: 76px;

                .two {
                    margin-right: 115px;
                    margin-left: 115px;
                }

                .column {
                    text-align: center;

                    .columns {
                        width: 72px;
                        height: 413px;
                        background: #303E8C;
                        border-radius: 11px;
                        margin: 0 auto 50px auto;
                        display: flex;
                        flex-direction: column-reverse;
                        overflow: hidden;
                    }

                    .font {
                        max-width: 190px;
                    }

                    font-size: 24px;
                    color: #FFFFFF;
                }

                .real1 {
                    width: 100%;
                    height: 160px;
                    background: linear-gradient(0deg, #0570E9 0%, #0ECFF8 100%);
                    border-radius: 11px;
                }

                .real2 {
                    width: 100%;
                    height: 352px;
                    background: linear-gradient(0deg, #303E8C 0%, #932AE1 100%);
                    border-radius: 11px;
                    border-radius: 11px;
                }

                .real3 {
                    width: 100%;
                    height: 281px;
                    background: linear-gradient(0deg, #0570E9 0%, #0ECFF8 100%);
                    border-radius: 11px;
                }

                .number {
                    position: absolute;
                    height: 413px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    left: 200px;
                    font-size: 24px;
                    color: #FFFFFF;
                }
            }

            .score-box {
                box-sizing: border-box;
                padding-top: 81px;

                .title {
                    text-align: center;
                    font-size: 24px;
                    font-weight: normal;
                    color: #FFFFFF;
                    margin-bottom: 41px;
                }

                .innercard {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 113px;

                    .head {
                        width: 124px;
                        height: 124px;
                        text-align: center;
                        border-radius: 50%;
                        border: 2px solid #D06DC7;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 105px;
                            height: 105px;
                            border-radius: 50%;
                        }
                    }

                    .zx {
                        width: 203px;
                        height: 117px;
                        display: block;
                        margin-left: 50px;
                        margin-top: -25px;
                    }

                    .score {
                        width: 240px;
                        height: 234px;
                        background: url('../../assets/uni-meta/kolDeatil/light.png') no-repeat center;
                        background-size: 100% 100%;
                        font-size: 72px;
                        color: #00FFFF;
                        line-height: 234px;
                        text-align: center;
                    }

                    .right-font {
                        width: 246px;
                        height: 204px;
                        background: url('../../assets/uni-meta/kolDeatil/pf.png') no-repeat center;
                        background-size: 100% 100%;
                        font-size: 28px;
                        color: #FFFFFF;
                        box-sizing: border-box;
                        padding-top: 18px;
                        padding-bottom: 18px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-left: 77px;
                    }
                }
            }

            .ebox {
                min-height: 537px;
                box-sizing: border-box;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;

                .echart {
                    width: 600px;
                    height: 270px;
                }
            }
        }

        .same-box {
            min-height: 937px;
            background: #210854;
            opacity: 0.7;
            border-radius: 6px;
            margin: 9px auto 35px auto;

            .title {
                padding-left: 82px;
                box-sizing: border-box;
                padding-top: 54px;
                font-size: 30px;
                font-weight: normal;
                color: #F6F8FC;
                margin-bottom: 45px;
            }

            .user {
                height: 206px;
                display: flex;
                align-items: center;
                padding-left: 119px;
                padding-right: 119px;
                border-top: 2px rgba(193, 152, 255, .3) solid;
                justify-content: space-between;
                font-size: 24px;
                color: #927CB3;

                img {
                    width: 104px;
                    height: 104px;
                    border-radius: 50%;
                }

                .name {
                    margin-bottom: 31px;
                }

                .lan,
                .num {}

                .nums {
                    margin-bottom: 31px;
                    font-size: 30px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.u-line {
    display: flex;
    align-items: center;

    .le {
        width: 59px;
        height: 9px;
        background: #08D1EA;
    }

    .lin {
        flex: 1;
        height: 2px;
        background: #08D1EA;
        opacity: 0.2;
    }
}

@media screen and (max-width: 500px) {
    .btn {
        width: 100% !important;
    }

    .c {
        width: 100vw !important;
    }

    .userinfo {
        display: flex;
        flex-wrap: wrap;
    }

    .base {
        display: flex;
        flex-wrap: wrap;
    }

    .cooperation {
        padding: 0 !important;
    }

    .innerbox {
        flex-wrap: wrap;
    }

    .inn {
        margin-top: 10px !important;
    }

    .center {
        flex-wrap: wrap;

        .left {
            width: 100% !important;
        }
    }

    .num-box {
        .card {
            height: 230px !important;

            .title {
                height: 32px !important;
                margin-bottom: 15px !important;
            }
        }
    }

    .avarage {
        .number {
            position: absolute;
            left: 0px !important;
        }
    }

    .score-box {
        .score {
            width: 400px !important;
            flex: 1;
        }

        .right-font {
            width: 346px !important;
            height: 254px !important;

            div {
                white-space: nowrap !important;
            }
        }
    }

    .data-box {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .echart {
        width: 700px !important;
        height: 270px;
    }

    .same-box {
        .user {
            padding: 0px !important;
            flex-wrap: wrap;
            justify-content: center;
            min-height: 336px;

            .ch {
                margin-top: 30px !important;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
    // background-color: #00C6FF;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #00C6FF;
}
</style>
  