<template>
    <div class="page-home">
        <div class="kol-box">
            <div class="under-banner">
                <div class="unimeta">
                    <div class="unibox">
                        <div class="uni_pic">
                            <img src="../../assets/uni-meta/more/under-pic.png" alt="">
                        </div>
                        <div class="uni_icon">
                            <div class="title" v-text="$t('WhatUNIMETA')"></div>
                            <div class="msg1" v-text="$t('UNIMETAIs')"></div>
                            <div class="msg2" v-text="$t('BasedOn')"></div>
                        </div>
                    </div>
                </div>

                <!-- unimeta DAO 的优势 -->
                <div class="advantage">
                    <div class="title" v-text="$t('AdvantagesDAO')">
                       
                    </div>
                    <div class="ads">
                        <div class="ad-box" v-for="(item,index) in advantAge" :key="index">
                            <img :src="item.img" alt="">
                            <div class="ts">
                                {{item.title}}
                            </div>
                            <div class="msg">
                                {{item.msg}}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 我们的未来规划-->
                <div class="future">
                    <div class="future—title" v-text="$t('OurFuture')">
                       
                    </div>
                    <div class="future-box">
                        <div class="future-inner" v-for="(item,index) in futureArr" :key="index">
                            <img :src="item.pic" alt="">
                            <div class="msg">
                                {{item.msg}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Pic0 from '@/assets/uni-meta/more/pic0.png';
import Pic1 from '@/assets/uni-meta/more/pic1.png';
import Pic2 from '@/assets/uni-meta/more/pic2.png';
import Pic3 from '@/assets/uni-meta/more/pic3.png';
import Icon0 from '@/assets/uni-meta/more/icon0.png';
import Icon1 from '@/assets/uni-meta/more/icon1.png';
import Icon2 from '@/assets/uni-meta/more/icon2.png';
import Icon3 from '@/assets/uni-meta/more/icon3.png';
import Icon4 from '@/assets/uni-meta/more/icon4.png';
import Icon5 from '@/assets/uni-meta/more/icon5.png';
import Icon6 from '@/assets/uni-meta/more/icon6.png';
import Icon7 from '@/assets/uni-meta/more/icon7.png';

export default {
    components: {
     
    },
    data() {
        return {
            advantAge: [
                { title: this.$t('AbundantResources'), msg: this.$t('WeMaintain'), img: Pic0 },
                { title: this.$t('Authentication'), msg: this.$t('TheUniMeta') , img: Pic1 },
                { title: this.$t('AlliancePartners'), msg: this.$t('weClose'), img: Pic2 },
                { title: this.$t('WebIndustry'), msg: this.$t('OurOperation'), img: Pic3 },
            ],
            futureArr: [
                { msg: this.$t('Continuously'), pic: Icon0 },
                { msg: this.$t('Complete'), pic: Icon1 },
                { msg: this.$t('Realize'), pic: Icon2 },
                { msg: this.$t('Improve'), pic: Icon3 },
                { msg: this.$t('Build'), pic: Icon4 },
                { msg: this.$t('Open'), pic: Icon5 },
                { msg: this.$t('Establish'), pic: Icon6 },
                { msg: this.$t('Work'), pic: Icon7 }
            ]
        };
    },
    computed: {
        // ...mapGetters(["lang"]),
    },
    filters: {


    },
    methods: {
    },
    created() {

    },
};
</script>
  
<style scoped lang="scss">
.kol-box {
    background: #371381;
    width: 100%;

    .under-banner {
        background: url('../../assets/uni-meta/kol-u.png') no-repeat top;
        background-size: 1920px 1428px;
        width: 100%;
        min-height: 1800px;

        .unibox {
            display: flex;
            justify-content: center;
            color: #FFFFFF;
            align-items: center;

            .uni_pic {
                width: 705px;
                height: 700px;
                background: url('../../assets/uni-meta/more/under.png') no-repeat top;
                background-size: 100% 100%;
                text-align: center;
                display: flex;
                align-items: center;

                img {
                    width: 532px;
                    height: 511px;
                    margin: auto;
                }
            }

            .uni_icon {
                width: 622px;
                margin-left: 104px;
                font-size: 24px;

                .title {
                    text-align: center;
                    font-size: 36px;
                    color: #FFFFFF;
                    line-height: 36px;
                    margin-bottom: 34px;
                }

                .msg1 {
                    margin-bottom: 34px;
                    line-height: 35px;
                }

                .msg2 {
                    line-height: 35px;
                }
            }
        }

        .advantage {
            margin-top: 101px;
            color: #FFFFFF;

            .title {
                text-align: center;
                font-size: 36px;
                color: #FFFFFF;
            }

            .ads {
                display: flex;
                justify-content: center;
                margin-top: 45px;
            }

            .ad-box {
                width: 320px;
                margin-right: 53px;

                &:last-child {
                    margin-right: 0px;

                }

                .ts {
                    font-size: 24px;
                    color: #FFFFFF;
                    margin-top: 37px;
                    margin-bottom: 25px;
                }

                .msg {
                    font-size: 20px;
                    color: #C198FF;
                    line-height: 36px;
                }

                img {
                    width: 320px;
                    height: 277px;
                }
            }
        }

        // 未来规划
        .future {
            margin-top: 100px;
            padding-bottom: 80px;
            .future—title {
                text-align: center;
                font-size: 36px;
                color: #FFFFFF;
                margin-bottom: 53px;
            }

            .future-box {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 1446px;
                margin: 0 auto;

                .future-inner {
                    width: 329px;
                    min-height: 180px;
                    background: rgba(193, 152, 255, 0.25);
                    border-radius: 0px 20px 20px 20px;
                    margin-bottom: 44px;
                    display: flex;
                    padding-top: 41px;
                    padding-left: 47px;
                    box-sizing: border-box;
                    padding-right: 40px;
                    padding-bottom: 20px;
                    align-items: center;
                    img {
                        width: 58px;
                        height: 58px;
                    }

                    .msg {
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 30px;
                        margin-left: 20px;
                    }

                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .unibox{
        display: flex;
        flex-wrap: wrap;
        .uni_pic{
            margin-top: 80px;
        }
        .uni_icon{
            margin-top: 50px;
            margin-left:0px !important;
        }
    }
    .ads{
        width: 100vw;
        flex-wrap: wrap;
        justify-content: space-between;
        .ad-box{
            margin-right: 15px !important;
            margin-bottom: 30px;
        }
    }
    .future-box{
        flex-wrap: wrap !important;
        display: flex !important;
        width: 100vw !important;
    }
    .future-inner{
        height: 480px !important;
        width: 48vw!important;
    }
}
</style>
  