<!--
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-20 14:13:59
 * @Description: 
-->
<template>
  <Popover
  class="pui-popover-trigger"
  popper-class="pui-popover"
  :trigger="trigger"
  :title="title"
  :content="content"
  :width="width"
  :placement="placement"
  :disabled="disabled"
  v-model="myValue"
  :offset="offset"
  >
  <slot></slot>
  <template slot="reference">
      <slot name="reference"></slot>
  </template>
  </Popover>
</template>

<script>
import { Popover } from 'element-ui'
export default {
    components: { Popover },
    props: {
        trigger: String,
        title: String,
        content: String,
        width: [Number,String],
        placement: String,
        disabled: Boolean,
        value: Boolean,
        offset: Number
    },
    data() {
        return {
            myValue: this.value
        }
    },
    watch: {
        myValue(val) {
            this.$emit('update:value', val)
        },
        value(val) {
            this.myValue = val
        }
    }
}
</script>

<style lang="scss" scoped>
    /deep/.pui-popover {
        box-shadow: 0px 4px 6px 2px rgba(208, 170, 141, 0.41) !important;
        border-radius: 11px !important;
        border: none !important;
        left:125px !important
    }
</style>