<template>
    <div class="boxse">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="size"
            layout="prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
    
</template>

<script>
export default {
    props:{
      size:{
        type:Number,
        default:()=>{
          return 6
        }
      },
      total:{
        type:Number,
        default:()=>{
          return 100
        }
      }
    },
    methods: {
      // handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      // },
      handleCurrentChange(val) {
        this.$emit('handleCurrentChange',val)
        console.log(`当前页: ${val}`);
      }
    },
    data() {
      return {
        currentPage1: 0,
      };
    }
}
</script>

<style lang="scss">
.boxse {
    text-align: center;
    transform: scale(0.8);
    margin-left: -50px;
}
.el-pagination__jump{
  margin-top: 20px;
  display: block !important;
}
.el-pagination .btn-next, .el-pagination .btn-prev,.el-pager li  {
    background: center center no-repeat #5826C0 !important;
    color:#fff !important
}
.el-pager li.active {
    color: #FF3A8A !important;
}
.el-pagination__editor.el-input .el-input__inner {
    background: #5826C0 !important;
    border: none;
    color: #fff;
}
.el-pagination__jump {
    color: #fff !important;
}
@media screen and(max-width:768px) {
    .el-pagination__jump {
        margin-left: 0 !important;
    }   
}

</style>