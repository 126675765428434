/*
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-04 14:21:54
 * @Description: 
 */
import Vue from 'vue'
import Vuex from 'vuex'
import state from './state.js'
import getters from './getters.js'
import mutations from './mutations.js'
import actions from './actions'
import persistedState from 'vuex-persistedstate'

let persistArg = {
	paths: [ 'account', 'token', 'lang','isPassInputInviteCode','sigStr','whiteUser','userInfo','memberType','chainInfo','isBscChain'], //指定缓存的值
	storage: window.localStorage //指定缓存类型
}

Vue.use(Vuex);//vue的插件机制

//Vuex.Store 构造器选项
const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	plugins:[persistedState(persistArg)]
})

export default store