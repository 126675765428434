<template>
  <Menu router @select="select" :collapse.sync="collapse" >
    <MenuItem :class="['pui-menu-item']" :index="item.to" v-for="(item, index) in menus" :key="index"
      :disabled="item.disabled" >
    <i class="iconfont" :class="[item.icon]"></i>
    <span slot="title" :class="[item.active ? 'active' : '']">{{ item.name }}</span>
    </MenuItem>
    <!-- <bot-menu v-if="isMobile"></bot-menu> -->
  </Menu>
</template>

<script>
import { Menu, MenuItem } from "element-ui";
// import botMenu from "./botMenu.vue";
import { isMobile } from "../../utils";
import { setI18nLanguage } from '@/plugins/i18n'
export default {
  components: { Menu, MenuItem },
  props: {
    showMenu: Boolean,
    data: [Array, Object],
  },
  data() {
    return {
      collapse: this.showMenu,
      isMobile,
      lang:this.$store.state.lang,
      menus: [
        {
          id: 'home',
          icon: '',
          active: true,
          name: "KOL List",
          to: '/',
        },
        {
          id: 'mall',
          icon: '',
          active: false,
          name: this.$t('DemandPool'),
          to: '/public-square',
        },
        {
          id: 'my-nft',
          icon: '',
          active: false,
          name:this.$t('PromotionChannels'),
          to: '/promotion-channels',
        },
        {
          id: 'work',
          icon: '',
          active: false,
          name: this.$t('LearnMore'),
          to: '/more',
        },
        {
          id: 'lang',
          icon: '',
          active: false,
          name: this.$store.state.lang,
          to: '',
        }
      ]
    };
  },
  mounted(){
    this.lang = this.$store.state.lang === 'en' ?  '中文' : 'English';
    this.menus[4].name = this.lang;
  },
  watch: {
    collapse(val) {
      this.$emit("update:showMenu", val);
    },
    showMenu(val) {
      this.collapse = val;
    },
  },
  methods: {
    select() {
      console.log(arguments[0]);
      if(arguments[0]){
        this.menus.forEach(item => {item.to === arguments[0] ? item.active = true : item.active = false; });
      }
      console.log(this.data);
      if (!arguments[0]) {
        this.switchLang();
      }
      this.$emit("update:showMenu", true);
    },
    // itemClickHandler(){
    // }
    switchLang() {
      let active = this.$store.state.lang === 'zh' ? 'en' : 'zh';
      this.$store.state.lang = active;
      this.$store.dispatch('changeload')
      setI18nLanguage(active)
      this.menus[4].name = this.$store.state.lang === 'en' ?  '中文' : 'English';
    }
  },
};
</script>

<style scoped lang="scss">
.pui-menu-item {
  &.el-menu-item {
    color: #fff;
    height: $--menu-item__height;
    line-height: 1.5;
    margin-top: 40px;

    &:hover,
    &:active,
    &:focus {
      background-color: $--color-lighter;
    }
  }

  span {
    font-size: 16px;
  }
}

.active {
  color: #FF3A8A;
}

.iconfont {
  margin-right: 10px;
  font-size: 20px;
  color: $--color !important;
}
</style>