<!--
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-11 17:42:42
 * @Description: 
-->
<template>
    <!-- 菜单抽屉 -->
    <div>
        <Drawer
            class="pui-menu-drawer"
            :style="propStyle"
            :withHeader="withHeader"
            :size="width"
            :visible.sync="showMenu"
            direction="rtl"
            :before-close="handleClose">
            <nav-menu :showMenu.sync="reShowMenu" :data="links" v-if="$store.state.isReload"></nav-menu>
        </Drawer>
    </div>
</template>

<script>
import { Drawer } from 'element-ui';
import navMenu from './nav-menu.vue';
// import botMenu from './botMenu.vue';
import { isMobile } from '../../utils';

export default {
    components: { Drawer, navMenu},
    data() {
        return {
            isMobile,
            showMenu: this.visible,
            reShowMenu: !this.visible
        }
    },
    props: {
        links: Array,
        visible: Boolean,
        withHeader: Boolean,
        width: {
            type: Number,
            default: 240
        },
        propStyle: {
            type: Object,
            default() {}
        }
    },
    watch: {
        visible(val) {
            this.showMenu = val;
            this.reShowMenu = !val;
        },
        showMenu(val) {
            this.$emit('update:visible', val);
        },
        reShowMenu(val) {
            this.$emit('update:visible', !val);
        }
    },
    methods: {
        handleClose() {
            this.showMenu = false;
        }
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
    .box {
        width: 100%;
        height: 800px;
        background: blue;
    }
    .pui-menu {
        overflow-y: auto;
        overflow-x: hidden;
        &--larger {
            min-height: 100%;
            &:not(.el-menu--collapse) {
                width: 273px;
            }
        }
        
        &-drawer {
            top: 150px;
            @media screen and (max-width:570px) {
                top: 140px;
            }
            
        }
    }
</style>
<style lang="scss">
    .v-modal {
        top: 150px !important;
            @media screen and (max-width:570px) {
                top: 140px !important;
            }     
    }
    .el-drawer__body,.el-menu {
        background-color: #371381 !important;
        color: #fff;
        border-right: none !important;
    }
    .pui-menu-item.el-menu-item[data-v-86036a68] {
        color: #fff !important;
    }
</style>