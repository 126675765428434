/*
 * @Author: zw
 * @Date: 2021-11-03 17:29:02
 * @LastEditors: zw
 * @LastEditTime: 2021-11-17 17:59:26
 * @Description:
 */


import _this from '@/main.js'

// import { getSignatureDataBSC } from '@/utils/bsc'
// import Router from '@/router'
// import { toast } from '@/plugins/toast'

export default {
  // 登录
  // Login({ commit }, data) {
  //     return new Promise((resolve, reject) => {
  //     console.log('qqqqq')
  //     login(data).then(res => {
  //         commit('SET_TOKEN', res.data.token)
  //         const data = res.data.user
  //         commit('SET_USERINFO', data)
  //         // localStorage.setItem('userInfo', JSON.stringify(data))
  //         resolve(res)
  //     }).catch(error => {
  //         reject(error)
  //     })
  //     })
  // },

  // 获取用户信息
  // async GetUserInfo(
  //   {
  //     // commit,
  //     // state,
  //     // getters
  //   },
  //   { account }
  // ) {
  //   if (!account) {
  //     return console.log('[GetUserInfo] account is null')
  //   }
  //   // console.log(Router)
  //   // let [userData, agentData] = await Promise.all([
  //   //   getUser({
  //   //     address: account,
  //   //   }),
  //   //   getAyencyConfig(),
  //   // ])

  //   // commit('SET_USER_INFO', userData.data)

  //   //userData为空 代表没注册
  //   //user_data下注册码为空 说明没有输邀请码的情况下注册了
  //   // -_-|| 根据需求先做成通过后端返回数据结构判断

  //   //if(!getters.isPassInputInviteCode && (!userData.data.referrer_invite_code)){
  //   // if (!userData.data.user_data && !userData.data.referrer_invite_code) {
  //   //   //沒有注册 没有点击注册跳过按钮
  //   //   // Router.push('/register')
  //   //   commit('SET_USER_IS_ALREADY_REGISTED', false)
  //   // } else if (!userData.data.referrer_invite_code) {
  //   //   // 点击了注册跳过按钮（没输入邀请码走了注册接口）
  //   //   // Router.push('/register')
  //   //   commit('SET_USER_IS_ALREADY_REGISTED', false)
  //   // } else {
  //   //   //注册
  //   //   commit('SET_USER_IS_ALREADY_REGISTED', true)
  //   // }
  // },

  // 登出
  LogOut({ commit }) {
    return new Promise((resolve) => {
      // logOut().then(res => {
      //     commit('SET_TOKEN', '')
      //     commit('DEL_USERINFO')
      //     removeToken()
      //     window.location.href = res.data.url
      //     resolve()
      // }).catch(error => {
      //     reject(error)
      // })
      commit('SET_ACCOUNT', '')
      commit('SET_USER_INFO', {})
      resolve()
    })
  },
 
  changeload({ commit }) {
    commit('toReload', false)
    return _this.$nextTick(function () {
      commit('toReload', true)
    })
  },
}
